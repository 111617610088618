import { Formik as FormProvider } from 'formik'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import * as Validator from 'yup'

import { RegisterForm } from './RegisterForm'

import { FormLayoutProvider } from 'components/common/context/FormLayoutContext'
import { NearbyskyLogoIcon } from 'components/icons/NearbyskyLogoIcon'
import { styled, apply } from 'lib/styled'
import { userModule } from 'modules/user'
import { requiredString } from 'utils/validation'

const Background = styled.div(
  apply('flex flex-column justify-center items-center', {
    backgroundColor: '#EDF2F7',
  })
)
const Container = styled.div(
  apply('flex flex-column justify-center items-center  px-10 py-16', {
    backgroundColor: '#FFFFFF',
    margin: '155px auto',
    width: '646px',
    borderRadius: '16px',
  })
)
const Title = styled.h2(apply('mt-6 text-4xl font-bold'))
const Description = styled.p(
  apply('m-0 text-gray-600 text-center', {
    color: '#595959',
  })
)

const Scrollable = styled.div(
  apply('flex flex-column p-6', {
    minHeight: 'min-content',
  })
)

const { object, array } = Validator

const validationSchema = object({
  organizationDetails: object({
    companyName: requiredString('Company Name'),
    companyWebsite: requiredString('Website').url(
      'Please input full url (e.g https://example.com)'
    ),
    companyServiceType: array().required('Service Type is a required field'),
  }),
  userDetails: object({
    firstName: requiredString('First Name'),
    email: requiredString('Email').email('Invalid email'),
    phoneNumber: requiredString('Phone number'),
  }),
})

const initialValues = {
  organizationDetails: {
    companyName: '',
    companyWebsite: '',
    companyServiceType: '',
  },
  userDetails: {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
  },
}

const FormContainer = styled.div({
  width: '100%',
})

function Register() {
  const [submiting, setSubmiting] = useState(false)
  const history = useHistory()
  const handleSubmit = async (values) => {
    setSubmiting(true)
    await userModule.registerSupplier(null, values).then((response) => {
      if (response) {
        history.replace('/register-success')
      }
    })
    setSubmiting(false)
  }
  return (
    <Background>
      <Container>
        <NearbyskyLogoIcon />
        <Title>Registration</Title>
        <Description>
          Please fill in the following Company and Key Contact details to apply as a
          Supplier on NearbySky&apos;s Network Plan (Free).
        </Description>
        <FormContainer>
          <FormLayoutProvider value={{ editing: true }}>
            <FormProvider
              enableReinitialize
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                handleSubmit(values)
              }}
            >
              {({ values, setFieldValue }) => (
                <Scrollable>
                  <RegisterForm
                    values={values}
                    setFieldValue={setFieldValue}
                    submiting={submiting}
                  />
                </Scrollable>
              )}
            </FormProvider>
          </FormLayoutProvider>
        </FormContainer>
      </Container>
    </Background>
  )
}

export { Register }
