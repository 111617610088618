import { pickThenRename, noop } from '@seedcloud/ramda-extra'
import { useFormikContext as useFormContext } from 'formik'

import { useJobs } from 'components/common/context/JobContext'
import { DateField } from 'components/common/DateField'
import { FormInput as FormInputBase } from 'components/common/FormInput'
import { LocationField } from 'components/common/LocationField'
import { SelectField } from 'components/common/SelectField'
import { Title } from 'components/common/Tabs/ui'
import { Toggle as ToggleBase } from 'components/common/Toggle'
import { AreaOfInterest } from 'components/jobs/common'
import { styled, apply } from 'lib/styled'

const Container = styled.section()

const Column = styled.div(apply('flex-1 flex flex-column'))
const Header = styled.div(apply('flex flex-row py-6'))
const Label = styled.label(apply('mb-2 uppercase tracking-wide text-xs text-grey-dark'))
const Toggle = styled(ToggleBase)(apply('mr-2'))
const ToggleContainer = styled.div(apply('flex pr-8'))
const Row = styled.div(
  apply('flex-1 flex flex-row my-2', {
    '&:first-of-type': apply('mt-0'),
    '&:last-of-type': apply('mb-10'),
  })
)
const FormInput = styled(FormInputBase)({
  '&:first-of-type': apply('ml-0'),
  '&:last-of-type': apply('mr-0'),
})

function JobInfo({ minScheduledAtDate }) {
  const { productList, customerList, onFilterProduct } = useJobs()

  const {
    setFieldValue,
    values: {
      jobInfo: { commercialSettings, address, radius },
    },
  } = useFormContext()

  const { billable, paid, dataUpload } = commercialSettings
  const coordinates = address?.geometry?.coordinates

  const productOptions = productList
    .filter((product) => product.isActive)
    .map(({ id, name: label }) => ({
      id,
      value: label,
      label,
    }))
  // const projectOptions = projectList.map(({ id, reference }) => ({
  //   id,
  //   value: reference,
  //   label: reference,
  // }))
  const customerOptions = customerList.map(
    ({ id, contactName, user: { email, phoneNumber } }) => ({
      value: contactName,
      label: contactName,
      email,
      phoneNumber,
      id,
    })
  )

  return (
    <Container>
      <Header>
        <Title id="info">Job Info</Title>
      </Header>
      <Column>
        <Row>
          <SelectField
            name="jobInfo.customer.companyName"
            label="Customer Name"
            onChange={(selectedOption) => {
              setFieldValue('jobInfo.customer', {
                companyName: selectedOption.value,
                companyEmail: selectedOption.email,
                companyNumber: selectedOption.phoneNumber,
              })
              setTimeout(() => {
                setFieldValue('jobInfo.selectedCustomerId', selectedOption.id)
              }, 100)
            }}
            options={customerOptions}
            placeholder="Select customer"
            testId="job-company"
            required
          />
        </Row>
        <Row>
          <FormInput
            name="jobInfo.customer.companyEmail"
            label="Customer Email"
            testId="job-customer-email"
            disabled
          />
          <FormInput
            name="jobInfo.customer.companyNumber"
            label="Customer Number"
            testId="job-customer-phone"
            disabled
          />
        </Row>
        {/* <Row>
          <SelectField
            name="jobInfo.project.reference"
            label="Customer Order ID"
            labelIcon={
              <ExclamationCircleIcon
                {...apply('h-1 w-1', {
                  stroke: '#334155',
                })}
                data-tip="Project ID is only needed before finalising a job"
                data-type="info"
              />
            }
            creatable
            emptyOption={{ id: undefined, reference: undefined }}
            onChange={({ id, value: reference }, { action }) => {
              onCreateProject(action, reference)
              setFieldValue('jobInfo.project', { id, reference })
            }}
            options={projectOptions}
          />
        </Row> */}
        <Label style={{ marginTop: 8 }}>Commercial settings</Label>
        <Row>
          <ToggleContainer>
            <Toggle
              onChange={(e) =>
                setFieldValue('jobInfo.commercialSettings.billable', e.target.checked)
              }
              checked={billable}
            />
            <Label>Billable</Label>
          </ToggleContainer>
          <ToggleContainer>
            <Toggle
              onChange={(e) =>
                setFieldValue('jobInfo.commercialSettings.paid', e.target.checked)
              }
              checked={paid}
            />
            <Label>Paid</Label>
          </ToggleContainer>
          <ToggleContainer>
            <Toggle
              onChange={(e) =>
                setFieldValue('jobInfo.commercialSettings.dataUpload', e.target.checked)
              }
              checked={dataUpload}
            />
            <Label>Data Upload</Label>
          </ToggleContainer>
        </Row>
        <Row>
          <LocationField
            name="jobInfo.address"
            label="Location"
            required
            testId="job-location"
            containerProps={{ style: apply('mr-3', { flexGrow: 6 }) }}
          />
          <FormInput
            name="jobInfo.radius"
            label="Radius in meters"
            required
            testId="job-radius"
          />
        </Row>

        <AreaOfInterest coordinates={coordinates} radius={radius} />

        <Row style={{ marginTop: 16 }}>
          <SelectField
            name="jobInfo.product.name"
            label="Product Name"
            required
            onChange={(selectedOption) => {
              setFieldValue(
                'jobInfo.product',
                pickThenRename(['id', 'value'], { value: 'name' })(selectedOption)
              )
            }}
            options={productOptions}
            placeholder="Search and Select Product"
            onInputChange={onFilterProduct}
            containerProps={{ style: apply('mr-3') }}
            testId="job-product-name"
          />
          <DateField
            name="jobInfo.scheduledAt"
            label="Scheduled at"
            required
            autoOk
            disablePast
            inputDisabled
            minDate={minScheduledAtDate}
            onError={noop}
            testId="job-scheduled-at"
          />
        </Row>
        <Row>
          <SelectField
            name="jobInfo.template"
            label="Job Template Type"
            onChange={(selectedOption) => {
              setFieldValue('jobInfo.template', selectedOption.value)
            }}
            options={[{ id: 'default', value: 'default', label: 'Default' }]}
            placeholder="Select a template type"
            testId="job-template-type"
          />
        </Row>
      </Column>
    </Container>
  )
}

export { JobInfo }
