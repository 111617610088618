import { styled, apply } from 'lib/styled'

const HeadingWrapper = styled.div(apply('flex justify-between mb-4'), {
  '& h1': {
    fontSize: '1.3125rem',
    fontWeight: 800,
    color: '#1F1F1F',
    lineHeight: '1.5625rem',
  },
})

const SubHeading = styled.p({
  fontSize: '13px',
  marginBottom: 0,
})

const Heading = ({ children, extra, subtitle }) => (
  <HeadingWrapper>
    <div>
      <h1>{children}</h1>
      {subtitle ? <SubHeading>{subtitle}</SubHeading> : null}
    </div>
    {extra}
  </HeadingWrapper>
)

export { Heading }
