/* eslint-disable no-shadow */
import { createContext, useCallback, useContext, useEffect, useState } from 'react'

import { getEntityType, getRole } from './getRole'
import { getIdentityClientInstance } from './solta-id'

import { ORGANIZATION_ROLES, ROLES } from 'constants/roles'
import { authModule } from 'modules/auth'
import { companyModule } from 'modules/company/web/module'

const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, window.location.pathname)

export const IdentityContext = createContext()
export const useIdentity = () => useContext(IdentityContext)
export const IdentityProvider = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  domain,
  clientId,
  redirectUrl,
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState()
  const [isAdmin, setIsAdmin] = useState(false)
  const [isPilot, setIsPilot] = useState(false)
  const [isSupplier, setIsSupplier] = useState(false)
  const [user, setUser] = useState()
  const [account, setAccount] = useState()
  const [organization, setOrganization] = useState()
  const [identityClient, setClient] = useState()
  const [loading, setLoading] = useState(true)
  const [popupOpen, setPopupOpen] = useState(false)
  const [invitationStatus, setInvitationStatus] = useState()
  const [pilotApplicationStatus, setPilotApplicationStatus] = useState()

  // this function to prevent infinite loading because cannot fetch /company
  const companyHandler = (companyResponse, identityClient) => {
    if (companyResponse === undefined) {
      identityClient.logout({
        returnTo: redirectUrl,
      })
    }
  }

  const fetchAccount = useCallback(
    async (role) => {
      const account = await authModule.getProfile()
      setAccount(account)
      const userRole = role || getRole(user)
      if (userRole === 'user') {
        setPilotApplicationStatus(account.status)
      }
      setIsPilot(account?.roles.pilot && account.roles.pilot.length > 0)
      setIsSupplier(
        account?.roles.contactType && account.roles.contactType === ROLES.SUPPLIER_ADMIN
      )
      const {
        organization: { logo, name, hubType, supplierType },
      } = account
      setOrganization({ logo, name, hubType, supplierType })
    },
    [user]
  )

  useEffect(() => {
    // eslint-disable-next-line complexity
    const initAuth0 = async () => {
      const identityClient = await getIdentityClientInstance({
        domain,
        clientId,
        redirectUrl,
      })
      setClient(identityClient)

      if (
        window.location.search.includes('code=') &&
        window.location.search.includes('state=')
      ) {
        const { appState } = await identityClient.handleRedirectCallback()
        onRedirectCallback(appState)
      }

      const isAuthenticated = await identityClient.isAuthenticated()

      setIsAuthenticated(isAuthenticated)

      if (isAuthenticated) {
        const user = await identityClient.getUser()
        const userRole = getRole(user)
        setUser(user)
        setIsAdmin(
          [
            ORGANIZATION_ROLES.ORGANIZATION_ADMIN,
            ORGANIZATION_ROLES.ORGANIZATION_STAFF,
            ROLES.PLATFORM_ADMIN,
          ].includes(userRole)
        )
        if (!isAdmin) {
          const companyResponse = await companyModule.inspectCompany()
          companyHandler(companyResponse, identityClient)
          if (userRole !== 'customer') {
            setInvitationStatus(companyResponse.company.invitationStatus)
          }
        }
        await fetchAccount(userRole)
      }

      setLoading(false)
    }
    initAuth0()
    // eslint-disable-next-line
  }, [])

  const loginWithPopup = async (params = {}) => {
    setPopupOpen(true)
    try {
      await identityClient.loginWithPopup(params)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
    } finally {
      setPopupOpen(false)
    }
    const user = await identityClient.getUser()
    setUser(user)
    setIsAuthenticated(true)
  }

  const handleRedirectCallback = async () => {
    setLoading(true)
    await identityClient.handleRedirectCallback()
    const user = await identityClient.getUser()
    setLoading(false)
    setIsAuthenticated(true)
    setUser(user)
  }

  return (
    <IdentityContext.Provider
      value={{
        isAdmin,
        isAuthenticated,
        isPilot,
        isSupplier,
        account,
        setAccount,
        user,
        setUser,
        organization,
        setOrganization,
        role: getRole(user),
        entityType: getEntityType(user),
        loading,
        popupOpen,
        invitationStatus,
        pilotApplicationStatus,
        setPilotApplicationStatus,
        loginWithPopup,
        handleRedirectCallback,
        getIdTokenClaims: (...p) => identityClient.getIdTokenClaims(...p),
        loginWithRedirect: (...p) => identityClient.loginWithRedirect(...p),
        getTokenSilently: (...p) => identityClient.getTokenSilently(...p),
        getTokenWithPopup: (...p) => identityClient.getTokenWithPopup(...p),
        logout: (p = {}) =>
          identityClient.logout({
            returnTo: redirectUrl,
            ...p,
          }),
        refetchAccount: fetchAccount,
      }}
    >
      {children}
    </IdentityContext.Provider>
  )
}
