import { Form } from 'formik'
import { useContext } from 'react'

import { FormLayoutContext } from '../../common/context/FormLayoutContext'

import CloudLayoutImage from 'assets/clouds_container.svg'
import { Spinner } from 'components/common/Spinner'
import { StatusBarWeb } from 'components/common/StatusBarWeb'
import { Tabs } from 'components/common/Tabs'
import { SUPPLIER_TYPES } from 'constants/supplier'
import { useIdentity } from 'lib/solta-id-react'
import { styled, apply } from 'lib/styled'

const Layout = styled.div(
  apply('flex flex-column w-full bg-white rounded-xl shadow-sm relative')
)

const LayoutForm = Layout.withComponent(Form)

const CloudBackground = styled.div({
  height: '220px',
  backgroundImage: `url('${CloudLayoutImage}')`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'bottom right',
  marginRight: '-24px',
  marginBottom: '-24px',
})

const Container = styled.div(apply('py-8 px-6 relative'), {
  overflowY: 'auto',
  height: '100%',
})

const SpinnerContainer = styled.div({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
})

// eslint-disable-next-line complexity
const CompanyLayout = ({
  children,
  footer,
  loading,
  company,
  type,
  status,
  isAdmin,
  isInternalEntity,
}) => {
  const { editing } = useContext(FormLayoutContext)
  const { role } = useIdentity()

  const LayoutComp = editing ? LayoutForm : Layout

  const tabsBase = [
    {
      title: 'Company Details',
      path: `/company/details`,
      id: 'details',
    },
    {
      title: 'Company Rate Card',
      path: `/company/ratecard`,
      id: 'ratecard',
    },
  ]

  const tabs = [
    ...tabsBase,
    {
      title: 'Aviation Details',
      path: `/company/aviation`,
      id: 'aviation',
    },
  ]

  return (
    <LayoutComp>
      {status && !loading && !isInternalEntity && role !== 'user' ? (
        <StatusBarWeb status={status} company={company} />
      ) : null}

      <Tabs
        tabs={isAdmin || type?.[0] !== SUPPLIER_TYPES.DATA_ANALYST[0] ? tabs : tabsBase}
      />
      <Container>
        {loading ? (
          <SpinnerContainer>
            <Spinner size={64} thickness={7} style={{ margin: 'auto 0' }} />
          </SpinnerContainer>
        ) : (
          children
        )}
        {!loading ? <CloudBackground /> : null}
      </Container>

      {footer || null}
    </LayoutComp>
  )
}

export { CompanyLayout }
