import { KeyContactsSection } from './KeyContactsSection'

import { styled } from 'lib/styled'

const Container = styled.section()

function CompanyDetailsForm({ id, isEditing, isActive, isModal, ...props }) {
  return (
    <Container {...props}>
      <KeyContactsSection id={id} isEditing={isEditing} />
    </Container>
  )
}

export { CompanyDetailsForm }
