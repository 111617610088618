import { styled } from 'lib/styled'

export function TermsCopywriting() {
  const Subtitle = styled.h2({
    fontWeight: 700,
    margin: 0,
    fontSize: '.875rem',
    textAlign: 'left',
  })
  const Li = styled.li({
    margin: '.4rem 0 .4rem 0',
  })
  const Subcontent = styled.div({
    margin: '1rem 0 2rem 0',
  })

  return (
    <>
      <Subtitle>NEARBYSKY NETWORK TERMS AND CONDITIONS</Subtitle>
      <Subcontent>
        Welcome to NearbySky! <br />
        Our online network facilitates our platform users to access a network of UAV &
        Data Service Providers (Network). This agreement governs your use of the Network
        and any Jobs conducted through the Network. These are the key terms to
        understand, to help you read this agreement:
      </Subcontent>
      <Subtitle>TERMS</Subtitle>
      <Subcontent>
        <b>Account</b> : Means a User’s unique profile on the Network.
        <br />
        <b>Booking</b> : Has the meaning given in clause 2.1(e).
        <br />
        <b>Customer</b> : Means a User that registers an Account for the purpose of
        accessing Service Providers to conduct Jobs.
        <br />
        <b>Data</b> : Means any images, videos or other information generated by a Job.
        <br />
        <b>Intellectual Property Rights</b> : Means all copyright, trade mark, design,
        patent, semiconductor and circuit layout rights, trade, business, company and
        domain names, confidential and other proprietary rights, and any other rights to
        registration of such rights whether created before or after the date of this
        agreement both in Australia and throughout the world.
        <br />
        <b>Job</b> : Means any UAV job activity requested or conducted through the
        Network, including any associated Data.
        <br />
        <b>NearbySky</b> : Means NearbySky Pty Ltd ACN 625 133 128
        <br />
        <b>Network</b> : Means the marketplace enabled via our portal or mobile
        application, and any other websites or applications owned or operated by Nearby
        Sky for the same or similar purposes as this Network.
        <br />
        <b>Quoted Amount</b> : Means the amount estimated at the point of a reservation
        being made between a Customer and Service Provider, inclusive of the Service
        Cost the Service Fee, as based on the Service Provider’s Rate Card.
        <br />
        <b>Rate Card</b> : Means the costs charged by the Service Provider which will
        form the basis of the Quoted Amount.
        <br />
        <b>Service Provider</b> : Means a User that registers an Account for the purpose
        of performing Jobs or Data analysis services.
        <br />
        <b>Service Cost</b> : Means the amount payable by the Customer to the Service
        Provider for a Job or any other services.
        <br />
        <b>Service Fee</b> : Means the percentage of the Service Cost that will be
        payable by the Customer to NearbySky, on top of the Service Cost, which as at
        the date of agreement is 12% (unless otherwise stated on the Network).
        <br />
        <b>Start Date & Time</b> : Means the date agreed between a Service Provider and
        a Customer for the start of a UAV Flight.
        <br />
        <b>Users</b> : Means any person that uses the Network in any capacity, including
        Service Providers and Customers.
      </Subcontent>
      <Subtitle>TERMS AND CONDITIONS</Subtitle>
      <Subcontent>
        <b>1. ACCOUNT REGISTRATION</b>
        <ol type="a">
          <Li>
            All Users are required to sign-up, register and receive an account through
            the Network (Account).
          </Li>
          <Li>
            By registering for an Account, you agree to be bound by this agreement which
            forms a binding contractual agreement between you, the User, and NearbySky.
          </Li>
          <Li>
            As part of the Account registration process and as part of your continued
            use of the Network, you are required to provide personal information and
            such as:
            <ol type="i">
              <Li>
                your profile and contact information, including your email address,
                first and last name;
              </Li>
              <Li>your location by enabling location services;</Li>
              <Li>
                if you are a Service Provider, your equipment, licence and insurance
                details; and
              </Li>
              <Li>
                all other identifying information related to any registered entity you
                operate under; and
              </Li>
              <Li>
                any other information reasonably requested by Nearby Sky from time to
                time.
              </Li>
            </ol>
          </Li>
          <Li>
            You warrant that any information you give to Nearby Sky will always be
            accurate, honest, correct and up-to-date. This includes any information
            provided by Service Providers in relation to their qualifications, skills
            and experience relating to Jobs. You indemnify Nearby Sky for any claims
            arising out of false, incomplete or misleading information.
          </Li>
          <Li>
            You must not access the Network if you are under the age of 18 years old and
            do not have your parent or guardian’s consent, or if you have previously
            been suspended or prohibited from using the Network.
          </Li>
          <Li>
            Once you complete the Account registration process, Nearby Sky may, in its
            absolute discretion, choose to accept you as a registered user within the
            Network and provide you with an Account. Nearby Sky may delegate the
            approval of a Service Provider’s Account to any relevant Customer of Nearby
            Sky.
          </Li>
        </ol>
      </Subcontent>
      <Subcontent>
        <Subtitle>2. JOBS</Subtitle>
        <br />
        2.1 BOOKING
        <br />
        <ol type="a">
          <Li>
            A Service Provider may list their skills and availability on the Network.
          </Li>
          <Li>
            A Customer who requires a Job may engage the Network to provide Job services
            subject to availability.
          </Li>
          <Li>
            If a Service Provider receives a notice that a Customer is seeking a Job,
            the Service Provider may accept or reject the request.
          </Li>
          <Li>
            If a Service Provider accepts the request, the Service Provider may accept
            the Booking and Nearby Sky will notify the Customer that the Booking is
            confirmed, at which point an agreement is formed between the Customer and
            the Service Provider for the provision of the Job (Booking).
          </Li>
          <Li>
            All Users acknowledge and agree that Nearby Sky will not be a party to any
            Booking or agreement for any Job.
          </Li>
        </ol>
      </Subcontent>
      <Subcontent>
        2.2 PERFORMANCE
        <br />
        <br />A Service Provider who agrees to a Booking with a Customer must:
        <ol type="a">
          <Li>
            ensure that performance of the Job occurs at the scheduled Start Date & Time
            (unless otherwise agreed in accordance with clause 6.1);
          </Li>
          <Li>perform the Job with due care and skill;</Li>
          <Li>
            ensure they aware of an comply with all CASA compliance and regulatory
            requirements and currently hold the appropriate insurance to perform the
            Job; and
          </Li>
          <Li>
            keep the Customer informed of any issues that may effect the Job or the
            associated Data, including adverse weather limiting visibility.
          </Li>
        </ol>
        Once a Booking is made, a Service Provider may only reschedule or cancel a Job
        in accordance with clause 6.1.
      </Subcontent>
      <Subtitle>3. NEARBYSKY NOT RESPONSIBLE FOR SERVICE PROVIDERS</Subtitle>
      <Subcontent>
        The parties and all Customers acknowledge and agree that:
        <ol type="a">
          <Li>
            the Network provides links and introductions to Service Providers who are
            third parties that are not under the control of, or employed by, Nearby Sky;
          </Li>
          <Li>
            the provision by Nearby Sky of introductions to Service Providers does not
            imply any endorsement or recommendation by Nearby Sky of any Service
            Provider; and
          </Li>
          <Li>
            Nearby Sky does not examine, determine or warrant the certification and/or
            licensing, competence, solvency or information of any Service Provider who
            uses the Network.
          </Li>
        </ol>
      </Subcontent>
      <Subtitle>4. PROVISION OF DATA</Subtitle>
      <Subcontent>
        <ol type="a">
          <Li>
            During each Job, the Service Provider is responsible for collecting all
            required footage, information and imagery agreed between the Customer and
            the Service Provider prior to the Job (Data).
          </Li>
          <Li>
            The Customer is responsible for ensuring that it notifies the Service
            Provider, prior to the Job, of any changes to the Data requirements.
          </Li>
          <Li>
            Once the Job has commenced, the Service Provider is not required to accept
            any changes to the Data requirements.
          </Li>
          <Li>
            Ownership of the Data, including all Intellectual Property Rights arising in
            the Data, will immediately vest in the Customer upon its creation.
          </Li>
          <Li>
            The Customer grants the Service Provider and Nearby Sky an irrevocable,
            perpetual, non-exclusive, worldwide licence to use, adapt, modify and
            distribute (including the right to sublicense) all Data for their respective
            purposes.
          </Li>
        </ol>
      </Subcontent>
      <Subtitle>5. SERVICE COSTS & NEARBY SKY FEES</Subtitle>
      <Subcontent>
        <ol type="a">
          <Li>
            Creating an Account on the Network is free. You may then be provided with an
            invitation code to join particular workspaces within the Network.
          </Li>
          <Li>
            The Service Provider will provide the Customer with a Quoted Amount, being
            the sum of:
            <ol type="i">
              <Li>
                the estimated Service Cost based on the Service Provider’s Rate Card;
                and
              </Li>
              <Li>the Service Fee which will be charged by NearbySky.</Li>
            </ol>
          </Li>
          <Li>
            Unless the Network provides functionality to agree a fixed price, the
            Service Cost will be determined after the Job, based on the Service
            Provider’s Rate Card and the actual flight time. If the Network provides
            functionality to agree a fixed price, that fixed price will form the Service
            Cost regardless of the actual flight time.
          </Li>
          <Li>
            The amount of the Service Fee is the percentage of the Service Cost, as
            displayed on the Network at the time of making a Booking, which will be
            charged to the Customer on top of the Service Cost.
          </Li>
          <Li>
            Unless otherwise agreed in writing, NearbySky reserves the right to vary the
            Service Fee at any time by agreeing to a new Service Fee with the Customer
            in writing.
          </Li>
          <Li>
            Service Providers must only charge the Customer based on the Service
            Provider’s Rate Card and with reference to the actual time performing the
            Job. Service Providers must not attempt to claim any other additional amount
            from the Customer for the Jobs.
          </Li>
          <Li>
            Once a Flight is completed:
            <ol type="i">
              <Li>the Service Provider must submit the Flight Data; and</Li>
              <Li>
                Nearby Sky will provide an invoice to the Customer based on the Flight
                Data.
              </Li>
            </ol>
          </Li>
          <Li>
            Nearby Sky reserves the right to charge credit card surcharges in the event
            that payments are made using a credit, debit or charge card (including Visa,
            MasterCard or American Express).
          </Li>
          <Li>
            Service Providers are responsible for the collection and remission of all
            taxes associated with any transactions made through the Network. Nearby Sky
            will not be held accountable in relation to any transactions where tax
            related misconduct has occurred.
          </Li>
        </ol>
      </Subcontent>
      <Subtitle>6. CHANGES TO YOUR JOB</Subtitle>
      <Subcontent>
        6.1 CANCELLATION PRIOR TO JOB
        <br />
        <ol type="a">
          <Li>
            Either party may postpone or cancel a Job without financial liability if
            more than 48 hours’ notice is provided via the Network or any connected app
            platforms.
          </Li>
          <Li>
            The parties agree that a Job must be postponed (without financial liability
            to either party) in the event of unsuitable weather. The parties may agree
            to postpone the Drone Flight up to the Start Time of the Job due to
            unsuitable weather. Unless clause 6.1(b) applies, if a Customer postpones or
            cancels a Job with less than 48 hours’ written notice, the Service Fee will
            be fully payable, and 50% of the Service Cost will be refunded (if paid in
            advance) or charged (as applicable) to the Customer.
          </Li>
          <Li>
            If a Service Provider postpones or cancels a Job with less than 48 hours’
            written notice, any amounts already paid by the Customer will be fully
            refunded to the Customer and the Service Provider may be removed from the
            Network.
          </Li>
        </ol>
      </Subcontent>
      <Subcontent>
        6.2 REFUND AFTER JOBS
        <br />
        <ol type="a">
          <Li>
            Unless there is a major failure with the technical functioning of the
            Network, the Service Fee is fully non-refundable.
          </Li>
          <Li>
            The Customer may request a refund where it considers:
            <ol type="i">
              <Li>
                the Job was not properly performed in accordance with the requirements
                agreed in writing between the Service Provider and the Customer; or
              </Li>
              <Li>the Data is faulty or defective.</Li>
            </ol>
          </Li>
          <Li>
            The following process applies where a Customer requests a refund:
            <ol type="i">
              <Li>
                The Customer must make the request in writing to Nearby Sky identifying
                the reasons why the refund is requested.
              </Li>
              <Li>
                If Nearby Sky agrees with the request, Nearby Sky will promptly:
                <ol type="A">
                  <Li>
                    notify the Service Provider, including the reference number for the
                    Booking and the nature of the fault; and
                  </Li>
                  <Li>
                    decide, in its absolute discretion, on an appropriate outcome
                    including potential refunds or replacement services for the Service
                    Provider (to be performed by the Service Provider at no additional
                    cost to Nearby Sky or the Customer).
                  </Li>
                </ol>
              </Li>
              <Li>
                If Nearby Sky determines in its reasonable opinion that the Customer is
                not entitled to a refund, Nearby Sky may refuse the request, giving
                reasons. If Nearby Sky’s decision is not satisfactory to the Service
                Provider or the Customer, the Service Provider and the Customer will
                follow the process in clause 14 to resolve the dispute.
              </Li>
              <Li>
                Nothing in this clause 6 is intended to limit or otherwise affect the
                operation of a Customer’s rights which cannot be excluded under
                applicable law, including the Australian Consumer Law.
              </Li>
            </ol>
          </Li>
          <Li>
            If Nearby Sky determines, in its absolute discretion, that a Service
            Provider has failed to meet the acceptable standards for participation in
            the Network as a Service Provider, including by providing faulty Data or
            failing to cooperate to resolve a Customer’s grievance, Nearby Sky may
            terminate the Service Provider’s Account.
          </Li>
        </ol>
      </Subcontent>
      <Subtitle>7. RESTRICTION ON BYPASSING</Subtitle>
      <Subcontent>
        <ol type="a">
          <Li>
            Correspondence between Users to arrange a Job must take place on the
            Network. Users must ensure that their Account does not display any of their
            personal contact information at any time such that it can be viewed by any
            other User. Users agree to not give their contact details to any other User.
          </Li>
          <Li>
            Once a Booking is made, Users may correspond about that Booking in whatever
            method is convenient.
          </Li>
          <Li>
            Users agree that they must not, either directly or indirectly, solicit or
            attempt to solicit any business, work, income or other benefit related to
            the provision of Jobs , from any other User whom they came to know about, or
            with whom they provided Jobs to directly or indirectly, by using the
            Network. This provision will apply whether or not either of the Users is
            still active on the Network.
          </Li>
          <Li>
            Nearby Sky may, in its absolute discretion, cancel an Account and suspend a
            User from using the Network if it finds or suspects that the User has
            breached this clause 6.
          </Li>
        </ol>
      </Subcontent>
      <Subtitle>8. REVIEWS OF OTHER USERS</Subtitle>
      <Subcontent>
        <ol type="a">
          <Li>
            Users may rate another User (Reviews) if and when they have completed a Job
            to or from that User (Experience).
          </Li>
          <Li>
            Reviews of a User can be viewed by any User and will remain viewable until
            the relevant User’s Account is removed or terminated.
          </Li>
          <Li>
            Users must provide true, fair and accurate information in their Review.
          </Li>
          <Li>
            If Nearby Sky considers that a Review is untrue, unfair, inaccurate,
            offensive or inappropriate, we may delete the Review. However, we do not
            undertake to review each Review made by a User.
          </Li>
          <Li>
            To the maximum extent permitted by law, we are not responsible for the
            content of any Reviews.
          </Li>
          <Li>
            Users are encouraged to be specific and factual in their Reviews. If a User
            has been offered an incentive by another User to write a Review, that
            incentive should be included in the Review.
          </Li>
        </ol>
      </Subcontent>
      <Subtitle>9. USER OBLIGATIONS</Subtitle>
      <Subcontent>
        As a User, you agree:
        <ol type="a">
          <Li>
            not to intimidate, harass, impersonate, stalk, threaten, bully or endanger
            any other User or distribute unsolicited commercial content, junk mail,
            spam, bulk content or harassment;
          </Li>
          <Li>
            to not share your Account with any other person and that any use of your
            Account by any other person is strictly prohibited. You must immediately
            notify Nearby Sky of any unauthorised use of your Account, password or
            email, or any other breach or potential breach of the Network’s security;
          </Li>
          <Li>
            to not use the Network for any purpose other than for the purpose of making
            arrangements to provide or receive Jobs, including:
            <ol type="i">
              <Li>
                you must not use the Network in a manner that is illegal or fraudulent
                or facilitates illegal or fraudulent activity (including requesting or
                accepting a job or a Flight which includes illegal Jobs, activities or
                purposes); and
              </Li>
              <Li>
                you must not use the Network in connection with any commercial or money
                making or other promotional or marketing endeavours except those that
                are endorsed herein, or as approved in writing by Nearby Sky;
              </Li>
            </ol>
          </Li>
          <Li>
            not to act in any way that may harm the reputation of Nearby Sky or
            associated or interested parties or do anything at all contrary to the
            interests of Nearby Sky or the Network;
          </Li>
          <Li>
            you must not make any automated use of the Network and you must not copy,
            reproduce, translate, adapt, vary or modify the Network without the express
            written consent of Nearby Sky;
          </Li>
          <Li>
            that Nearby Sky may change any features of the Network or Jobs offered
            through the Network at any time without notice to you;
          </Li>
          <Li>
            that information given to you through the Network, by Nearby Sky or another
            User including a Service Provider, is general in nature and we take no
            responsibility for anything caused by any actions you take in reliance on
            that information; and
          </Li>
          <Li>
            that Nearby Sky may cancel your account at any time, including if it
            considers, in its absolute discretion, that you are in breach or are likely
            to breach this clause 2.
          </Li>
        </ol>
      </Subcontent>
      <Subtitle>10. POSTED CONTENT</Subtitle>
      <br />
      10.1 WARRANTIES
      <Subcontent>
        By creating an Account, agreeing to a Booking or otherwise posting any requests,
        questions, information, materials or other content on the Network (Posted
        Content), you represent and warrant that:
        <ol type="a">
          <Li>
            you are authorised to provide the Posted Content (including by being
            authorised to perform any services that you represent you provide);
          </Li>
          <Li>the Posted Content is accurate and true at the time it is provided;</Li>
          <Li>
            any Posted Content which is in the form of a review or feedback is honest,
            accurate and presents a fair view of the relevant person and/or your
            experience;
          </Li>
          <Li>
            the Posted Content is free from any harmful, discriminatory, defamatory or
            maliciously false implications and does not contain any offensive or
            explicit material;
          </Li>

          <Li>
            the Posted Content does not infringe any Intellectual Property Rights,
            including copyright, trademarks, business names, patents, confidential
            information or any other similar proprietary rights, whether registered or
            unregistered, anywhere in the world;
          </Li>
          <Li>
            the Posted Content does not contain any viruses or other harmful code, or
            otherwise compromise the security or integrity of the Network or any network
            or system; and
          </Li>

          <Li>the Posted Content does not breach or infringe any applicable laws.</Li>
        </ol>
      </Subcontent>
      <br />
      10.2 LICENCE
      <Subcontent>
        You grant to Nearby Sky a perpetual, irrevocable, transferable, worldwide and
        royalty-free licence (including the right to sublicense) to use, copy, modify,
        reproduce and adapt any Intellectual Property Rights in any Posted Content in
        order for Nearby Sky to use, exploit or otherwise enjoy the benefit of such
        Posted Content.
        <ol type="a">
          <Li>
            You indemnify Nearby Sky against all damages, losses, costs and expenses
            incurred by Nearby Sky arising out of any third party claim that your Posted
            Content infringes any third party’s Intellectual Property Rights.
          </Li>
        </ol>
      </Subcontent>
      10.3 REMOVAL
      <Subcontent>
        <ol type="a">
          <Li>
            Nearby Sky acts as a passive conduit for the online distribution of Posted
            Content and has no obligation to screen Posted Content in advance of it
            being posted. However, Nearby Sky may, in its absolute discretion, review
            and remove any Posted Content at any time without giving any explanation or
            justification for removing the Posted Content.
          </Li>
          <Li>
            You agree that you are responsible for keeping and maintaining records of
            Posted Content.
          </Li>
        </ol>
      </Subcontent>
      10.4 THIRD PARTY CONTENT
      <Subcontent>
        The Network may contain text, images, data and other content provided by a third
        party and displayed on the Network (Third Party Content). Nearby Sky accepts no
        responsibility for Third Party Content and makes no representation, warranty or
        guarantee about the quality, suitability, accuracy, reliability, currency or
        completeness of Third Party Content.
      </Subcontent>
      <Subtitle>11. SERVICE LIMITATIONS</Subtitle>
      <Subcontent>
        The Network is made available to you strictly on an ’as is’ basis. Without
        limitation, you acknowledge and agree that Nearby Sky cannot and does not
        represent, warrant or guarantee that:
        <ol type="a">
          <Li>the Network will be free from errors or defects;</Li>
          <Li>the Network will be accessible at all times;</Li>
          <Li>
            information you receive or supply through the Network will be secure or
            confidential; or
          </Li>
          <Li>any information provided through the Network is accurate or true.</Li>
        </ol>
      </Subcontent>
      <Subtitle>12. PROTECTION OF INFORMATION</Subtitle>
      <br />
      12.1 CONFIDENTIALITY
      <Subcontent>
        You agree that:
        <ol type="a">
          <Li>
            no information owned by Nearby Sky, including system operations, documents,
            marketing strategies, staff information and client information, may be
            disclosed or made available to any third parties; and
          </Li>
          <Li>
            all communications involving the details of other Users on this Network and
            of the Service Provider are confidential, and must be kept as such by you
            and must not be distributed nor disclosed to any third party.
          </Li>
        </ol>
        When you provide information to NearbySky, you acknowledge and agree that
        NearbySky may share that information with other Users for the purposes of
        facilitating Jobs. You may notify NearbySky prior to providing us any
        information that the particular information is not to be shared with another
        User, in which case NearbySky will keep that information confidential.
      </Subcontent>
      12.2 PRIVACY
      <Subcontent>
        You agree to be bound by the clauses outlined in Nearby Sky’s Privacy Policy,
        which can be accessed here
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://nearbysky.com/privacy/"
        >
          {' '}
          https://nearbysky.com/privacy/
        </a>
      </Subcontent>
      12.3 RECORDS AND AUDITS
      <Subcontent>
        To the extent permitted by law, Nearby Sky reserves the right to keep all
        records of any and all transactions and communications made through this Network
        between you and other Users (including conversations, user posts, job workflows,
        product offerings, partner rates, data files, request bids, comments, feedback,
        cookies, and I.P. address information) for administration purposes and also
        holds the right to produce these records in the event of any legal dispute
        involving Nearby Sky.
      </Subcontent>
      <Subtitle>13. DISPUTES BETWEEN USERS</Subtitle>
      <Subcontent>
        <ol type="a">
          <Li>
            If any issue or problem relating to the Network remains unresolved after
            directing a complaint to a relevant User, or if the complaint does not
            relate to another User, you must report it to Nearby Sky via the platform or
            by contacting support@nearbysky.com. Nearby Sky will assess the complaint
            and attempt to quickly and satisfactorily resolve it.
          </Li>
          <Li>
            Nearby Sky reserves the right to hold funds in relation to a dispute until
            the dispute is resolved.
          </Li>
          <Li>
            Any costs you incur in relation to a complaint or dispute will be your
            responsibility.
          </Li>
          <Li>
            If you have a dispute with Nearby Sky, or a dispute with another User that
            NearbySky is unable to resolve, the parties to the dispute will conduct
            mediation through the Australian Disputes Centre (ADC) and in accordance
            with the ADC’s Guidelines for Commercial Mediation (as current at the time
            of the dispute).
          </Li>
          <Li>
            The parties will follow the mediator’s recommendations on the extent of
            mediation required, and when to stop mediation if the issue cannot be
            resolved.
          </Li>
          <Li>
            The parties will use best endeavours to avoid litigation and reach a prompt
            settlement.
          </Li>
          <Li>
            The process in this clause does not apply where a party requires an urgent
            injunction.
          </Li>
        </ol>
      </Subcontent>
      <Subtitle>14. DISCLAIMER</Subtitle>
      <Subcontent>
        <ol type="a">
          <Li>
            <b>(Security)</b> Nearby Sky does not accept responsibility for loss or
            damage to computer systems, mobile phones or other electronic devices
            arising in connection with your use of the Network. You should take your own
            precautions to ensure that the process you employ to access the Network does
            not expose you to the risk of viruses, malicious computer code or other
            forms of interference.
          </Li>
          <Li>
            <b>(Introduction service)</b> NearbySky Network is a medium that facilitates
            the introduction of Customers and Service Providers for the purposes of
            buying and selling UAV & Data Services. NearbySky Network simply collects a
            Service Fee in consideration for providing this introduction service and
            does not have any obligations or liabilities to, and is not a party to any
            contract between, Customers and Service Providers in relation to such Jobs
            otherwise resulting from the introduction.
          </Li>
          <Li>
            <b>(Limitation of liability)</b> To the maximum extent permitted by
            applicable law, the maximum aggregate liability of Nearby Sky to any person
            for loss or damage of any kind, however arising whether in contract, tort
            (including negligence), statute, equity, indemnity or otherwise, arising
            from or relating in any way to the Network or any Job is the Service Fee
            paid to Nearby Sky for the relevant Job giving rise to the liability.
          </Li>
          <Li>
            <b>(Disclaimer)</b> All express or implied representations and warranties
            are, to the maximum extent permitted by applicable law, excluded. Where any
            (including the Competition and Consumer Act 2010 (Cth)) implies a condition,
            warranty or guarantee into this agreement which may not lawfully be
            excluded, then to the maximum extent permitted by applicable law, Nearby
            Sky’s liability for breach of that non-excludable condition, warranty or
            guarantee will, at our option, be limited to:
            <ol type="i">
              <Li>
                in the case of Jobs, their replacement or the supply of equivalent Jobs
                or their repair; and
              </Li>
              <Li>
                in the case of services, the supply of the services again, or the
                payment of the cost of having them supplied again.
              </Li>
            </ol>
          </Li>
          <Li>
            <b>(Indemnity)</b> You agree to indemnify Nearby Sky and its employees and
            agents in respect of all liability for loss, damage or injury which may be
            suffered by any person arising from you or your representatives:
            <ol type="i">
              <Li>breach of any term of this agreement;</Li>
              <Li>misuse of the Network or Platform; or</Li>
              <Li>provision or receipt of Jobs from another User.</Li>
            </ol>
            Nearby Sky agrees to indemnify each User and its employees and agents in
            respect of all liability for loss, damage or injury which may be suffered by
            any person arising from:
            <ol type="i">
              <Li>NearbySky’s breach of any term of this agreement; or</Li>
              <Li>NearbySky’s failure to provide the Platform.</Li>
            </ol>
          </Li>
          <Li>
            <b>(Consequential loss)</b> To the maximum extent permitted by law, under no
            circumstances will Nearby Sky be liable for any incidental, special or
            consequential loss or damages (including to persons or property), or damages
            for loss of data, business or business opportunity, goodwill, anticipated
            savings, profits or revenue arising under or in connection with the Network,
            this agreement or their subject matter, or any services provided by any
            Service Provider (except to the extent this liability cannot be excluded
            under the Competition and Consumer Act 2010 (Cth)).
          </Li>
        </ol>
      </Subcontent>
      <Subtitle>15. TERMINATION</Subtitle>
      <Subcontent>
        <ol type="a">
          <Li>
            Nearby Sky reserves the right to terminate a User’s access to any or all of
            the Network at any time without notice, for any reason.
          </Li>
          <Li>
            If a User’s Account, access or membership is terminated, access to the
            Network will be revoked.
          </Li>
          <Li>
            Users may terminate their Account or membership on the Network at any time
            by using the Network’s functionality where such functionality is available.
            Where such functionality is not available, Nearby Sky will effect such
            termination within a reasonable time after receiving written notice from the
            User.
          </Li>
          <Li>
            Notwithstanding termination or expiry of your Account or membership or this
            agreement, any provision which by its nature would reasonably be expected to
            be complied with after termination or expiry, will continue to apply.
          </Li>
        </ol>
      </Subcontent>
      <Subtitle>16. NOTICES</Subtitle>
      <Subcontent>
        <ol type="a">
          <Li>
            Any notices required to be sent under this agreement must be sent via email
            using the party’s email addresses most regularly used in connection with
            this agreement, and the email’s subject heading must refer to this
            agreement.
          </Li>
          <Li>
            The notice will be considered to be delivered 24 hours after it was sent,
            unless the sender has reason to believe the email failed to send or was
            otherwise not delivered or received.
          </Li>
        </ol>
      </Subcontent>
      <Subtitle>17. GENERAL</Subtitle>
      <br />
      17.1 GOVERNING LAW AND JURISDICTION
      <Subcontent>
        This agreement is governed by the law applying in New South Wales, Australia.
        Each party irrevocably submits to the exclusive jurisdiction of the courts of
        New South Wales, Australia and courts of appeal from them in respect of any
        proceedings arising out of or in connection with this agreement. Each party
        irrevocably waives any objection to the venue of any legal process on the basis
        that the process has been brought in an inconvenient forum.
      </Subcontent>
      17.2 WAIVER
      <Subcontent>
        No party to this agreement may rely on the words or conduct of any other party
        as a waiver of any right unless the waiver is in writing and signed by the party
        granting the waiver.
      </Subcontent>
      17.3 SEVERANCE
      <Subcontent>
        Any term of this agreement which is wholly or partially void or unenforceable is
        severed to the extent that it is void or unenforceable. The validity and
        enforceability of the remainder of this agreement is not limited or otherwise
        affected.
      </Subcontent>
      17.4 JOINT AND SEVERAL LIABILITY
      <Subcontent>
        An obligation or a liability assumed by, or a right conferred on, two or more
        persons binds or benefits them jointly and severally.
      </Subcontent>
      17.5 INTERPRETATION
      <Subcontent>
        <ol type="a">
          <Li>
            <b>(singular and plural)</b> words in the singular includes the plural (and
            vice versa);
          </Li>
          <Li>
            <b>(gender)</b> words indicating a gender includes the corresponding words
            of any other gender;
          </Li>
          <Li>
            <b>(defined terms)</b> if a word or phrase is given a defined meaning, any
            other part of speech or grammatical form of that word or phrase has a
            corresponding meaning;
          </Li>

          <Li>
            <b>(person)</b> a reference to &quot;person&quot; or &quot;you&quot;
            includes an individual, the estate of an individual, a corporation, an
            authority, an association, consortium or joint venture (whether incorporated
            or unincorporated), a partnership, a trust and any other entity;
          </Li>
          <Li>
            <b>(party)</b> a reference to a party includes that party’s executors,
            administrators, successors and permitted assigns, including persons taking
            by way of novation and, in the case of a trustee, includes any substituted
            or additional trustee;
          </Li>
          <Li>
            <b>(this agreement)</b> (this agreement) a reference to a party, clause,
            paragraph, schedule, exhibit, attachment or annexure is a reference to a
            party, clause, paragraph, schedule, exhibit, attachment or annexure to or of
            this agreement, and a reference to this agreement includes all schedules,
            exhibits, attachments and annexures to it;
          </Li>

          <Li>
            <b>(document) </b> a reference to a document (including this agreement) is
            to that document as varied, novated, ratified or replaced from time to time;
          </Li>
          <Li>
            <b>(headings)</b> headings and words in bold type are for convenience only
            and do not affect interpretation;
          </Li>
          <Li>
            <b>(includes)</b> the word &quot;includes&quot; and similar words in any
            form is not a word of limitation; and
          </Li>
          <Li>
            <b>(adverse interpretation)</b> no provision of this agreement will be
            interpreted adversely to a party because that party was responsible for the
            preparation of this agreement or that provision.
          </Li>
        </ol>
      </Subcontent>
    </>
  )
}
