/* eslint-disable complexity */
import CONSTANTS from '@seedcloud/constants'
import { connect } from '@seedcloud/stateless'
import { useLayoutEffect, useState } from 'react'
import * as Validator from 'yup'

import { CustomerProfile } from '../common/CustomerProfile'

import { styled, apply } from 'lib/styled'
import toaster from 'lib/toaster'
import { authModule, user$ } from 'modules/auth'
import { optionalString } from 'utils/validation'

const { object, string } = Validator
const requiredString = (label) => string().required().label(label)

const validationSchema = object({
  firstName: requiredString('First name'),
  lastName: optionalString('Last name'),
  phoneNumber: requiredString('Phone number').matches(
    CONSTANTS.REGEX.E164_PHONE_FORMAT,
    'Not a valid phone number'
  ),
  organization: object({
    name: requiredString('Company Name'),
    abn: requiredString('ABN'),
    address: optionalString('Address'),
  }),
})

const ConnectedCustomerProfile = connect(() => ({ user: user$ }))(
  CustomerProfileContainer
)
function CustomerProfileContainer({ user }) {
  const [loading, setLoading] = useState(false)
  const [editing, setEditing] = useState(false)

  useLayoutEffect(() => {
    setLoading(true)
    authModule.getProfile()
    setLoading(false)
  }, [])
  const { firstName, lastName, phoneNumber, email, organization } = user

  const initialValues = {
    firstName,
    lastName,
    phoneNumber,
    email,
    organization: {
      name: organization.name,
      abn: organization.abn,
      address: organization.address,
    },
  }

  const customerDetailsProps = {
    initialValues,
    validationSchema,
    editing,
    setEditing,
    toaster,
    loading,
    onSubmit: async (values) => {
      await authModule.updateProfile('', values).then((response) => {
        if (response) {
          toaster.success('Update Success!')
        }
      })
      setEditing(false)
    },
  }

  return <CustomerProfile {...customerDetailsProps} />
}

const PaddedContainer = styled.div(apply('flex-1 flex flex-row p-6'))

function CustomerDetailsWithContainer() {
  return (
    <PaddedContainer>
      <CustomerProfileContainer />
    </PaddedContainer>
  )
}

export { ConnectedCustomerProfile as CustomerProfile, CustomerDetailsWithContainer }
