import CONSTANTS from '@seedcloud/constants'
import { head, keys, path, pipe } from '@seedcloud/ramda-extra'
import { connect } from '@seedcloud/stateless'
import { Formik as FormProvider, Form } from 'formik'
import { useHistory } from 'react-router-dom'
import * as Validator from 'yup'

import { StaffDetailsForm } from '../StaffDetails/StaffDetailsForm'

import { CancelButton, CreateButton } from 'components/common/Button'
import { Spinner } from 'components/common/Spinner'
import { roleKey } from 'constants/roles'
import { useIdentity } from 'lib/solta-id-react'
import { styled, apply } from 'lib/styled'
import { isCreatingStaff$, staffModule } from 'modules/staff'

const { string, object } = Validator
const requiredString = string().required('This field is required')
const optionalString = string().nullable().optional()

const validationSchema = object({
  userDetails: object({
    firstName: requiredString,
    lastName: optionalString,
    phoneNumber: requiredString.matches(
      CONSTANTS.REGEX.E164_PHONE_FORMAT,
      'Please include a country code (e.g +64213112)'
    ),
    email: requiredString.email('Not a valid email address'),
  }).required(),
  role: requiredString,
})

const Container = styled.div(
  apply('flex-1 flex flex-column bg-white rounded-lg shadow-sm', {
    overflow: 'auto',
  })
)
const Header = styled.div(apply('flex flex-row py-6'))
const Title = styled.h2(apply('m-0 text-xl font-light'))
const ButtonsContainer = styled.div(apply('flex', { marginLeft: 'auto' }))
const Scrollable = styled.div(
  apply('flex flex-column px-6 pb-6', {
    minHeight: 'min-content',
  })
)

const { createStaff } = staffModule

/**
 * @param {object} user auth0 user
 * @example
 * // Auth0 stringifies the role object under a key
 * {
 *  'https://nearbysky.com/roles':
 *  '{"admin":false,"staff": {"5e7351910af6acca4509faa4":"organization_admin"}}',
 * }
 * @returns {string} organizationId
 */
const parseOrgId = (user) => {
  const role = JSON.parse(user?.[roleKey])
  return pipe(path(['staff']), keys, head)(role)
}

const ConnectedCreateStaff = connect(() => ({
  isCreatingStaff: isCreatingStaff$,
}))(CreateStaff)

function CreateStaff({ isCreatingStaff }) {
  const { user } = useIdentity()
  const history = useHistory()
  const organizationId = parseOrgId(user)

  return (
    <Container>
      <FormProvider
        id="createStaff"
        enableReinitialize
        initialValues={{
          organizationId,
          role: undefined,
          userDetails: {
            firstName: undefined,
            lastName: undefined,
            phoneNumber: undefined,
            email: undefined,
          },
        }}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          const { id } = await createStaff(null, values)
          history.push({ pathname: `/staff/${id}`, state: { isNew: true } })
        }}
      >
        <Scrollable>
          <Form>
            <Header>
              <Title>Create New Staff</Title>
              <ButtonsContainer>
                {isCreatingStaff && (
                  <Spinner size={32} thickness={6} style={{ margin: 'auto 32px' }} />
                )}
                <CreateButton disabled={isCreatingStaff} type="submit">
                  Create
                </CreateButton>
                <CancelButton
                  onClick={() => history.goBack()}
                  style={apply('ml-4')}
                  type="button"
                >
                  Cancel
                </CancelButton>
              </ButtonsContainer>
            </Header>

            <StaffDetailsForm isCreateStaff isEditing={!isCreatingStaff} />
          </Form>
        </Scrollable>
      </FormProvider>
    </Container>
  )
}

export { ConnectedCreateStaff as CreateStaff }
