const SERVICE_CATEGORIES = [
  {
    value: 'photo_video_service',
    readableValue: 'Photo & Video',
  },
  {
    value: 'hd_survey_service',
    readableValue: 'Survey Grade HD',
  },
  {
    value: 'spatial_3d_service',
    readableValue: 'Spatial 3D',
  },
  {
    value: 'lidar_service',
    readableValue: 'LiDAR',
  },
  {
    value: 'thermal_service',
    readableValue: 'Thermal',
  },
  {
    value: 'specialist_service',
    readableValue: 'Specialist',
  },
  {
    value: 'energy_resource_service',
    readableValue: 'Energy Resource Service',
  },
  {
    value: 'spatial_digital_twin_service',
    readableValue: 'Spatial Digital Twin Service',
  },
  {
    value: 'water_service',
    readableValue: 'Water Service',
  },
  {
    value: 'environment_service',
    readableValue: 'Environment Service',
  },
  {
    value: 'industry_specific_service',
    readableValue: 'Industry Specific Service',
  },
  {
    value: 'property_building_service',
    readableValue: 'Property Building Service',
  },
  {
    value: 'transport_service',
    readableValue: 'Transport Service',
  },
]

export { SERVICE_CATEGORIES }
