import { useMemo } from 'react'

import { Button } from 'components/common/Button'
import { Table } from 'components/common/withTable'
import { EditIcon, TrashIcon } from 'components/icons'
import { styled, apply } from 'lib/styled'

const Space = styled.div(apply('flex'), {
  gap: '1rem',
  flex: '0 0 300px',
})

const BaseActionButton = styled(Button)({
  background: 'transparent',
  boxShadow: 'none',
})

const EditButton = styled(BaseActionButton)({
  color: '#00326F',
})

const DeleteButton = styled(BaseActionButton)({
  color: '#D42C16',
})

const PilotEquipmentTable = ({
  currency,
  values,
  handleEdit,
  handleDelete,
  next,
  limit,
  startRow,
  role,
  readOnly,
  onTurnPage,
}) => {
  const handleTurnPage = ({ turnNext }) => {
    onTurnPage(turnNext)
  }

  const EQUIPMENT_CATEGORY_COLUMNS = useMemo(
    () => [
      { Header: 'No', Cell: (prop) => prop.row.index + 1, disableSortBy: true },
      {
        Header: `Equipment Category`,
        accessor: 'description',
        disableSortBy: true,
      },
      {
        Header: `Make`,
        accessor: 'make',
        disableSortBy: true,
      },
      {
        Header: `Model`,
        accessor: 'model',
        disableSortBy: true,
      },
      { Header: `Rate  (${currency}/hr)`, accessor: 'rate', disableSortBy: true },
      ...(role !== 'user'
        ? [
            {
              id: 'actions',
              Header: 'Actions',
              accessor: 'id',
              Cell: ({ value: id }) => (
                <Space>
                  <EditButton onClick={() => handleEdit(id)}>
                    <EditIcon />
                  </EditButton>
                  <DeleteButton onClick={() => handleDelete(id)}>
                    <TrashIcon />
                  </DeleteButton>
                </Space>
              ),
              disableSortBy: true,
            },
          ]
        : []),
    ],
    [currency, values]
  )

  return (
    <Table
      columns={EQUIPMENT_CATEGORY_COLUMNS}
      data={values}
      next={next}
      limit={limit}
      turnPage={handleTurnPage}
      startRow={startRow}
      hiddenColumns={readOnly ? ['actions'] : []}
    />
  )
}

export { PilotEquipmentTable }
