import { styled, apply } from 'lib/styled'

const Container = styled.button(
  apply(
    'tracking-wide uppercase rounded-lg text-xs border-0 border-solid border-blue-lighter font-normal'
  ),
  ({ disabled }) => ({
    color: disabled ? '#262626' : '#FFFF',
    cursor: disabled ? 'not-allowed' : 'pointer',
    textTransform: 'none',
    backgroundColor: disabled ? '#D9D9D9' : '#009DE4',
    border: `1px solid ${disabled ? '#D9D9D9' : '#009DE4'}`,
    fontSize: 15,
    fontWeight: 500,
    padding: '8px 16px',
    boxSizing: 'border-box',
    display: 'inline-block',
    height: 'fit-content',
  })
)
const CancelButton = styled(Button)(
  apply('bg-transparent shadow-none text-blue border-blue')
)
const CreateButton = styled(Button)()

function Button({ ...props }) {
  return <Container {...props} />
}

export { Button, CancelButton, CreateButton }
