import { BaseBadge } from 'components/common/Badge'
import { SUPPLIER_LABELS, SUPPLIER_LABEL_TEXT } from 'constants/supplier'

export const variants = {
  [SUPPLIER_LABELS.ACTIVE]: {
    backgroundColor: '#E0F0E8',
    color: '#005027',
  },
  [SUPPLIER_LABELS.UPDATED_DETAILS]: {
    backgroundColor: '#FAF7E7',
    color: '#AC9100',
  },
  [SUPPLIER_LABELS.PENDING_ACTIVATION]: {
    backgroundColor: '#E3EEF5',
    color: '#00326F',
  },
  [SUPPLIER_LABELS.INVITED]: {
    backgroundColor: '#E3EEF5',
    color: '#00326F',
  },
  [SUPPLIER_LABELS.PENDING_PARTNERSHIP]: {
    backgroundColor: '#FAF7E7',
    color: '#AC9100',
  },
}

const SupplierBadge = ({ status }) => {
  const labelText = SUPPLIER_LABEL_TEXT[status] ?? status
  return (
    <BaseBadge status={status} variants={variants}>
      {labelText}
    </BaseBadge>
  )
}

export { SupplierBadge }
