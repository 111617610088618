import { ThemeProvider } from '@emotion/react'
import { equals } from '@seedcloud/ramda-extra'
import { memo } from 'react'
import { Route } from 'react-router-dom'

import { Register } from 'components/auth/register'
import { RegisterSuccess } from 'components/auth/register/RegisterSuccess'
import { ResetPassword } from 'components/auth/ResetPassword'
import { ClaimCompanyManager } from 'components/claim/ClaimCompanyManager'
import { Dashboards } from 'components/Dashboards'
import { PermissionsProvider } from 'lib/permissions'
import { styled, apply } from 'lib/styled'
import 'react-toastify/dist/ReactToastify.css'
import 'styles/vendors.scss'
import 'styles/index.css'
import { ConfigureToast } from 'lib/toaster'

const Container = styled.div(
  apply('h-full flex flex-column', {
    backgroundColor: '#F8F8F8',
  })
)

const theme = {
  colors: {
    primary: '#009DE4',
  },
}

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Route path="/register" component={Register} />
      <Route path="/register-success" component={RegisterSuccess} />
      <Route path="/reset-password" component={ResetPassword} />
      <Route path="/claim/:token" component={ClaimCompanyManager} />
      <Route
        path="/"
        render={({ location }) =>
          location.pathname.includes('register') ||
          location.pathname.includes('claim') ||
          location.pathname.includes('reset-password') ? null : (
            <PermissionsProvider>
              <Container>
                <Dashboards />
              </Container>
            </PermissionsProvider>
          )
        }
      />
      <ConfigureToast />
    </ThemeProvider>
  )
}

export default memo(App, equals)
