import Modal from 'react-modal'

import { TermsCopywriting } from './TermsCopywriting'

import { Button, CancelButton as CancelButtonBase } from 'components/common/Button'
import { styled, apply } from 'lib/styled'

const Root = styled.div(apply('text-black bg-white rounded-lg'), {
  padding: '48px 64px',
  width: '656px',
  maxWidth: '656px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'left',
  gap: '32px',
})

const Title = styled.h1({
  fontWeight: 700,
  fontSize: '2.25rem',
  textAlign: 'center',
})

const Subtitle = styled.p({
  margin: 0,
  fontSize: '.875rem',
  textAlign: 'center',
})

const AgreementText = styled.p({
  margin: 0,
  fontSize: '.775rem',
  textAlign: 'center',
})

const Content = styled.div({
  margin: 0,
  fontSize: '.875rem',
  textAlign: 'left',
  height: '21rem',
  overflowY: 'scroll',
})

const Space = styled.div(apply('flex'), {
  gap: '1rem',
})

const ActionButton = styled(Button)(apply('flex-1 mt-5'), {
  marginTop: '1rem',
})

const CancelButton = styled(CancelButtonBase)(apply('flex-1 mt-5'), {
  background: 'transparent',
  boxShadow: 'none',
  marginTop: '1rem',
})
const CheckBoxInput = styled.input(apply('mr-4'))
const CheckboxWrapper = styled.div(apply('flex-1 flex flex-row'))

const TermsButton = styled.button({
  backgroundColor: 'white',
  color: '#009de4',
  border: 'none',
  textDecoration: 'underline',
  padding: '0',
})

Modal.setAppElement('#root')

// eslint-disable-next-line complexity
function TermsModal({
  title = 'Terms & Conditions',
  subtitle,
  isOpen,
  closeModal,
  onConfirm,
  loading,
  confirmText = 'Agree',
  confirmLoadingText = 'Confriming...',
  isCheckbox = false,
  checked,
  setChecked,
  isExpanded = true,
  setIsExpanded,
}) {
  const handleClick = () => setChecked(!checked)

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => closeModal()}
      id="unique"
      style={{
        overlay: apply('z-4 p-0 flex items-center justify-center', {
          backgroundColor: 'rgba(20, 20, 20, 0.2)',
        }),
        content: apply('p-0 bg-transparent border-0', {
          inset: 'unset',
          top: '25%',
        }),
      }}
    >
      <Root>
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
        {isCheckbox && (
          <CheckboxWrapper>
            <CheckBoxInput
              id="tnc"
              name="tnc"
              type="checkbox"
              checked={checked}
              onClick={handleClick}
            />
            <AgreementText>
              I agree to the{' '}
              <TermsButton onClick={() => setIsExpanded(!isExpanded)}>
                {' '}
                Terms & Conditions{' '}
              </TermsButton>{' '}
              of being a Supplier within the NearbySky platform
            </AgreementText>
          </CheckboxWrapper>
        )}
        {isExpanded === true && (
          <Content>
            <TermsCopywriting />
          </Content>
        )}
        {onConfirm && (
          <Space>
            <CancelButton disabled={loading} onClick={() => closeModal()}>
              Cancel
            </CancelButton>
            <ActionButton disabled={loading || !checked} onClick={() => onConfirm()}>
              {loading ? confirmLoadingText : confirmText}
            </ActionButton>
          </Space>
        )}
      </Root>
    </Modal>
  )
}

export { TermsModal }
