/* eslint-disable no-param-reassign */
import { isNilOrEmpty, isNotNilOrEmpty, omit, path } from '@seedcloud/ramda-extra'
import { createModule } from '@seedcloud/stateless'
import { circle } from '@turf/turf'
import moment from 'moment'

import { PilotWebService } from './service'

import { UploadService } from 'modules/upload'
import { UserService } from 'modules/user'
// eslint-disable-next-line import/no-cycle
import { getCompanyAndOrganizationId } from 'utils/getOrganizationId'
import { withTryCatch } from 'utils/withTryCatch'

const INITIAL_STATE = Object.freeze({
  entities: {},
  companyName: '',
  companyId: '',
  inspectedEntity: undefined,
  filterQuery: '',
  order: [],
  avatar: undefined,
  paging: {
    next: undefined,
  },
})

const fetchPilots = (module, { setError }) =>
  withTryCatch(
    async (_, { turnPage = true } = {}) => {
      const { filterQuery, paging } = module.getState()
      const { companyId } = await getCompanyAndOrganizationId()
      const next = turnPage ? paging.next : undefined

      const {
        entities,
        order,
        next: newNext,
      } = await PilotWebService.list({
        companyId,
        query: filterQuery,
        next,
      })

      module.setState({
        entities,
        order,
        paging: {
          next: newNext,
        },
      })
    },
    { errHandler: setError }
  )

const setDefaultCompany = (module, { setError }) =>
  withTryCatch(
    async () => {
      const { companyName, companyId, organizationId } =
        await getCompanyAndOrganizationId()
      module.setState({
        companyName,
        companyId,
        organizationId,
      })
    },
    { errHandler: setError }
  )

const inspectPilot = (module, { setError }) =>
  withTryCatch(
    async (id) => {
      module.setState({
        inspectedEntity: id,
      })

      const entity = await PilotWebService.read(id)

      module.setState({
        entities: { [id]: entity },
      })
    },
    { errHandler: setError }
  )

const filterPilots = (module) => (_, filters) => {
  module.setState({
    ...filters,
  })

  module.fetchPilots(null, { turnPage: false })
}

const createPilot = (_module, { setError }) =>
  withTryCatch(
    async (_, payload) => {
      const { userDetails, pilotDetails } = payload

      pilotDetails.addressLocation = pilotDetails.address.geometry
      pilotDetails.address = pilotDetails.address.place_name

      pilotDetails.licenceExpiredAt = moment
        .utc(pilotDetails.licenceExpiredAt)
        .format('YYYY-MM-DD')

      if (isNotNilOrEmpty(pilotDetails.insuranceExpiredAt)) {
        pilotDetails.insuranceExpiredAt = moment
          .utc(pilotDetails.insuranceExpiredAt)
          .format('YYYY-MM-DD')
      }

      pilotDetails.serviceAreas = [
        // `turf` generates a `Feature` but we just want the `Polygon`
        circle(pilotDetails.addressLocation, pilotDetails.serviceRadius, {
          units: 'kilometers',
        }).geometry,
      ]

      PilotWebService.create({
        userDetails,
        ...pilotDetails,
      })
    },
    { errHandler: setError }
  )

const updateIndividualPilot = (module, { setError }) =>
  withTryCatch(
    async (id, payload) => {
      const buildAddressPayload = () => {
        if (payload.address) {
          return {
            addressLocation: payload.address.geometry,
            address: payload.address.place_name,
            serviceAreas: [
              // `turf` generates a `Feature` but we just want the `Polygon`
              circle(payload.address.geometry, payload.serviceRadius, {
                units: 'kilometers',
              }).geometry,
            ],
          }
        }

        return {}
      }
      const previousAvatar = payload.userDetails.avatar
      const { avatar } = module.getState()

      if (isNilOrEmpty(previousAvatar)) {
        payload.userDetails.avatar = undefined
      }

      if (isNotNilOrEmpty(avatar)) {
        payload.avatar = avatar.userDetails.filename
      }

      const response = await PilotWebService.updateIndividualPilot({
        ...payload,
        ...buildAddressPayload(),
      })

      await module.inspectPilot(id)
      return response
    },
    { errHandler: setError }
  )

const submitApplication = (module, { setError }) =>
  withTryCatch(
    async (id, payload) => {
      const buildAddressPayload = () => {
        if (payload.address) {
          return {
            addressLocation: payload.address.geometry,
            address: payload.address.place_name,
          }
        }

        return {}
      }
      const previousAvatar = payload.avatar
      const { avatar } = module.getState()

      if (isNilOrEmpty(previousAvatar)) {
        payload.avatar = undefined
      }

      if (isNotNilOrEmpty(avatar)) {
        payload.avatar = avatar.filename
      }

      const response = await PilotWebService.submitApplication({
        ...payload,
        ...buildAddressPayload(),
      })

      await module.inspectPilot(id)
      return response
    },
    { errHandler: setError }
  )

const updatePilot = (module, { setError }) =>
  withTryCatch(
    async (id, payload) => {
      const { userDetails, pilotDetails } = payload
      const { entities, avatar } = module.getState()
      const { userId } = path([id], entities)

      if (isNotNilOrEmpty(avatar)) {
        userDetails.avatar = avatar
      }

      pilotDetails.addressLocation = pilotDetails.address.geometry
      pilotDetails.address = pilotDetails.address.place_name

      pilotDetails.serviceAreas = [
        // `turf` generates a `Feature` but we just want the `Polygon`
        circle(pilotDetails.addressLocation, pilotDetails.serviceRadius, {
          units: 'kilometers',
        }).geometry,
      ]

      const [updatedUser, updatedPilot] = await Promise.all([
        UserService.update(userId, userDetails),
        PilotWebService.update(id, pilotDetails),
      ])

      const filteredUser = omit(['authId', 'createdAt', 'devices'], updatedUser)
      const filteredPilot = omit(['userDetails'], updatedPilot)

      filteredPilot.address = {
        geometry: filteredPilot.addressLocation,
        // eslint-disable-next-line camelcase
        place_name: filteredPilot.address,
      }

      module.setState({
        entities: { [id]: { userDetails: filteredUser, ...filteredPilot } },
      })

      module.inspectPilot(id)
    },
    { errHandler: setError }
  )

const uploadAvatar = (module, { setError }) =>
  withTryCatch(
    async (_, file, userId) => {
      const { filename } = await UploadService.uploadFileNonAdmin(
        file,
        `pilots/${userId}/avatar-upload`,
        { fileName: file.name, fileSize: file.size }
      )

      module.setState({ avatar: { file, filename } })
    },
    { errHandler: setError }
  )

const setAvatarFile = (module) => (_, file) => {
  module.setState({ avatar: { file, filename: undefined } })
}

const resetUploadAvatar = (module) => () => {
  module.setState({ avatar: undefined })
}

const downloadDocument = (_, { setError }) =>
  withTryCatch(
    async (documentId, pilotId) =>
      PilotWebService.downloadDocument(documentId, pilotId),
    { errHandler: setError }
  )

const reset = (module) => () => {
  module.setState(INITIAL_STATE, (_, incomingState) => incomingState)
}

const pilotWebModule = createModule({
  name: 'pilotWeb',
  initialState: INITIAL_STATE,
  decorators: {
    fetchPilots,
    inspectPilot,
    filterPilots,
    createPilot,
    updatePilot,
    updateIndividualPilot,
    submitApplication,
    uploadAvatar,
    resetUploadAvatar,
    downloadDocument,
    setDefaultCompany,
    setAvatarFile,
    reset,
  },
})

export { pilotWebModule }
