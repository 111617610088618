import { Switch, Route } from 'react-router-dom'

import { MyPilotDetails } from './MyPilotInfo'

import { NotFoundPage } from 'components/common/NotFoundPage'
import { styled, apply } from 'lib/styled'

const Container = styled.div(apply('flex-1 flex flex-row p-6'))

// eslint-disable-next-line react/display-name
function MyPilotDetailsManager() {
  return (
    <Container>
      <Switch>
        <Route
          exact
          path={['/', '/my-pilot-info', '/my-pilot-info/personal-details']}
          component={MyPilotDetails}
        />
        <Route component={NotFoundPage} />
      </Switch>
    </Container>
  )
}

export { MyPilotDetailsManager }
