import { prepend } from '@seedcloud/ramda-extra'
import { useState } from 'react'

import { useFilter } from './FilterBar'

import { SelectField } from 'components/common/SelectField'

const dateRangeOptions = prepend({ value: undefined, label: 'Select Filter' }, [
  { label: 'Scheduled at', value: 'scheduledAt' },
  { label: 'Started at', value: 'startedAt' },
  { label: 'Ended at', value: 'finishedAt' },
])

const FilterBy = () => {
  const { onSubmit } = useFilter()
  const [selectedFilter, setSelectedFilter] = useState(dateRangeOptions[0])

  const handleFilterByChange = (filterBy) => {
    setSelectedFilter(filterBy)
    onSubmit({ filterBy: filterBy.value })
  }

  return (
    <SelectField
      name="filterBy"
      type="select"
      optional
      emptyOption={{ value: undefined }}
      value={selectedFilter}
      onChange={handleFilterByChange}
      options={dateRangeOptions}
      selectStyles={{ valueContainer: { height: '46px' } }}
      size="lg"
      newStyle
    />
  )
}

export { FilterBy }
