import {
  isNotNil,
  prop,
  defaultTo,
  isNotNilOrEmpty,
  pick,
} from '@seedcloud/ramda-extra'
import moment from 'moment'
import { normalize, schema } from 'normalizr'

import { baseApi } from 'lib/http-client'

const getFormattedDateOrUndefined = (date) => {
  const result = isNotNilOrEmpty(date) ? moment.utc(date).format() : undefined

  return result
}

const companyEntity = new schema.Entity('company')
const pilotEntity = new schema.Entity('pilots')

const CompanyService = {
  async list({ query = '', type, limit = 1000, next }) {
    const config = {
      searchParams: {
        q: query,
        limit,
      },
    }
    if (isNotNil(next)) {
      config.searchParams.next = next
    }
    if (isNotNil(type)) {
      config.searchParams.type = type
    }

    const { items, paging } = await baseApi.get('companies', config).json()

    const normalized = normalize(items, [companyEntity])
    const entities = defaultTo({}, prop('company', normalized.entities))
    const order = defaultTo([], normalized.result)

    return {
      entities,
      order,
      next: paging.next,
    }
  },

  async listPilots({ id, query = '', limit = 1000, next }) {
    const config = {
      searchParams: {
        q: query,
        limit,
      },
    }
    if (isNotNil(next)) {
      config.searchParams.next = next
    }

    const { items, paging } = await baseApi.get(`companies/${id}/pilots`, config).json()

    const normalized = normalize(items, [pilotEntity])
    const entities = defaultTo({}, prop('pilots', normalized.entities))
    const order = defaultTo([], normalized.result)

    return {
      entities,
      order,
      next: paging.next,
    }
  },

  async invitePilot({ companyId, userDetails }) {
    const config = {
      json: pick(
        [
          'avatar',
          'email',
          'firstName',
          'lastName',
          'phoneNumber',
          'pilotCategoryId',
          'companyName',
          'terms',
        ],
        userDetails
      ),
    }

    return baseApi.post(`companies/${companyId}/pilots`, config).json()
  },

  async inviteMyselfAsPilot(values = {}) {
    const config = {
      json: pick(
        [
          'pilotCategoryId',
          'bankAccount',
          'droneType',
          'address',
          'addressLocation',
          'licenceType',
          'licenceNumber',
          'licenceExpiredAt',
          'insuranceNumber',
          'insuranceExpiredAt',
          'serviceCategories',
          'serviceAreas',
          'serviceRadius',
          'termsAndConditionsAccepted',
          'arn',
          'equipments',
        ],
        values
      ),
    }

    return baseApi.post('admin/pilots/invite-self', config).json()
  },

  async create(body) {
    const config = {
      json: body,
    }

    return baseApi.post('companies', config).json()
  },

  async read(id) {
    return baseApi.get(`companies/${id}`).json()
  },

  async update(id, { insuranceExpiredAt, ...otherData }) {
    const config = {
      json: {
        insuranceExpiredAt: getFormattedDateOrUndefined(insuranceExpiredAt),
        ...otherData,
      },
    }

    return baseApi.patch(`companies/${id}`, config).json()
  },

  async add(id) {
    return baseApi.post(`companies/${id}/add`)
  },

  async reinvite(id) {
    return baseApi.post(`companies/${id}/reinvite`)
  },
}

export { CompanyService }
