import { pathOr } from '@seedcloud/ramda-extra'
import { useMemo } from 'react'
import { useHistory } from 'react-router-dom'

import { FilterInput } from './FilterInput'
import { PilotListItem } from './PilotListItem'
import { TypeCompanySelectField } from './TypeCompanySelectField'

import { ReactComponent as PlusIcon } from 'assets/feathers/plus.svg'
import { Button } from 'components/common/Button'
import { Loader } from 'components/common/Loader'
import { Spinner } from 'components/common/Spinner'
import { useIdentity } from 'lib/solta-id-react'
import { styled, apply } from 'lib/styled'
import { nullToString } from 'utils/stringUtils'

const Container = styled.div(
  {
    '--sidebar-width': '384px',
  },
  apply('flex flex-column min-w-10 max-w-24 mr-6 bg-white rounded-lg shadow-sm', {
    width: 'var(--sidebar-width)',
    minWidth: 'var(--sidebar-width)',
    maxWidth: 'var(--sidebar-width)',
    overflow: 'hidden',
  })
)

const InputContainer = styled.div(
  apply('p-4 border-0 border-b-1 border-solid border-grey-lighter')
)

const FilterCompanyButton = styled.div(apply('mt-4'))

const List = styled.ol(
  apply('p-2 m-0', {
    overflow: 'auto',
    listStyleType: 'none',
  })
)

const PrimaryButton = styled(Button)(
  apply('w-full mb-4 flex items-center rounded-lg justify-center', {
    gap: '.5rem',
  })
)

function PilotList({
  isLoadingPilots,
  pilotList,
  search,
  setSearch,
  orgId,
  onFilterChange,
  companyList,
  selectedFilter,
  isAdmin,
}) {
  const history = useHistory()
  const { account, isPilot } = useIdentity()
  const isSupplier = useMemo(() => account?.roles.contactType === 'supplier_admin')

  return (
    <Container>
      <InputContainer>
        <PrimaryButton
          disabled={isLoadingPilots}
          onClick={() => history.push(`/pilot/${orgId}/invite-pilot`)}
          data-test-id="invite-pilot-btn"
        >
          <PlusIcon /> ADD PILOT
        </PrimaryButton>
        {!isPilot && isSupplier ? (
          <PrimaryButton
            disabled={isLoadingPilots}
            onClick={() =>
              history.push(`/pilot/${orgId}/invite-pilot?invite-myself=true`)
            }
            data-test-id="invite-pilot-btn"
          >
            <PlusIcon /> ADD MYSELF AS A PILOT
          </PrimaryButton>
        ) : null}

        <FilterInput value={search} onChange={setSearch} />

        {isAdmin ? (
          <FilterCompanyButton>
            <TypeCompanySelectField
              selected={selectedFilter}
              companyList={companyList}
              onChange={onFilterChange}
              style={apply('mt-4')}
            />
          </FilterCompanyButton>
        ) : null}
      </InputContainer>

      <Loader
        loading={isLoadingPilots}
        renderPlaceholder={<Spinner size={32} thickness={6} style={apply('mt-4')} />}
      >
        <List>
          {pilotList.map(
            ({
              id,
              activatedAt,
              isBanned,
              isDeleted,
              userDetails,
              companyName,
              pilotCategory,
            }) => {
              const { firstName, lastName, phoneNumber, avatar } = userDetails
              const category = pathOr('No Category', ['description'], pilotCategory)

              return (
                <PilotListItem
                  key={id}
                  id={id}
                  avatarSrc={avatar}
                  fullName={`${firstName} ${nullToString(lastName)}`}
                  phoneNumber={phoneNumber}
                  company={companyName}
                  category={category}
                  isBanned={isBanned}
                  isDeleted={isDeleted}
                  activatedAt={activatedAt}
                  href={`/pilot/${id}`}
                />
              )
            }
          )}
        </List>
      </Loader>
    </Container>
  )
}

export { PilotList }
