/* eslint-disable complexity */
import { connect } from '@seedcloud/stateless'
import { useLayoutEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { FilterBar } from '../common/FilterBar'

import { Columns } from './Columns'
import { JobTable } from './JobTable'
import { Rows } from './Rows'

import { JOB_STATUSES } from 'constants/jobs'
import { useIdentity } from 'lib/solta-id-react'
import {
  jobModule,
  jobList$,
  selectedTab$,
  isLoadingJobs$,
  jobFilters$,
} from 'modules/job'
import { createJobtabs } from 'utils/createJobTabs'

const {
  fetchJobs,
  filterEngagedJobs,
  filterAvailableJobs,
  setSelectedTab,
  fetchEngagedJobs,
  resetPagination,
} = jobModule

const FilterBarWeb = ({ filterJobsFn, selectedTab }) => (
  <FilterBar filterJobsFn={filterJobsFn} selectedTab={selectedTab}>
    <FilterBar.Search placeholder="Search by Reference No." />
    <FilterBar.FilterBy />
    <FilterBar.DateRange />
    <FilterBar.SearchAction />
  </FilterBar>
)

const ConnectedJobTable = connect(() => ({
  jobList: jobList$,
  jobFilters: jobFilters$,
  selectedTab: selectedTab$,
  isLoadingJobs: isLoadingJobs$,
}))(JobTableContainer)

function JobTableContainer({ jobList, selectedTab, jobFilters, isLoadingJobs }) {
  const { role } = useIdentity()
  const history = useHistory()

  useLayoutEffect(() => {
    const sortOrder = 'desc'
    switch (selectedTab) {
      case 'published':
        filterAvailableJobs(null, {
          statuses: [JOB_STATUSES.PUBLISHED],
          sortBy: 'publishedAt',
          sortOrder,
        })
        break
      case 'accepted':
        filterAvailableJobs(null, {
          statuses: [JOB_STATUSES.ACCEPTED],
          sortBy: 'acceptedAt',
          sortOrder,
        })
        break
      case 'engaged':
        filterEngagedJobs(null, {
          status: JOB_STATUSES.ENGAGED,
          sortBy: 'engagedAt',
          sortOrder,
        })
        break
      case 'delivered':
        filterEngagedJobs(null, {
          status: JOB_STATUSES.DELIVERED,
          sortBy: 'engagedAt',
          sortOrder,
        })
        break
      case 'in_review':
        filterEngagedJobs(null, {
          status: JOB_STATUSES.IN_REVIEW,
          sortBy: 'engagedAt',
          sortOrder,
        })
        break
      case 'completed':
        filterEngagedJobs(null, {
          status: JOB_STATUSES.COMPLETED,
          sortBy: 'engagedAt',
          sortOrder,
        })
        break
      case 'cancelled':
        filterEngagedJobs(null, {
          status: JOB_STATUSES.CANCELLED,
          sortBy: 'engagedAt',
          sortOrder,
        })
        break
      case 'disputed':
        filterEngagedJobs(null, {
          status: JOB_STATUSES.DISPUTED,
          sortBy: 'engagedAt',
          sortOrder,
        })
        break
      default:
        filterAvailableJobs(null, {
          statuses: [JOB_STATUSES.PUBLISHED],
          sortBy: 'publishedAt',
          sortOrder,
        })
        break
    }
  }, [])

  // const excludedTabs = ['new', 'queue']
  const excludedTabs =
    role === 'customer'
      ? [
          'new',
          'queue',
          'published',
          'accepted',
          'engaged',
          'delivered',
          'in_review',
          'cancelled',
          'disputed',
        ]
      : ['new', 'queue', 'cancelled']

  const tabs = createJobtabs({
    setSelectedTab,
    filterJobs: (...args) => {
      resetPagination()
      filterEngagedJobs(...args)
    },
    filterAvailable: (...args) => {
      resetPagination()
      filterAvailableJobs(...args)
    },
    exclude: excludedTabs,
  })

  const columns = Columns(selectedTab, role)

  const data = Rows(jobList)

  const onSort = (sortBy, sortOrder) => filterAvailableJobs(null, { sortBy, sortOrder })

  const onTurnPage = ({ turnNext }) => {
    if (selectedTab === 'accepted') {
      fetchJobs(null, { fetchAvailable: true, turnPage: true, turnNext })
    } else {
      fetchEngagedJobs(null, {
        turnPage: true,
        turnNext,
      })
    }
  }

  const onRowClicked = (jobId) => history.push(`/job/${jobId}`)

  const jobTableProps = {
    selectedTab,
    jobFilters,
    isLoadingJobs,
    setSelectedTab,
    filterJobs: filterAvailableJobs,
    columns,
    data,
    onRowClicked,
    onSort,
    onTurnPage,
    tabs,
    filterBar: (
      <FilterBarWeb
        filterJobsFn={
          selectedTab === 'accepted' ? filterAvailableJobs : filterEngagedJobs
        }
        selectedTab={selectedTab}
      />
    ),
    isAdmin: false,
  }

  return <JobTable {...jobTableProps} />
}

export { ConnectedJobTable }
