/* eslint-disable complexity */
import {
  prop,
  defaultTo,
  isNotNilOrEmpty,
  omitBy,
  isNilOrEmpty,
  pick,
} from '@seedcloud/ramda-extra'
import { format } from 'date-fns'
import moment from 'moment'
import { normalize, schema } from 'normalizr'

import { adminApi, baseApi } from 'lib/http-client'
import {
  addDateParams,
  addNumberParams,
  addStringParams,
  composeSearchParams,
} from 'utils/searchParams'
import { stringifyParams } from 'utils/stringifyParams'

const getFormattedDateOrUndefined = (date) => {
  const result = isNotNilOrEmpty(date) ? moment.utc(date).format() : undefined

  return result
}

const omitNilOrEmpty = (payload) => omitBy(isNilOrEmpty, payload)

const jobEntity = new schema.Entity('jobs')

const JobService = {
  async list({
    query = '',
    statuses,
    limit = 10,
    next,
    sortBy,
    filterBy,
    sortOrder,
    from,
    to,
    project,
    publishType,
  }) {
    const config = {
      searchParams: {
        q: query,
        limit,
        sortBy,
        filterBy,
        sortOrder,
        statuses,
      },
    }

    composeSearchParams(
      addStringParams({ project }),
      addDateParams({ from }),
      addDateParams({ to }),
      addNumberParams({ next }),
      addStringParams({ publishType })
    )(config)

    const { items, paging } = await baseApi
      .get(`organization-jobs?${stringifyParams(config.searchParams)}`)
      .json()
    const normalized = normalize(items, [jobEntity])
    const entities = defaultTo({}, prop('jobs', normalized.entities))
    const order = defaultTo([], normalized.result)

    return {
      entities,
      order,
      next: paging.next,
      sortBy: paging.sortBy,
      filterBy: paging.filterBy,
      sortOrder: paging.sortOrder,
    }
  },
  async listAvailable({ limit = 10, next, from, to, query = '', statuses = '' }) {
    const config = {
      searchParams: {
        q: query,
        status: statuses,
        limit,
      },
    }

    composeSearchParams(
      addNumberParams({ next }),
      addDateParams({ to }),
      addDateParams({ from }),
      addStringParams({ q: query })
    )(config)

    const { items, paging } = await baseApi.get('available-jobs', config).json()
    const normalized = normalize(items, [jobEntity])
    const entities = defaultTo({}, prop('jobs', normalized.entities))
    const order = defaultTo([], normalized.result)

    return {
      entities,
      order,
      next: paging.next,
      sortBy: paging.sortBy,
      filterBy: paging.filterBy,
      sortOrder: paging.sortOrder,
    }
  },

  async listEngaged({ status, limit = 10, next, to, from, query = '' }) {
    const config = {
      searchParams: {
        status,
        limit,
        q: query,
      },
    }

    composeSearchParams(
      addNumberParams({ next }),
      addDateParams({ to }),
      addDateParams({ from }),
      addStringParams({ q: query })
    )(config)

    const { items, paging } = await baseApi.get('engaged-jobs', config).json()
    const normalized = normalize(items, [jobEntity])
    const entities = defaultTo({}, prop('jobs', normalized.entities))
    const order = defaultTo([], normalized.result)

    return {
      entities,
      order,
      next: paging.next,
      sortBy: paging.sortBy,
      filterBy: paging.filterBy,
      sortOrder: paging.sortOrder,
    }
  },
  async read(id) {
    return baseApi.get(`organization-jobs/${id}`).json()
  },

  async readAvailable(id) {
    return baseApi.get(`available-jobs/${id}`).json()
  },

  async readEngaged(id) {
    return baseApi.get(`engaged-jobs/${id}`).json()
  },

  async updateEngaged(id, payload) {
    const config = {
      json: omitNilOrEmpty(payload),
    }

    return baseApi.patch(`engaged-jobs/${id}/status`, config).json()
  },

  async publish(
    id,
    {
      scheduledAt,
      finishedAt,
      engagedAt,
      startedAt,
      location,
      pilotId,
      clientId,
      ...otherData
    }
  ) {
    const payload = {
      scheduledAt: getFormattedDateOrUndefined(scheduledAt),
      finishedAt: getFormattedDateOrUndefined(finishedAt),
      engagedAt: getFormattedDateOrUndefined(engagedAt),
      startedAt: getFormattedDateOrUndefined(startedAt),
      location: prop('geometry')(location),
      address: prop('place_name')(location),
      engagedBy: pilotId,
      orderedBy: clientId,
      ...otherData,
    }

    const config = {
      json: omitNilOrEmpty(payload),
    }

    return baseApi.patch(`organization-jobs/${id}/publish`, config).json()
  },

  async calculateTimesheet(
    { jobId, selectedEquipmentIds = [], calloutFee, startedAt, finishedAt },
    options = {}
  ) {
    const payload = {
      finishedAt: getFormattedDateOrUndefined(finishedAt),
      startedAt: getFormattedDateOrUndefined(startedAt),
      jobId,
      selectedEquipmentIds: selectedEquipmentIds.filter((it) => !!it),
      calloutFee,
    }

    const config = {
      json: omitNilOrEmpty(payload),
      ...options,
    }

    return adminApi.post(`organization-jobs/calculate-timesheet`, config).json()
  },

  async update(
    id,
    {
      scheduledAt,
      finishedAt,
      engagedAt,
      startedAt,
      location,
      pilotId,
      clientId,
      selectedPilotId,
      selectedSupplierId,
      ...otherData
    },
    updateSelectedFields
  ) {
    const payload = pick(
      [
        'description',
        'instructions',
        'radius',
        'location',
        'address',
        'productId',
        'status',
        'finishedAt',
        'scheduledAt',
        'startedAt',
        'engagedAt',
        'engagedBy',
        'orderedBy',
        'projectId',
        'acceptTermsConditions',
        'commercialSettings',
        'companyId',
        'internalProjectId',
        'pricing',
        'publishType',
        'selectedEquipmentIds',
        'calloutFee',
        'marketFee',
        'ratings',
        'feedback',
        'selectedPilotId',
        'selectedPilotCategoryId',
        'template',
        'selectedSupplierId',
        'selectedAllPilot',
        'selectedAllSupplier',
        'selectedCustomerId',
        'disputeReason',
      ],
      {
        scheduledAt: getFormattedDateOrUndefined(scheduledAt),
        finishedAt: getFormattedDateOrUndefined(finishedAt),
        engagedAt: getFormattedDateOrUndefined(engagedAt),
        startedAt: getFormattedDateOrUndefined(startedAt),
        location: prop('geometry')(location),
        address: prop('place_name')(location),
        engagedBy: pilotId,
        orderedBy: clientId,
        selectedPilotId,
        selectedSupplierId,
        ...otherData,
        selectedEquipmentIds: (otherData.selectedEquipments || []).map((it) => it.id),
        pricing: otherData.pricing
          ? {
              quote: otherData.pricing.quote,
              timeMaterial: otherData.pricing.timeMaterial,
              budget: otherData.pricing.budget,
            }
          : undefined,
      }
    )

    const config = {
      json: omitNilOrEmpty(payload),
    }

    if (updateSelectedFields) {
      if (!selectedPilotId) {
        config.json.selectedPilotId = null
      }

      if (!selectedSupplierId) {
        config.json.selectedSupplierId = null
      }
    }

    // perlu diganti ke baseApi, tapi perlu ada adjustment dari BE agar supplier bisa update job
    return adminApi.patch(`organization-jobs/${id}`, config).json()
  },

  async engage(id) {
    return baseApi.put(`available-jobs/${id}`)
  },

  async cancel(id) {
    return baseApi.delete(`organization-jobs/${id}`)
  },

  finalize(id) {
    return adminApi.patch(`organization-jobs/${id}/finalize`)
  },

  sendUploadReminder(id) {
    return adminApi.post(`organization-jobs/${id}/upload-reminder/send`)
  },

  accept(id) {
    return baseApi.patch(`organization-jobs/${id}/accept`).json()
  },

  reject(id, rejectionReason) {
    const config = {
      json: { rejectionReason },
    }
    return baseApi.patch(`organization-jobs/${id}/reject`, config).json()
  },

  duplicate(id) {
    const payload = {
      jobId: id,
    }
    const config = {
      json: omitNilOrEmpty(payload),
    }
    return adminApi.post(`organization-jobs/duplicate`, config).json()
  },

  create({
    productId,
    clientId,
    companyId,
    address,
    commercialSettings,
    template,
    scheduledAt,
    projectId,
    radius,
    customer,
    selectedCustomerId,
  }) {
    const payload = {
      title: prop('place_name')(address),
      productId,
      companyId,
      commercialSettings,
      template,
      scheduledAt: format(scheduledAt, 'yyyy-MM-dd HH:mm'),
      location: prop('geometry')(address),
      address: prop('place_name')(address),
      orderedBy: clientId,
      projectId,
      radius,
      customer,
      selectedCustomerId,
    }

    const config = {
      json: payload,
    }

    return baseApi.post(`organization-jobs`, config).json()
  },
}

export { JobService }
