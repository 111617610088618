import { Formik as FormProvider, Form } from 'formik'

import { FieldGroup, Row, TextField, SelectField } from 'components/company/common'
import { styled, apply } from 'lib/styled'
import { formatCurrency } from 'utils/formatCurrency'
import { formatPilotCategories } from 'utils/formatPilotCategories'

const Container = styled.section(apply('m-10'))
const Details = styled(Form)(apply('flex-1 flex flex-row'))
const Column = styled.div(apply('flex-1 flex flex-column'))

function PersonalDetails({
  firstName,
  lastName,
  companyName,
  phoneNumber,
  email,
  pilotCategoriesList,
  pilotCategory,
}) {
  const pilotCategoryOptions = pilotCategoriesList.map(({ id, description, rate }) => ({
    id,
    value: id,
    label: `${formatPilotCategories(description)} ${formatCurrency(rate)} / hr`,
  }))
  const selectedPilotCategory = pilotCategoryOptions.find(
    (data) => data.id === pilotCategory
  )
  const labelCategory =
    selectedPilotCategory !== undefined ? selectedPilotCategory.label : ''

  return (
    <Container>
      <FieldGroup title={'Personal Details'}>
        <FormProvider
          enableReinitialize
          initialValues={{
            firstName,
            lastName,
            companyName,
            phoneNumber,
            email,
          }}
        >
          <Details>
            <Column>
              <Row>
                <TextField
                  disabled
                  name="firstName"
                  type="text"
                  label="First name"
                  value={firstName}
                />

                <TextField
                  disabled
                  name="lastName"
                  type="text"
                  label="Last name"
                  value={lastName}
                />
              </Row>

              <Row>
                <TextField
                  disabled
                  name="phoneNumber"
                  type="text"
                  label="Phone Number"
                  value={phoneNumber}
                />
                <TextField
                  disabled
                  name="email"
                  type="text"
                  label="E-mail"
                  value={email}
                />
              </Row>

              <Row>
                <TextField
                  disabled
                  name="companyName"
                  type="text"
                  label="Company name (optional)"
                  containerProps={{ style: apply('mr-3') }}
                  value={companyName}
                />
                <SelectField
                  label="Pilot Category"
                  value={formatPilotCategories(labelCategory)}
                  name="pilotCategory"
                  options={pilotCategoryOptions}
                  emptyOption={{ value: undefined, label: '' }}
                  isSearchable={false}
                  required
                />
              </Row>
            </Column>
          </Details>
        </FormProvider>
      </FieldGroup>
    </Container>
  )
}

export { PersonalDetails }
