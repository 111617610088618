/* eslint-disable complexity */
import { connect } from '@seedcloud/stateless'
import { useLayoutEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { FilterBar } from '../common/FilterBar'

import { Columns } from './Columns'
import { JobTable } from './JobTable'
import { Rows } from './Rows'

import { JOB_STATUSES } from 'constants/jobs'
import { useIdentity } from 'lib/solta-id-react'
import { createCompanyModule } from 'modules/company'
import {
  jobModule,
  jobList$,
  selectedTab$,
  isLoadingJobs$,
  jobFilters$,
} from 'modules/job'
import { createJobtabs } from 'utils/createJobTabs'

const {
  module: companyModule,
  selectors: { isNBS$ },
} = createCompanyModule()

const { fetchJobs, filterJobs, setSelectedTab, resetPagination } = jobModule

const FilterBarAdmin = ({ filterJobsFn, selectedTab }) => (
  <FilterBar filterJobsFn={filterJobsFn} selectedTab={selectedTab}>
    <FilterBar.Search />
    <FilterBar.PublishType />
    <FilterBar.FilterBy />
    <FilterBar.DateRange />
    <FilterBar.SearchAction />
    <FilterBar.CreateJob />
  </FilterBar>
)

const ConnectedJobTable = connect(() => ({
  jobList: jobList$,
  jobFilters: jobFilters$,
  selectedTab: selectedTab$,
  isLoadingJobs: isLoadingJobs$,
  isNBS: isNBS$,
}))(JobTableContainer)

function JobTableContainer({ jobList, selectedTab, jobFilters, isLoadingJobs, isNBS }) {
  const { role } = useIdentity()
  const history = useHistory()

  useLayoutEffect(() => {
    companyModule.inspectCompany()
  }, [])

  useLayoutEffect(() => {
    const statuses = []
    let sortBy = 'createdAt'
    switch (selectedTab) {
      case 'new':
        statuses.push(JOB_STATUSES.NEW)
        sortBy = 'createdAt'
        break
      case 'queue':
        statuses.push(JOB_STATUSES.QUEUE)
        sortBy = 'createdAt'
        break
      case 'published':
        statuses.push(JOB_STATUSES.PUBLISHED)
        sortBy = 'publishedAt'
        break
      case 'accepted':
        statuses.push(JOB_STATUSES.ACCEPTED)
        sortBy = 'acceptedAt'
        break
      case 'engaged':
        statuses.push(JOB_STATUSES.ENGAGED)
        sortBy = 'engagedAt'
        break
      case 'delivered':
        statuses.push(JOB_STATUSES.DELIVERED)
        sortBy = 'engagedAt'
        break
      case 'in_review':
        statuses.push(JOB_STATUSES.IN_REVIEW)
        sortBy = 'engagedAt'
        break
      case 'completed':
        statuses.push(JOB_STATUSES.COMPLETED)
        sortBy = 'engagedAt'
        break
      case 'cancelled':
        statuses.push(JOB_STATUSES.CANCELLED)
        sortBy = 'cancelledAt'
        break
      case 'disputed':
        statuses.push(JOB_STATUSES.DISPUTED)
        sortBy = 'engagedAt'
        break
      default:
        statuses.push(JOB_STATUSES.NEW)
        break
    }

    if (selectedTab === JOB_STATUSES.IN_REVIEW && isNBS) {
      statuses.push(JOB_STATUSES.IN_REVIEW_NETWORK)
    }

    filterJobs(null, {
      statuses,
      sortBy,
      sortOrder: 'desc',
    })
  }, [isNBS])

  const tabs = createJobtabs({
    setSelectedTab,
    filterJobs: (...args) => {
      resetPagination()
      filterJobs(...args)
    },
  })

  const columns = Columns(selectedTab, role)

  const data = Rows(jobList)

  const onSort = (sortBy, sortOrder) => filterJobs(null, { sortBy, sortOrder })

  const onTurnPage = ({ turnNext }) => fetchJobs(null, { turnPage: true, turnNext })

  const onRowClicked = (jobId) => history.push(`/job/${jobId}`)

  const jobTableProps = {
    selectedTab,
    jobFilters,
    isLoadingJobs,
    setSelectedTab,
    filterJobs,
    columns,
    data,
    onRowClicked,
    onSort,
    onTurnPage,
    tabs,
    filterBar: <FilterBarAdmin filterJobsFn={filterJobs} selectedTab={selectedTab} />,
    isAdmin: true,
  }

  return <JobTable {...jobTableProps} />
}

export { ConnectedJobTable }
