import { path, omit, isNotNilOrEmpty } from '@seedcloud/ramda-extra'
import { createModule } from '@seedcloud/stateless'
import ms from 'ms'
import { toast } from 'react-toastify'

import { CompanyService } from './service'

import { createPilotModule } from 'modules/pilot'
import { withTryCatch } from 'utils/withTryCatch'

const { module: pilotModule, service: PilotService } = createPilotModule()

const INITIAL_STATE = Object.freeze({
  entities: {},
  customerEntities: {},
  nbsEntities: {},
  pilotEntities: {},
  inspectedEntity: undefined,
  filterQuery: '',
  filterType: undefined,
  order: [],
  pilotOrder: [],
  customerOrder: [],
  nbsOrder: [],
  paging: {
    next: undefined,
  },
  nbsPaging: {
    next: undefined,
  },
  pilotPaging: {
    next: undefined,
  },
  canResendInviteAt: undefined,
})

const fetchCompanies = (module, { setError }) =>
  withTryCatch(
    async (_, { turnPage = false } = {}) => {
      const { filterQuery, filterType: type, paging } = module.getState()
      const next = turnPage ? paging.next : undefined

      const {
        entities,
        order,
        next: newNext,
      } = await CompanyService.list({
        query: filterQuery,
        type,
        next,
      })

      // console.log(entities, order)
      module.setState({
        entities,
        order,
        paging: {
          next: newNext,
        },
      })
    },
    { errHandler: setError }
  )

const fetchCustomers = (module, { setError }) =>
  withTryCatch(
    async (_, { turnPage = false } = {}) => {
      const { filterQuery, filterType: type, paging } = module.getState()
      const next = turnPage ? paging.next : undefined

      const { entities, order } = await CompanyService.customerList({
        query: filterQuery,
        type,
        next,
      })
      // console.log(entities, order)

      module.setState({
        customerEntities: entities,
        customerOrder: order,
      })
    },
    { errHandler: setError }
  )

const fetchNBSCompanies = (module, { setError }) =>
  withTryCatch(
    async () => {
      const {
        entities,
        order,
        next: newNext,
      } = await CompanyService.list({
        isNBS: true,
      })

      module.setState({
        nbsEntities: entities,
        nbsOrder: order,
        nbsPaging: {
          next: newNext,
        },
      })
    },
    { errHandler: setError }
  )

const fetchCompanyPilots = (module, { setError }) =>
  withTryCatch(
    async (id, { turnPage = false } = {}) => {
      const { filterQuery, paging } = module.getState()
      const next = turnPage ? paging.next : undefined

      const {
        entities,
        order,
        next: newNext,
      } = await CompanyService.listPilots({
        id,
        query: filterQuery,
        next,
      })

      module.setState({
        pilotEntities: entities,
        pilotOrder: order,
        pilotPaging: {
          next: newNext,
        },
      })
    },
    { errHandler: setError }
  )

const createCustomer = ({ setError }) =>
  withTryCatch(
    async (_, { organizationDetails, userDetails }) => {
      // TODO: UNCOMMENT THIS IF WHEN USING COORDINATE LOCATION
      // const address = path(['location', 'place_name'], companyDetails)

      // const updatedCompanyDetails = omit(['address', 'location'], companyDetails)

      const company = await CompanyService.create({
        organizationDetails: {
          name: organizationDetails?.name ? organizationDetails?.name : '',
          address: organizationDetails?.address ? organizationDetails?.address : '',
          abn: organizationDetails?.abn ? organizationDetails?.abn : '',
        },
        userDetails,
      })

      return company
    },
    { errHandler: setError }
  )

const addCompany = (module, { setError }) =>
  withTryCatch(
    async (id) => {
      await CompanyService.add(id)

      fetchCustomers(module, { setError })(null)
    },
    { errHandler: setError }
  )

const updateCustomer = (module, { setError }) =>
  withTryCatch(
    async (id, payload) => {
      const updatedKeyContacts = omit(['email'], payload.user)
      const userId = path(['keyContact', 'id'], payload)

      const customerUpdateProps = {
        name: payload?.name ? payload?.name : '',
        address: payload?.address ? payload?.address : '',
        abn: payload?.abn ? payload?.abn : '',
        user: {
          ...updatedKeyContacts,
        },
        userId,
      }

      const updatedCustomer = await CompanyService.update(id, customerUpdateProps)

      module.setState({ entities: { [id]: updatedCustomer } })

      module.inspectCustomer(id)
      return updatedCustomer
    },
    { errHandler: setError }
  )

const filterCompanies = (module) => (_, filter) => {
  module.setState({
    ...filter,
  })

  module.fetchCustomers(null, { turnPage: false })
}

const resetCompanyFilter = (module) => () => {
  module.setState({
    filterQuery: '',
    filterType: undefined,
    paging: { next: undefined },
  })
}

const inspectCustomer = (module, { setError }) =>
  withTryCatch(
    async (id) => {
      module.setState({
        inspectedEntity: id,
      })

      const entity = await CompanyService.read(id)

      module.setState({
        entities: { [id]: entity },
      })

      return entity
    },
    { errHandler: setError }
  )

const resendInvite = (module, { setError }) =>
  withTryCatch(
    async (id) => {
      const { canResendInviteAt } = module.getState()

      if (Date.now() < canResendInviteAt) {
        throw new Error('Wait before resending invite')
      }

      await CompanyService.reinvite(id)

      // Due to `ky` throwing an error if the `reinvite` endpoint fails
      // this means this line only executes when `reinvite` succeeds
      // meaning that users only get a delay if it succeeds otherwise
      // they can retry again immediately, which is what we want :smile:
      module.setState({ canResendInviteAt: Date.now() + ms('30s') })
    },
    { errHandler: setError }
  )

const inviteCompanyPilot = (module, { setError }) =>
  withTryCatch(
    async (_, { companyId, userDetails }) => {
      const { avatar } = pilotModule.getState()

      const payload = { ...userDetails, avatar: undefined }

      const pilot = await CompanyService.invitePilot({
        companyId,
        userDetails: payload,
      })

      if (isNotNilOrEmpty(avatar)) {
        const { userId, pilotCategory } = pilot

        await pilotModule.uploadAvatar(null, avatar.file, userId)

        const {
          avatar: { filename },
        } = pilotModule.getState()

        await PilotService.updatePilotById(pilot._id, {
          userDetails: {
            pilotCategory,
            avatar: filename,
          },
        })
      }

      toast.success('Pilot invited')

      return pilot
    },
    { errHandler: setError }
  )

const companyAdminModule = createModule({
  name: 'legacy-company',
  initialState: INITIAL_STATE,
  decorators: {
    fetchCustomers,
    fetchCompanies,
    fetchNBSCompanies,
    filterCompanies,
    inspectCustomer,
    fetchCompanyPilots,
    addCompany,
    createCustomer,
    updateCustomer,
    resetCompanyFilter,
    resendInvite,
    inviteCompanyPilot,
  },
})

export { companyAdminModule }
