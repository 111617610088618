/* eslint-disable complexity */
import { AbilityBuilder, Ability } from '@casl/ability'

import { PILOT_APPLICATION_STATUS } from 'constants/pilots'
import { RESOURCES } from 'constants/resources'
import { ROLES, INVITATION_STATUS, HUB_TYPE } from 'constants/roles'

const {
  PILOT_APPLICATION,
  PILOT_DETAILS,
  JOB,
  PRODUCT,
  FILE,
  STAFF,
  CUSTOMER,
  MY_COMPANY,
  SUPPLIER,
  CLAIM_COMPANY,
  MY_PILOT_INFO,
  MY_PROFILE,
  SUBSCRIPTION_PLANS,
} = RESOURCES

// 'manage' equals 'CRUD'
export function defineAbilityFor(
  role,
  invitationStatus,
  hubType,
  pilotpilotApplicationStatus
) {
  const { can, cannot, build } = new AbilityBuilder(Ability)
  switch (role) {
    case ROLES.PLATFORM_ADMIN:
      switch (hubType) {
        case HUB_TYPE.NETWORK:
          can(['read', 'update', 'delete'], PILOT_APPLICATION)
          can(['read', 'update', 'delete'], PILOT_DETAILS)
          can('manage', MY_COMPANY)
          can('manage', JOB)
          can('manage', PRODUCT)
          can('manage', FILE)
          can('manage', STAFF)
          can('manage', SUPPLIER)
          can('manage', CUSTOMER)
          break
        case HUB_TYPE.SUPPLIER:
          can(['read', 'update', 'delete'], PILOT_DETAILS)
          can('manage', MY_COMPANY)
          can(['read', 'update', 'delete'], JOB)
          can('manage', FILE)
          // can('manage', STAFF)
          break
        default:
          break
      }
      break
    // Non Super-Admin Abilities
    case ROLES.SUPPLIER_ADMIN:
      switch (hubType) {
        case HUB_TYPE.NETWORK:
          can(['read', 'update', 'delete'], PILOT_APPLICATION)
          can(['read', 'update', 'delete'], PILOT_DETAILS)
          can('manage', MY_COMPANY)
          can('manage', JOB)
          can('manage', PRODUCT)
          can('manage', FILE)
          can('manage', STAFF)
          can('manage', SUPPLIER)
          can('manage', CUSTOMER)
          can('manage', SUBSCRIPTION_PLANS)
          break
        case HUB_TYPE.SUPPLIER:
          switch (invitationStatus) {
            case INVITATION_STATUS.ACTIVE:
              can(['read', 'update', 'delete'], PILOT_APPLICATION)
              can(['read', 'update', 'delete'], PILOT_DETAILS)
              can('manage', MY_COMPANY)
              can(['read', 'update', 'delete'], JOB)
              can('manage', FILE)
              // can('manage', STAFF)
              can('manage', SUBSCRIPTION_PLANS)
              break
            default:
              can('manage', FILE)
              can('manage', MY_COMPANY)
              can('manage', CLAIM_COMPANY)
              can('manage', SUBSCRIPTION_PLANS)
              break
          }
          break
        default:
          break
      }
      break
    case ROLES.USER:
      switch (pilotpilotApplicationStatus) {
        case PILOT_APPLICATION_STATUS.ACCEPTED:
          can(['read'], JOB)
          can('manage', MY_PILOT_INFO)
          can(['read'], MY_COMPANY)
          break
        default:
          can('manage', MY_PILOT_INFO)
          can(['read'], MY_COMPANY)
      }
      break
    case ROLES.CUSTOMER:
      can(['read'], JOB)
      can('manage', MY_PROFILE)
      break
    default:
      cannot('manage', 'all')
  }

  return build()
}
