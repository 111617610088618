/* eslint-disable complexity */
import { isNotNilOrEmpty } from '@seedcloud/ramda-extra'
import { connect } from '@seedcloud/stateless'
import { Formik as FormProvider } from 'formik'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { FieldGroup, FormAction, FormLayoutProvider } from 'components/company/common'
import { BillingContact } from 'components/supplier/common/Fields/BllingContact'
import { CommercialContact } from 'components/supplier/common/Fields/CommercialContact'
import { Details } from 'components/supplier/common/Fields/Details'
import { HeroPilotContact } from 'components/supplier/common/Fields/HeroPilotContact'
import { SupplierLayout } from 'components/supplier/common/SupplierLayout'
import { ResendInvite } from 'components/supplier/SupplierDetail/ResendInvite'
import { SUPPLIER_LABELS, SUPPLIER_TYPES } from 'constants/supplier'
import {
  isInspectingSupplier$,
  supplierDetail$,
  supplierModule,
  invitationStatus$,
} from 'modules/supplier'

const SupplierDetail = ({ supplierDetail = {}, isInspectingSupplier, status }) => {
  const [editing, setEditing] = useState(false)

  const { id } = useParams()

  useEffect(() => {
    supplierModule.inspectSupplier(id)
  }, [id])

  const {
    companyName,
    abn,
    website,
    address,
    phoneNumber,
    commercial,
    billing,
    heroPilot,
    supplierType,
  } = supplierDetail

  const initialValues = {
    companyName,
    abn,
    phoneNumber,
    website,
    address,
    supplierType,
    commercial,
    billing,
    heroPilot,
  }

  const isOnInvitedOrPendingState = [
    SUPPLIER_LABELS.PENDING_ACTIVATION,
    SUPPLIER_LABELS.INVITED,
  ].includes(status)

  return (
    <FormLayoutProvider value={{ editing }}>
      <FormProvider
        initialValues={initialValues}
        enableReinitialize
        onSubmit={async () => {
          setEditing(false)
        }}
      >
        {({ resetForm }) => (
          <SupplierLayout
            company={companyName}
            type={supplierType}
            status={status}
            loading={isInspectingSupplier}
            hideTabs={isOnInvitedOrPendingState}
            footer={
              editing && (
                <FormAction
                  onCancel={() => {
                    setEditing(false)
                    resetForm()
                  }}
                />
              )
            }
          >
            <Details
              title="Company Details"
              editing={editing}
              showExtra={false}
              setEditing={setEditing}
              values={{
                companyName,
                abn,
                website,
                address,
                phoneNumber,
                supplierType,
              }}
            />
            {isOnInvitedOrPendingState ? (
              <FieldGroup title="Actions">
                <ResendInvite id={id} />
              </FieldGroup>
            ) : null}
            {isNotNilOrEmpty(commercial) ? (
              <CommercialContact title="Key Contact" commercial={commercial || {}} />
            ) : null}
            {billing ? (
              <BillingContact title="Billing Contact" billing={billing || {}} />
            ) : null}
            {heroPilot && supplierType[0] !== SUPPLIER_TYPES.DATA_ANALYST[0] ? (
              <HeroPilotContact title="Hero Pilot" heroPilot={heroPilot || {}} />
            ) : null}
          </SupplierLayout>
        )}
      </FormProvider>
    </FormLayoutProvider>
  )
}

const ConnectedSupplierDetail = connect(() => ({
  supplierDetail: supplierDetail$,
  isInspectingSupplier: isInspectingSupplier$,
  status: invitationStatus$,
}))(SupplierDetail)

export { ConnectedSupplierDetail as SupplierDetail }
