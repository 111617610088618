const SUPPLIER_LABELS = {
  ACTIVE: 'active',
  UPDATED_DETAILS: 'updated-information',
  PENDING_ACTIVATION: 'pending-activation',
  INVITED: 'invited',
  PENDING_PARTNERSHIP: 'pending-partnership',
}

const SUPPLIER_LABEL_DISPLAY = {
  [SUPPLIER_LABELS.ACTIVE]: 'Active',
  [SUPPLIER_LABELS.UPDATED_DETAILS]: 'Company has updated informations',
  [SUPPLIER_LABELS.PENDING_ACTIVATION]: 'Pending Company Activation',
  [SUPPLIER_LABELS.INVITED]: 'Pending Company Activation',
  [SUPPLIER_LABELS.PENDING_PARTNERSHIP]: 'Pending Company Partnership',
}

const SUPPLIER_LABEL_COLOR = {
  [SUPPLIER_LABELS.ACTIVE]: '#005027',
  [SUPPLIER_LABELS.UPDATED_DETAILS]: '#AC9100',
  [SUPPLIER_LABELS.PENDING_ACTIVATION]: '#AC9100',
  [SUPPLIER_LABELS.INVITED]: '#AC9100',
  [SUPPLIER_LABELS.PENDING_PARTNERSHIP]: '#00326F',
}

const SUPPLIER_LABEL_BG_COLOR = {
  [SUPPLIER_LABELS.ACTIVE]: '#E0F0E8',
  [SUPPLIER_LABELS.UPDATED_DETAILS]: '#FAF7E7',
  [SUPPLIER_LABELS.PENDING_ACTIVATION]: '#FAF7E7',
  [SUPPLIER_LABELS.INVITED]: '#FAF7E7',
  [SUPPLIER_LABELS.PENDING_PARTNERSHIP]: '#E3EEF5',
}

const SUPPLIER_LABEL_TEXT = {
  [SUPPLIER_LABELS.ACTIVE]: 'Active',
  [SUPPLIER_LABELS.UPDATED_DETAILS]: 'Need to review',
  [SUPPLIER_LABELS.PENDING_ACTIVATION]: 'Pending',
  [SUPPLIER_LABELS.INVITED]: 'Pending',
  [SUPPLIER_LABELS.PENDING_PARTNERSHIP]: 'Need to review',
}

const SUPPLIER_LABEL_BADGE = {
  [SUPPLIER_LABELS.ACTIVE]: 'Active',
  [[SUPPLIER_LABELS.PENDING_ACTIVATION, SUPPLIER_LABELS.INVITED]]: 'Pending',
  [[SUPPLIER_LABELS.UPDATED_DETAILS, SUPPLIER_LABELS.PENDING_PARTNERSHIP]]:
    'Need to review',
}

const SUPPLIER_TYPES = {
  PILOT: ['pilot'],
  DATA_ANALYST: ['data_analyst'],
}

const SUPPLIER_TYPES_DISPLAY_NAMES = {
  [SUPPLIER_TYPES.PILOT]: 'Pilot Services',
  [SUPPLIER_TYPES.DATA_ANALYST]: 'Data Analyst Services',
  [SUPPLIER_TYPES.PILOT.concat(SUPPLIER_TYPES.DATA_ANALYST)]:
    'Pilot Services & Data Analyst Services',
}

export {
  SUPPLIER_LABELS,
  SUPPLIER_LABEL_DISPLAY,
  SUPPLIER_LABEL_TEXT,
  SUPPLIER_TYPES,
  SUPPLIER_TYPES_DISPLAY_NAMES,
  SUPPLIER_LABEL_COLOR,
  SUPPLIER_LABEL_BG_COLOR,
  SUPPLIER_LABEL_BADGE,
}
