import { AviationDetailFields } from './InvitePilotFormFields/AviationDetailFields'
import { EquipmentFormFields } from './InvitePilotFormFields/EquipmentFormFields'
import { PersonalDetailFields } from './InvitePilotFormFields/PersonalDetailFields'
import { ServiceFields } from './InvitePilotFormFields/ServiceFields'

import { FieldGroup } from 'components/company/common'

function InvitePilotForm({
  pilotCategoriesList,
  setAvatarFile,
  isInvitingMyself = false,
}) {
  return (
    <FieldGroup title="Invite New Pilot">
      <PersonalDetailFields
        pilotCategoriesList={pilotCategoriesList}
        setAvatarFile={setAvatarFile}
        isInvitingMySelf={isInvitingMyself}
      />
      {isInvitingMyself ? (
        <>
          <AviationDetailFields />
          <EquipmentFormFields />
          <ServiceFields />
        </>
      ) : null}
    </FieldGroup>
  )
}

export { InvitePilotForm }
