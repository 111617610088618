import { isNil } from '@seedcloud/ramda-extra'
import { connect } from '@seedcloud/stateless'
import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { General } from './General'
import { PersonalDetails } from './PersonalDetails'

import { ConfirmModal } from 'components/common/ConfirmModal/ConfirmModal'
import { Loader } from 'components/common/Loader'
import { Spinner } from 'components/common/Spinner'
import { Tabs } from 'components/common/Tabs'
import { API_TYPES } from 'lib/http-client'
import { useIdentity } from 'lib/solta-id-react'
import { styled, apply } from 'lib/styled'
import {
  inspectedApplication$,
  isInspectingApplication$,
  applicationModule,
} from 'modules/application'
import { createPilotCategoriesModule } from 'modules/pilotCategory'

const Container = styled.div(
  apply('flex-1 flex flex-column bg-white rounded-lg shadow-sm')
)

const {
  module: pilotCategoryModule,
  selectors: { pilotCategoriesList$ },
} = createPilotCategoriesModule(API_TYPES.WEB)

const ConnectedApplicationDetails = connect(() => ({
  isInspectingApplication: isInspectingApplication$,
  inspectedApplication: inspectedApplication$,
  pilotCategoriesList: pilotCategoriesList$,
}))(ApplicationDetails)

function ApplicationDetails({
  isInspectingApplication,
  inspectedApplication,
  pilotCategoriesList,
}) {
  const { organization } = useIdentity()
  const [isApproveModalOpen, setIsApproveModalOpen] = useState(false)
  const [isRejectModalOpen, setIsRejectModalOpen] = useState(false)
  const openApproveModal = () => setIsApproveModalOpen(true)
  const closeApproveModal = () => setIsApproveModalOpen(false)

  const openRejectModal = () => setIsRejectModalOpen(true)
  const closeRejectModal = () => setIsRejectModalOpen(false)
  const { id } = useParams()
  useEffect(() => {
    applicationModule.inspectApplication(id)
    pilotCategoryModule.filterPilotCategories(null, { filterQuery: { limit: 1000 } })
    pilotCategoryModule.fetchPilotCategories(null, {
      turnPage: false,
      type: organization.supplierType,
    })
  }, [id])

  if (isNil(inspectedApplication)) return null

  const tabsBaseFn = () => [
    {
      title: 'Personal Details',
      path: `/applications/${id}/personal-details`,
      id: 'personal-details',
    },
    {
      title: 'Aviation Details',
      path: `/applications/${id}/aviation-details`,
      id: 'aviation-details',
    },
  ]
  const tabsBase = tabsBaseFn()
  const {
    status,
    firstName,
    lastName,
    companyName,
    phoneNumber,
    email,
    avatar,
    pilotCategory,
  } = inspectedApplication

  const onApproveConfirm = () => {
    setIsApproveModalOpen(false)
    applicationModule.approveApplication(id, pilotCategory)
  }
  const onRejectConfirm = () => {
    setIsRejectModalOpen(false)
    applicationModule.rejectApplication(id)
  }
  return (
    <Container data-test-id="application-details-container">
      <Loader
        loading={isInspectingApplication}
        renderPlaceholder={
          <Spinner size={64} thickness={7} style={{ margin: 'auto 0' }} />
        }
      >
        <General
          id={id}
          openApproveModal={openApproveModal}
          openRejectModal={openRejectModal}
        />
        <Tabs
          tabs={tabsBase}
          defaultSelectedId="personal-details"
          pathLength={id ? 4 : 3}
        />
        <PersonalDetails
          id={id}
          status={status}
          firstName={firstName}
          lastName={lastName}
          companyName={companyName}
          phoneNumber={phoneNumber}
          email={email}
          avatarSrc={avatar}
          pilotCategoriesList={pilotCategoriesList}
          pilotCategory={pilotCategory}
        />
      </Loader>
      <ConfirmModal
        title="Confirmation"
        subtitle="Are you sure you would like to Approve this application?"
        isOpen={isApproveModalOpen}
        closeModal={closeApproveModal}
        confirmLoadingText="Approving..."
        onConfirm={onApproveConfirm}
      />
      <ConfirmModal
        title="Confirmation"
        subtitle="Are you sure you would like to Reject this application?"
        isOpen={isRejectModalOpen}
        closeModal={closeRejectModal}
        confirmLoadingText="Approving..."
        onConfirm={onRejectConfirm}
      />
    </Container>
  )
}

export { ConnectedApplicationDetails as ApplicationDetails }
