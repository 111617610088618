/* eslint-disable camelcase */
import { isNotNilOrEmpty, path, prop } from '@seedcloud/ramda-extra'
import { connect } from '@seedcloud/stateless'
import { useState, useLayoutEffect, useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'

import { usePilotCompany } from '../common/hooks/usePilotCompany'

import { JobDetails } from './JobDetails'
import getFormInitialValues from './utils/getFormInitialValues'

import { JobsProvider } from 'components/common/context/JobContext'
import { DOCUMENT_TYPES } from 'constants/documents'
import { JOB_STATUSES } from 'constants/jobs'
import { ROLES } from 'constants/roles'
import { API_TYPES } from 'lib/http-client'
import { useIdentity } from 'lib/solta-id-react'
import { createCompanyModule as createNewCompanyModule } from 'modules/company'
import { createDocumentModule } from 'modules/document'
import {
  inspectedJob$,
  isFinalizingJob$,
  isInspectingJobs$,
  jobModule,
  selectedTab$,
} from 'modules/job'
import { createCompanyModule } from 'modules/legacy-company'
import { createOrganizationModule } from 'modules/organization'
import { createPilotModule } from 'modules/pilot'
import { productModule, productList$, filterQuery$ } from 'modules/product'
import { projectList$, projectModule } from 'modules/project'
import { staffList$ } from 'modules/staff'
import { supplierList$ } from 'modules/supplier'
import { useUpdateEffect } from 'utils/hooks'

const {
  module: documentModule,
  selectors: {
    jobDocumentList$,
    jobFolderList$,
    inspectedFolder$,
    organizationDocumentList$,
  },
} = createDocumentModule(API_TYPES.WEB)

const {
  module: companyModule,
  selectors: { companyList$ },
} = createCompanyModule(API_TYPES.WEB)

const {
  module: newCompanyModule,
  selectors: { isDownloading$ },
} = createNewCompanyModule()

const {
  module: organizationModule,
  selectors: { organizationTermsAndCondition$, isInspectingOrganization$ },
} = createOrganizationModule(API_TYPES.WEB)

const {
  module: pilotCompanyModule,
  selectors: { inspectedOrganization$: inspectedPilotCompany$ },
} = createOrganizationModule(API_TYPES.WEB, 'company/pilot')

const {
  module: pilotModule,
  selectors: { pilotList$ },
} = createPilotModule(API_TYPES.WEB)

const ConnectedJobDetailsContainer = connect(() => ({
  inspectedJob: inspectedJob$,
  isInspectingJobs: isInspectingJobs$,
  jobDocumentList: jobDocumentList$,
  organizationDocumentList: organizationDocumentList$,
  jobFolderList: jobFolderList$,
  inspectedFolder: inspectedFolder$,
  productList: productList$,
  companyList: companyList$,
  pilotList: pilotList$,
  projectList: projectList$,
  supplierList: supplierList$,
  filterQuery: filterQuery$,
  staffList: staffList$,
  isFinalizingJob: isFinalizingJob$,
  selectedTab: selectedTab$,
  documentTnC: organizationTermsAndCondition$,
  isDownloadingTnC: isDownloading$,
  isInspectingOrganization: isInspectingOrganization$,
  pilotCompany: inspectedPilotCompany$,
}))(JobDetailsContainer)

function JobDetailsContainer({
  selectedTab,
  inspectedJob = {},
  isInspectingJobs,
  jobDocumentList,
  organizationDocumentList,
  jobFolderList,
  inspectedFolder,
  productList,
  companyList,
  pilotList,
  projectList,
  supplierList,
  staffList,
  isFinalizingJob,
  documentTnC,
  isDownloadingTnC,
  isInspectingOrganization,
  pilotCompany,
}) {
  const [isEditing, setIsEditing] = useState(false)
  const { id } = useParams()
  const { role } = useIdentity()

  useLayoutEffect(() => {
    switch (selectedTab) {
      case 'published':
      case 'new':
        jobModule.inspectAvailableJob(id)
        break
      default:
        jobModule.inspectEngagedJob(id)
    }
  }, [])

  // Fetch the pilot's company details
  usePilotCompany(pilotCompanyModule, inspectedJob, 'engagedBy')

  useEffect(() => {
    const scroll = document.querySelector('#scroll')
    if (scroll) scroll.scrollTop = 0
  }, [inspectedJob?.status])

  const { status: jobStatus, reference } = inspectedJob

  const { jobInfo, documents, contactInfo } = useMemo(
    () =>
      getFormInitialValues(
        inspectedJob,
        jobDocumentList,
        organizationDocumentList,
        pilotCompany
      ),
    [inspectedJob, jobDocumentList, organizationDocumentList, pilotCompany]
  )

  const showEditingTab = ![
    JOB_STATUSES.PUBLISHED,
    JOB_STATUSES.ACCEPTED,
    JOB_STATUSES.IN_REVIEW,
    JOB_STATUSES.COMPLETED,
  ].includes(jobStatus)

  const isKeyContacts = [ROLES.USER].includes(role)

  const initialValues = useMemo(
    () => ({
      status: jobStatus,
      jobInfo,
      contactInfo,
      documents: { uploadedByPilot: [], uploadedByOrganization: [] },
    }),
    [jobStatus, jobInfo, contactInfo]
  )

  const loading = !inspectedJob || id !== prop('id', inspectedJob) || isInspectingJobs

  // JobInfo
  useLayoutEffect(() => {
    productModule.fetchProducts(null)
    projectModule.fetchProjects(null)
    companyModule.fetchCompanies(null)
    pilotModule.fetchPilots(null, { includeCompanyPilots: true })
    // pilotCategoryModule.filterPilotCategories(null, { filterQuery: { limit: 1000 } })
    // pilotCategoryModule.fetchPilotCategories(null, { type: ['EQUIPMENT'] })

    // we use filterJobDocuments instead of fetchJobDocuments to be explicit about fetching ALL job document types.
    documentModule.inspectFolder(id)
    documentModule.filterJobDocuments(id, {
      documentTypes: [
        DOCUMENT_TYPES.JOB_TERMS,
        DOCUMENT_TYPES.JOB_JSEA,
        DOCUMENT_TYPES.JOB_CASA,
        DOCUMENT_TYPES.JOB_ASSET,
      ],
    })

    return () => {
      documentModule.reset()
      pilotModule.reset()
      // pilotCategoryModule.resetPagination()
    }
  }, [])

  useUpdateEffect(() => {
    const id = path(['organizationId'], inspectedJob)
    if (isNotNilOrEmpty(id)) {
      organizationModule.inspectOrganization(id)
    }
  }, [inspectedJob])

  const onCreateProject = (action, reference) => {
    if (action === 'create-option') {
      projectModule.createProject(null, reference)
    }
  }

  const onFilterProduct = productModule.filterProducts

  // Actions
  const onAcceptJob = async (id) => {
    await jobModule.acceptJob(id)
    setIsEditing(false)
  }

  const onRejectJob = async (id, payload) => {
    await jobModule.rejectJob(id, payload)
    setIsEditing(false)
  }

  const onPublishJob = async (id, payload) => {
    await jobModule.publishJob(id, payload)
    setIsEditing(false)
  }

  const onFinalizeJob = async (payload) => {
    await jobModule.finalizeJob(null, payload)
    setIsEditing(false)
  }

  const onUpdateJob = async (id, payload) => {
    await jobModule.updateJob(id, { ...payload, fetchEngaged: true })
    setIsEditing(false)
  }

  const onSupplierAcceptJob = async (id, payload) => {
    await jobModule.updateJob(id, { ...payload, role })
    setIsEditing(false)
  }

  const onEngagedJob = async () => {
    await jobModule.engageJob()
  }

  const onUpdateEngagedJob = async (id, payload) => {
    await jobModule.updateEngagedJob(id, payload)
  }

  const onUploadJobDocument = async (file, documentType) =>
    documentModule.uploadJobDocument(null, {
      file,
      documentType,
    })

  const onDownloadJobDocument = async (id, fileSize) =>
    documentModule.downloadJobDocument(id, { multipart: true, fileSize })

  const onDeleteJobDocument = documentModule.deleteJobDocument
  const onConfirmJobDocument = documentModule.confirmJobDocumentUpload
  const onInspectFolder = documentModule.inspectFolder
  const onCreateFolder = documentModule.createFolder

  // Compliance
  const onDownloadOrganizationTnC = async (event) => {
    event.preventDefault()
    if (documentTnC) {
      const file = await newCompanyModule.downloadAviationDocuments(
        documentTnC.id,
        inspectedJob.organizationId
      )
      if (file) {
        window.open(URL.createObjectURL(file))
      }
    }
  }

  const shouldDisableCompliance = isDownloadingTnC || isInspectingOrganization

  return (
    <JobsProvider
      value={{
        isEditing,
        setIsEditing,
        jobStatus,
        showEditingTab,
        showComplianceSection: true,
        initialValues,
        reference,
        loading,
        inspectedJob,
        isFinalizingJob,
        jobDocumentList,
        organizationDocumentList,
        jobFolderList,
        inspectedFolder,
        isAdmin: false,
        isKeyContacts,
        // JobInfo props
        productList,
        projectList,
        companyList,
        pilotList,
        supplierList,
        onCreateProject,
        onFilterProduct,
        // ContactInfo props
        editableClient: false,
        staffList,
        // Actions
        onAcceptJob,
        onRejectJob,
        onPublishJob,
        onFinalizeJob,
        onUpdateJob,
        onEngagedJob,
        onUpdateEngagedJob,
        onUploadJobDocument,
        onDownloadJobDocument,
        onDeleteJobDocument,
        onConfirmJobDocument,
        onDownloadOrganizationTnC,
        shouldDisableCompliance,
        onInspectFolder,
        onCreateFolder,
        onSupplierAcceptJob,
        documents,
      }}
    >
      <JobDetails />
    </JobsProvider>
  )
}

export { ConnectedJobDetailsContainer }
