import TextField from '@material-ui/core/TextField'
import { DateRangePicker, LocalizationProvider } from '@material-ui/pickers'
import DateFnsAdapter from '@material-ui/pickers/adapter/date-fns'
import { useState } from 'react'

import { useFilter } from './FilterBar'

import { ReactComponent as CloseIcon } from 'assets/close.svg'
import { newInputSizeVariants } from 'components/common/Fields/Input'
import { styled, apply } from 'lib/styled'
import { useDebounce, useUpdateEffect } from 'utils/hooks'

const InputContainer = styled.div(
  apply('flex', {
    gap: '1rem',
    height: '46px',
  })
)

const NewInput = styled(TextField, { shouldForwardProp: (p) => p !== 'inputStyles' })(
  apply('border text-black rounded-lg w-8', {
    border: '1px solid #CCCCCC',
    // NOTE: The :read-only pseudo-selector in CSS
    //       will match both `readonly` and `disabled` elements.
    '&:read-only': {
      color: 'rgba(0, 0, 0, 0.38)',
      cursor: 'default',
      height: '100%',
    },
  }),
  ({ inputsize = 'md' }) => newInputSizeVariants[inputsize],
  ({ disabled }) =>
    disabled ? apply('bg-gray-400 border-gray-700 text-gray-600') : {},
  ({ readOnly }) =>
    readOnly
      ? apply('bg-gray-400 border-gray-700', {
          '& .MuiInputBase-input': apply('text-gray-800'),
        })
      : {},
  ({ inputStyles }) =>
    apply('justify-center', {
      '& .MuiInputBase-root': apply('', {
        lineHeight: 'inherit',
        fontFamily: 'unset',
      }),
      '& .MuiInputBase-input': apply('py-0', {
        height: 'inherit',
        fontSize: '0.9375rem',
        ...inputStyles,
      }),
      '& .MuiIconButton-edgeEnd': {
        padding: '6px',
        marginRight: '-8px',
      },
    }),
  {
    '& .MuiPickersDateRangePickerInput-root': apply('h-full'),
    '& .MuiFormHelperText-root': apply('hidden'),
    '& .MuiInputBase-root::before': apply('hidden'),
    '& .MuiInputBase-root::after': apply('hidden'),
    '& .MuiOutlinedInput-root.Mui-focused': apply('', {
      border: 'none',
    }),
    '& .MuiOutlinedInput-notchedOutline': apply('', {
      border: 'none',
    }),
  }
)

const DateRange = () => {
  const { onSubmit } = useFilter()
  const [selectedDate, setSelectedDateRange] = useState([null, null])

  const debouncedFrom = useDebounce(selectedDate[0], 500)
  const debouncedTo = useDebounce(selectedDate[1], 500)
  useUpdateEffect(() => {
    onSubmit({ from: debouncedFrom })
  }, [debouncedFrom])

  useUpdateEffect(() => {
    onSubmit({ to: debouncedTo })
  }, [debouncedTo])
  return (
    <LocalizationProvider dateAdapter={DateFnsAdapter}>
      <DateRangePicker
        startText=""
        endText=""
        inputFormat="dd/MM/yyyy"
        value={selectedDate}
        onChange={(newValue) => {
          if (newValue[1]) {
            newValue[1].setHours(23, 59, 59, 999)
          }
          setSelectedDateRange(newValue)
        }}
        renderInput={(startProps, endProps) => (
          <InputContainer>
            <NewInput
              {...startProps}
              InputProps={{
                endAdornment: selectedDate[0] && (
                  <CloseIcon
                    style={
                      (apply('ml-2'),
                      {
                        cursor: 'pointer',
                      })
                    }
                    onClick={() => setSelectedDateRange([null, selectedDate[1]])}
                  />
                ),
              }}
              inputProps={{
                ...startProps.inputProps,
                placeholder: 'Start Date',
              }}
            />
            <NewInput
              {...endProps}
              InputProps={{
                endAdornment: selectedDate[1] && (
                  <CloseIcon
                    style={
                      (apply('ml-2'),
                      {
                        cursor: 'pointer',
                      })
                    }
                    onClick={() => setSelectedDateRange([selectedDate[0], null])}
                  />
                ),
              }}
              inputProps={{
                ...endProps.inputProps,
                placeholder: 'End Date',
              }}
            />
          </InputContainer>
        )}
      />
    </LocalizationProvider>
  )
}

export { DateRange }
