import { useState } from 'react'

import { Tabs } from './ui'

import { styled, apply } from 'lib/styled'

export const TabItem = styled.button(
  apply('text-center py-4 px-6 font-black relative'),
  ({ selected, theme, width, disabled }) => ({
    width,
    backgroundColor: 'white',
    border: 'none',
    color: selected ? `${theme.colors.primary}` : '#AAAAAA',
    cursor: disabled ? 'not-allowed' : 'pointer',
    '&::after': {
      content: selected ? '""' : 'unset',
      position: 'absolute',
      bottom: '0',
      left: '0',
      height: '4px',
      backgroundColor: `${theme.colors.primary}`,
      borderRadius: '.25em .25em 0 0',
      width: '100%',
    },
  })
)

export const NormalTabs = ({
  children,
  numberOfTabs,
  isDisabled = false,
  selectedTab,
}) => {
  const [selected, setSelected] = useState(0)
  const processedWidth = 100 / numberOfTabs

  // change tab with customized button trigger
  if (selectedTab) {
    const buttons = children.map((child, index) => (
      <TabItem
        type="button"
        disabled={isDisabled}
        key={index}
        selected={selectedTab === index}
        width={`${processedWidth}%`}
      >
        {child.props.title}
      </TabItem>
    ))
    return (
      <>
        <Tabs>{buttons}</Tabs>
        {children[selectedTab].props.children}
      </>
    )
  }

  // change tab with clicking tab
  const buttons = children.map((child, index) => (
    <TabItem
      type="button"
      disabled={isDisabled}
      key={index}
      onClick={() => setSelected(index)}
      selected={selected === index}
      width={`${processedWidth}%`}
    >
      {child.props.title}
    </TabItem>
  ))
  return (
    <>
      <Tabs>{buttons}</Tabs>
      {children[selected].props.children}
    </>
  )
}
