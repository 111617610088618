/* eslint-disable complexity */

import { path, prepend } from '@seedcloud/ramda-extra'
import { useFormikContext as useFormContext } from 'formik'
import moment from 'moment'
import { useState, useRef, useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'

import { FolderPanel } from '../documents/FolderPanel'
import { UploadInstructionModal } from '../documents/UploadInstructionModal'
import { UploadPanel } from '../documents/UploadPanel'
import { JobDocuments } from '../JobDocuments'
import { usePilotEquipments } from '../utils/usePilotEquipments'

import { CompanyModal } from './CompanyModal'

// import { ReactComponent as ExclamationCircleIcon } from 'assets/exclamation-circle.svg'
import { Button } from 'components/common/Button'
import { ConfirmModal } from 'components/common/ConfirmModal/ConfirmModal'
import { useJobs } from 'components/common/context/JobContext'
import { DateField } from 'components/common/DateField'
import { FormInput as FormInputBase } from 'components/common/FormInput'
import { LocationField } from 'components/common/LocationField'
import { SelectField } from 'components/common/SelectField'
import { Title } from 'components/common/Tabs/ui'
import { TextAreaField } from 'components/common/TextAreaField'
import { Toggle as ToggleBase } from 'components/common/Toggle'
import { AreaOfInterest, ComplianceSection, ContactInfo } from 'components/jobs/common'
import { ComplianceCard } from 'components/jobs/common/ComplianceCard'
import { JobPublishNetwork } from 'components/jobs/common/JobPublishNetwork'
import { JOB_STATUSES } from 'constants/jobs'
import { PUBLISH_TYPES, PUBLISH_TYPES_DISPLAY_NAMES } from 'constants/publishType'
import { ROLES } from 'constants/roles'
import { SERVICE_CATEGORIES } from 'constants/serviceCategories'
import { useIdentity } from 'lib/solta-id-react'
import { apply, styled } from 'lib/styled'
import toast from 'lib/toaster'
import { JobService } from 'modules/job'
import { formatCurrency } from 'utils/formatCurrency'

const ButtonLink = styled(Button)(apply('bg-transparent border-0 underline'), {
  color: '#00326F',
})
const Container = styled.div(apply('mb-12'))
const Header = styled.div(apply('flex flex-column pt-10 pb-4'), {
  '&:first-of-type': apply('mt-0'),
  '&:last-of-type': apply('mb-0'),
  color: '#1F1F1F',
})
const Description = styled.span(apply('text-base text-grey-dark mt-1'))
const Details = styled.div(apply('flex-1 flex flex-row'))
const Column = styled.div(
  apply('flex-1 flex flex-column'),
  ({ gap, flex, marginRight }) => ({
    gap: gap || 0,
    flex: flex || '',
    marginRight: marginRight || '',
  })
)

const Row = styled.div(
  apply('flex flex-row my-2 items-start', {
    '&:first-of-type': apply('mt-0'),
    '&:last-of-type': apply('mb-0'),
    columnGap: 16,
  })
)

const FormInput = styled(FormInputBase)({
  '&:first-of-type': apply('ml-0'),
  '&:last-of-type': apply('mr-0'),
  marginLeft: 0,
  marginRight: 0,
})

const Separator = styled.div(
  apply('mx-4', {
    borderLeft: '1px solid #ccc',
    height: '100%',
  })
)
const PricingToggleContainer = styled.div(apply('flex my-3'))
const PricingContainer = styled.div(apply(''))
const Label = styled.label(apply('mb-2 uppercase tracking-wide text-xs text-grey-dark'))
const ErrorLabel = styled.label(apply('my-2 tracking-wide text-xs text-red'))
const Toggle = styled(ToggleBase)(apply('mr-2'))
const ToggleContainer = styled.div(apply('flex items-center pr-8'))
const SubTitle = styled.h2(
  apply('text-xl font-medium', {
    color: '#555555',
  })
)
const ToggleLabel = styled.label(
  apply('uppercase tracking-wide text-xs text-grey-dark mb-0')
)
const FeedbackButton = styled(Button)(
  apply('w-12 py-4 px-4 flex items-center justify-center')
)

function JobInfo({ role }) {
  const [isCompanyModalOpen, setIsCompanyModalOpen] = useState(false)
  const [isReassignModalOpen, setIsReassignModalOpen] = useState(false)
  const { isSupplier, account } = useIdentity()

  const {
    isAdmin,
    isNBS,
    productList,
    // projectList,
    customerList,
    pilotList,
    supplierList,
    reference: jobReference,
    isEditing,
    jobStatus,
    // onCreateProject,
    onFilterProduct,
    onUpdateJob,
    showSubmitFeedbackActions,
    inspectedJob,
    showComplianceSection,
    initialValues,
  } = useJobs()
  const isStarted = initialValues.jobInfo.startedAt === 'Unspecified'

  const {
    setFieldValue,
    values: {
      jobInfo: { ratings, feedback, publishedAt, ...jobInfo },
    },
    errors,
  } = useFormContext()

  const {
    radius,
    location,
    commercialSettings,
    acceptedBy,
    rejectedBy,
    publishType,
    pricing,
    publishedBy,
    company,
    status,
    engagedBy,
  } = jobInfo

  const jobPublished = [
    JOB_STATUSES.PUBLISHED,
    JOB_STATUSES.ENGAGED,
    JOB_STATUSES.DELIVERED,
    JOB_STATUSES.IN_REVIEW,
    JOB_STATUSES.IN_REVIEW_NETWORK,
    JOB_STATUSES.COMPLETED,
    JOB_STATUSES.DISPUTED,
  ].includes(jobStatus)

  const jobCanceled = [JOB_STATUSES.CANCELLED].includes(jobStatus)

  const isNetworkJobQueue =
    isNBS && jobStatus === JOB_STATUSES.QUEUE && publishType === PUBLISH_TYPES.NETWORK

  const acceptTermsConditionsError = path(['jobInfo', 'acceptTermsConditions'], errors)
  const { billable, paid, dataUpload } = commercialSettings
  const { timeMaterial } = pricing

  const coordinates = location?.geometry?.coordinates

  const publishTypeOptions = Object.values(PUBLISH_TYPES)
    .map((publishType) => ({
      id: publishType,
      value: publishType,
      label: PUBLISH_TYPES_DISPLAY_NAMES[publishType],
    }))
    .filter(({ value }) => {
      if (!isNBS || jobPublished) return true
      return value !== PUBLISH_TYPES.NETWORK || isNetworkJobQueue
    })

  const productOptions = productList
    .filter((product) => product.isActive)
    .map(({ id, name: label }) => ({
      id,
      value: label,
      label,
    }))
  const reassignOptions = [
    { id: '3', value: JOB_STATUSES.ENGAGED, label: 'Engaged' },
    { id: '4', value: JOB_STATUSES.DELIVERED, label: 'Delivered' },
    { id: '5', value: JOB_STATUSES.COMPLETED, label: 'Completed' },
  ]
  // const projectOptions = projectList.map(({ id, reference }) => ({
  //   id,
  //   value: reference,
  //   label: reference,
  // }))

  const pilotOptions = prepend(
    { id: undefined, value: undefined, label: 'All Pilot' },
    pilotList
      .filter(({ isActive }) => isActive)
      .map(({ id, userDetails }) => {
        const fullName = `${userDetails.firstName} ${userDetails.lastName}`
        return {
          id,
          value: fullName,
          label: fullName,
        }
      })
  )

  const assignPilotOptions = prepend(
    { id: undefined, value: undefined, label: 'Select Pilot' },
    pilotList.map(({ id, userDetails, pilotCategory, serviceCategories }) => {
      const fullName = userDetails.lastName
        ? `${userDetails.firstName} ${userDetails.lastName}`
        : userDetails.firstName
      const category = pilotCategory.description
      const services = serviceCategories
        ?.map(
          (service) =>
            SERVICE_CATEGORIES.find(({ value }) => value === service)?.readableValue ||
            ''
        )
        .filter(Boolean)
        .join(', ')

      return {
        id,
        value: fullName,
        label: `${fullName}${category ? ` / ${category}` : ''}${
          services ? ` / ${services}` : ''
        }`,
      }
    })
  )

  const customerOptions =
    customerList?.map(({ id, contactName, user: { email, phoneNumber } }) => ({
      value: contactName,
      label: contactName,
      email,
      phoneNumber,
      id,
    })) || []

  const supplierListOptions = prepend(
    { id: undefined, value: undefined, label: 'All Supplier' },
    supplierList.map(({ id, company: { companyName } }) => ({
      id,
      value: companyName,
      label: companyName,
    }))
  )

  const ratingsOptions = [
    { id: '1', value: '1', label: '1' },
    { id: '2', value: '2', label: '2' },
    { id: '3', value: '3', label: '3' },
    { id: '4', value: '4', label: '4' },
    { id: '5', value: '5', label: '5' },
  ]

  const jobEngaged = [
    JOB_STATUSES.ENGAGED,
    // JOB_STATUSES.DELIVERED,
    JOB_STATUSES.IN_REVIEW,
    // JOB_STATUSES.IN_REVIEW_NETWORK,
    JOB_STATUSES.COMPLETED,
    // JOB_STATUSES.DISPUTED,
  ].includes(jobStatus)

  const jobDelivered = [
    JOB_STATUSES.DELIVERED,
    JOB_STATUSES.IN_REVIEW,
    JOB_STATUSES.IN_REVIEW_NETWORK,
    JOB_STATUSES.COMPLETED,
    JOB_STATUSES.DISPUTED,
  ].includes(jobStatus)

  const jobInReview = [
    JOB_STATUSES.IN_REVIEW,
    JOB_STATUSES.IN_REVIEW_NETWORK,
    JOB_STATUSES.COMPLETED,
    JOB_STATUSES.DISPUTED,
  ].includes(jobStatus)

  const jobCompleted = [JOB_STATUSES.COMPLETED].includes(jobStatus)

  const jobDisputed = [JOB_STATUSES.DISPUTED].includes(jobStatus)

  return (
    <Container>
      <Details>
        <Column>
          {jobDisputed && (
            <>
              <Header>
                <Title id="disputed">Disputed</Title>
              </Header>
              <Row>
                <FormInput
                  disabled
                  name="jobInfo.disputeReason"
                  type="text"
                  label="Reason"
                />
              </Row>
              {isAdmin && (
                <>
                  <Row>
                    <SelectField
                      name="jobInfo.status"
                      label="Select State"
                      isDisabled={!isEditing}
                      options={reassignOptions}
                      placeholder="Select State"
                    />
                  </Row>
                  <Row>
                    <Button
                      disabled={!isEditing}
                      type="button"
                      onClick={() => setIsReassignModalOpen(true)}
                    >
                      Re-assign
                    </Button>
                  </Row>
                </>
              )}
            </>
          )}

          {jobStatus === JOB_STATUSES.ACCEPTED && role === ROLES.SUPPLIER_ADMIN && (
            <>
              <Header>
                <Title id="assign-pilot">Assign Pilot</Title>
              </Header>
              <SelectField
                name="jobInfo.selectedPilot.name"
                label="Select Pilot"
                emptyOption={{
                  id: undefined,
                  value: undefined,
                }}
                onChange={({ id, value: name }) => {
                  setFieldValue('jobInfo.selectedPilot', { id, name })
                }}
                options={assignPilotOptions}
                placeholder="Select Pilot"
              />
            </>
          )}

          <Header>
            <Title id="info">Job Info</Title>
          </Header>

          {isAdmin ? (
            <>
              <Row>
                <SelectField
                  name="jobInfo.selectedCustomer.contactName"
                  label="Customer Name"
                  isDisabled
                  options={customerOptions}
                  placeholder="Select customer"
                  testId="job-company"
                />
              </Row>
              <Row>
                <FormInput
                  name="jobInfo.selectedCustomer.companyEmail"
                  label="Customer Email"
                  testId="job-customer-email"
                  disabled
                />
                <FormInput
                  name="jobInfo.selectedCustomer.companyNumber"
                  label="Customer Number"
                  testId="job-customer-phone"
                  disabled
                />
              </Row>
              {/* <Row>
                <SelectField
                  name="jobInfo.project.reference"
                  label="Customer Order ID"
                  labelIcon={
                    <ExclamationCircleIcon
                      {...apply('h-1 w-1', {
                        stroke: '#334155',
                      })}
                      data-tip="Project ID is only needed before finalising a job"
                      data-type="info"
                    />
                  }
                  creatable
                  isDisabled
                  emptyOption={{ id: undefined, reference: undefined }}
                  onChange={({ id, value: reference }, { action }) => {
                    onCreateProject(action, reference)
                    setFieldValue('jobInfo.project', { id, reference })
                  }}
                  options={projectOptions}
                />
              </Row> */}
            </>
          ) : (
            <>
              <Row>
                <FormInput
                  disabled
                  name="jobInfo.reference"
                  type="text"
                  label="Reference"
                />
                {/* {jobStatus === JOB_STATUSES.ENGAGED && role === ROLES.USER && (
                  <DateField
                    disabled
                    name="jobInfo.createdAt"
                    type="text"
                    label="Created At"
                  />
                )} */}
              </Row>
            </>
          )}

          {role === ROLES.PLATFORM_ADMIN && (
            <>
              <Label style={{ marginTop: 12 }}>Commercial settings</Label>
              <Row>
                <ToggleContainer>
                  <Toggle
                    onChange={(e) =>
                      setFieldValue(
                        'jobInfo.commercialSettings.billable',
                        e.target.checked
                      )
                    }
                    checked={billable}
                    disabled
                  />
                  <ToggleLabel>Billable</ToggleLabel>
                </ToggleContainer>
                <ToggleContainer>
                  <Toggle
                    onChange={(e) =>
                      setFieldValue('jobInfo.commercialSettings.paid', e.target.checked)
                    }
                    checked={paid}
                    disabled
                  />
                  <ToggleLabel>Paid</ToggleLabel>
                </ToggleContainer>
                <ToggleContainer>
                  <Toggle
                    onChange={(e) =>
                      setFieldValue(
                        'jobInfo.commercialSettings.dataUpload',
                        e.target.checked
                      )
                    }
                    checked={dataUpload}
                    disabled
                  />
                  <ToggleLabel>Data Upload</ToggleLabel>
                </ToggleContainer>
              </Row>
            </>
          )}

          {(jobStatus !== JOB_STATUSES.ENGAGED && role !== ROLES.USER) ||
          (jobStatus === JOB_STATUSES.ENGAGED && role === ROLES.USER) ? (
            <>
              <Row>
                <SelectField
                  name="jobInfo.product.name"
                  label="Product Name"
                  isDisabled
                  options={productOptions}
                  placeholder="Search and Select Product"
                  onInputChange={onFilterProduct}
                />

                <DateField
                  disabled
                  name="jobInfo.scheduledAt"
                  type="text"
                  label="Scheduled At"
                  autoOk
                />
              </Row>
            </>
          ) : null}

          <Row>
            <LocationField
              name="jobInfo.location"
              label="Location"
              disabled
              containerProps={{ style: { flexGrow: 6 } }}
            />
            <FormInput name="jobInfo.radius" label="Radius in meters" disabled />
          </Row>

          <AreaOfInterest coordinates={coordinates} radius={radius} />
          {jobStatus !== JOB_STATUSES.ENGAGED && isAdmin && (
            <Row style={{ marginTop: 16 }}>
              <SelectField
                name="jobInfo.template"
                label="Job Template Type"
                options={[{ id: 'default', value: 'default', label: 'Default' }]}
                placeholder="Select a template type"
                isDisabled
                testId="job-template-type"
              />
            </Row>
          )}

          {jobStatus !== JOB_STATUSES.NEW && (
            <>
              {/* we force the component to remount since the form resets on submit, clearing out the docs inside formik state */}
              {showComplianceSection ? (
                <ComplianceSection key={isEditing} disabled={!isEditing} role={role} />
              ) : null}

              {publishedBy && isAdmin ? (
                <Row>
                  <FormInput
                    disabled
                    name="jobInfo.publishedBy.fullName"
                    type="text"
                    label="Published By"
                  />
                  <DateField
                    disabled
                    name="jobInfo.publishedAt"
                    type="text"
                    label="Published At"
                    autoOk
                  />
                </Row>
              ) : null}

              {acceptedBy && isAdmin && (
                <Row>
                  <FormInput
                    disabled
                    name="jobInfo.acceptedBy.fullName"
                    type="text"
                    label="Accepted By"
                  />
                  <DateField
                    disabled
                    name="jobInfo.acceptedAt"
                    type="text"
                    label="Accepted At"
                    autoOk
                  />
                </Row>
              )}

              {rejectedBy && isAdmin && !jobCanceled && (
                <>
                  <Label style={apply('mt-4')}>
                    Rejected by {rejectedBy.firstName} {rejectedBy.lastName}
                  </Label>
                  <TextAreaField
                    name="jobInfo.rejectionReason"
                    containerStyle={apply('w-full')}
                    minRows={1}
                    noLabel
                  />
                </>
              )}

              <JobPublishNetwork
                pilotOptions={pilotOptions}
                supplierListOptions={supplierListOptions}
                publishTypeOptions={publishTypeOptions}
                isDisabled={!isEditing || jobPublished}
              />

              {!jobCanceled ? (
                <>
                  <Header>
                    <Title id="job-pricing">Job Pricing</Title>
                    <Description>
                      This job operates on a Time & Material pricing model. This means
                      that compensation will be based on the actual time spent
                      completing the job, billed at the hourly rate specified in the
                      company&apos;s rate card. Compensation will also be based on
                      Equipment Used – if the job requires specialised tools, or
                      materials, these will also be billed according to the
                      company&apos;s rate card.
                    </Description>
                  </Header>
                  <Row>
                    <PricingContainer>
                      <Label>Job Pricing</Label>
                      <PricingToggleContainer>
                        <ToggleContainer>
                          <Toggle
                            onChange={(e) =>
                              setFieldValue(
                                'jobInfo.pricing.timeMaterial',
                                e.target.checked
                              )
                            }
                            checked={timeMaterial}
                            disabled={!isEditing}
                          />
                          <ToggleLabel>Time & Material</ToggleLabel>
                        </ToggleContainer>
                      </PricingToggleContainer>
                    </PricingContainer>
                    <FormInput
                      disabled={!isEditing}
                      name="jobInfo.pricing.budget"
                      type="number"
                      label="Budget"
                    />
                  </Row>
                  <Header>
                    <SubTitle>Product Estimate</SubTitle>
                  </Header>
                  <Row>
                    <FormInput
                      disabled
                      name="jobInfo.product.pricing.baseDuration"
                      type="text"
                      label="Standard Fly Time"
                      value={`${jobInfo.product.pricing.baseDuration} Mins`}
                    />
                    {isAdmin && (
                      <>
                        <FormInput
                          disabled
                          name="jobInfo.product.pricing.basePrice"
                          type="text"
                          label="Retail Price"
                        />
                        <FormInput
                          disabled
                          name="jobInfo.product.pricing.basePayoutRate"
                          type="text"
                          label="Payout Rate (%)"
                        />
                      </>
                    )}
                  </Row>
                  {isAdmin && (
                    <>
                      <Row>
                        <FormInput
                          disabled
                          name="jobInfo.product.pricing.incrementDuration"
                          type="text"
                          label="Extra Time (per package)"
                          value={`${jobInfo.product.pricing.incrementDuration} Mins`}
                        />
                        <FormInput
                          disabled
                          name="jobInfo.product.pricing.incrementPrice"
                          type="text"
                          label="Retail Price"
                        />
                        <FormInput
                          disabled
                          name="jobInfo.product.pricing.incrementPayoutRate"
                          type="text"
                          label="Payout Rate (%)"
                        />
                      </Row>
                    </>
                  )}
                </>
              ) : null}

              {![JOB_STATUSES.NEW, JOB_STATUSES.CANCELLED].includes(jobStatus) ? (
                <ContactInfo isEditing={isEditing} />
              ) : null}

              {isAdmin && !jobCanceled && <ReadyToPublishSection />}

              {jobDisputed && (isSupplier || isAdmin) ? <EngangedBySection /> : null}

              {isAdmin && !jobCanceled && publishType === PUBLISH_TYPES.NETWORK ? (
                <>
                  <ComplianceCard
                    id="jobInfo.acceptTermsConditions"
                    name="jobInfo.acceptTermsConditions"
                    title="Approve Terms & Conditions"
                    disabled={!isEditing}
                    style={apply('mt-3')}
                    complianceMessage={
                      <span>
                        By publishing this job, you acknowledge and agree to abide by
                        the following{' '}
                        <a
                          href="https://platform-assets.nearbysky.com/nbs_tc.pdf"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Terms & Conditions
                        </a>
                      </span>
                    }
                  />
                  {acceptTermsConditionsError && (
                    <ErrorLabel>{acceptTermsConditionsError}</ErrorLabel>
                  )}
                </>
              ) : null}
            </>
          )}
          {jobEngaged && (
            <>
              {role !== ROLES.USER && <EngangedBySection />}
              {jobStatus === JOB_STATUSES.ENGAGED && (
                <>
                  <Header>
                    <Title id="timesheet">Timesheet</Title>
                  </Header>
                  <Row>
                    <DateField
                      disabled={
                        (role !== ROLES.USER && role !== ROLES.SUPPLIER_ADMIN) ||
                        engagedBy.id !== account.roles.pilot
                      }
                      name="jobInfo.startedAt"
                      type="text"
                      label="Started At"
                      autoOk
                      withTimePicker
                    />
                  </Row>
                  <Row>
                    <DateField
                      disabled={
                        (role !== ROLES.USER && role !== ROLES.SUPPLIER_ADMIN) ||
                        engagedBy.id !== account.roles.pilot ||
                        isStarted
                      }
                      name="jobInfo.finishedAt"
                      type="text"
                      label="Ended At"
                      placeholder={isStarted && 'Not Yet Available'}
                      autoOk
                      withTimePicker
                    />
                  </Row>
                </>
              )}
              {company && isAdmin ? (
                <Button
                  type="button"
                  style={apply('my-4')}
                  onClick={() => setIsCompanyModalOpen(true)}
                >
                  View Company
                </Button>
              ) : null}
            </>
          )}

          {jobDelivered && (
            <>
              <Row>
                <FormInput
                  disabled
                  name="jobInfo.uploadCode"
                  type="text"
                  label="upload code"
                />
              </Row>

              <TimesheetSection />
              <JobAssetsSection />
            </>
          )}
          {jobInReview && (
            <>
              <Header>
                <Title id="review">Staff Review</Title>
              </Header>
              <JobDocuments type="staff" jobReference={jobReference} />
            </>
          )}
          {(jobCompleted || jobDisputed) && (
            <>
              <Header>
                <Title id="feedback">Rating & Feedback</Title>
              </Header>
              <Row>
                <SelectField
                  name="jobInfo.ratings.communication"
                  label="Pilot Communication"
                  isDisabled={!isAdmin}
                  onChange={(selectedOption) => {
                    setFieldValue('jobInfo.ratings.communication', selectedOption.value)
                  }}
                  options={ratingsOptions}
                  placeholder="Select a score"
                  containerProps={{ style: apply('mr-3') }}
                  testId="job-ratings-communication"
                />
              </Row>
              <Row>
                <SelectField
                  name="jobInfo.ratings.mediaAndPhotoQuality"
                  label="Media & Photo Quality"
                  isDisabled={!isAdmin}
                  onChange={(selectedOption) => {
                    setFieldValue(
                      'jobInfo.ratings.mediaAndPhotoQuality',
                      selectedOption.value
                    )
                  }}
                  options={ratingsOptions}
                  placeholder="Select a score"
                  containerProps={{ style: apply('mr-3') }}
                  testId="job-ratings-mediaAndPhotoQuality"
                />
              </Row>
              <Row>
                <SelectField
                  name="jobInfo.ratings.overall"
                  label="Job Satisfaction"
                  isDisabled={!isAdmin}
                  onChange={(selectedOption) => {
                    setFieldValue('jobInfo.ratings.overall', selectedOption.value)
                  }}
                  options={ratingsOptions}
                  placeholder="Select a score"
                  containerProps={{ style: apply('mr-3') }}
                  testId="job-ratings-overall"
                />
              </Row>
              <Row>
                <TextAreaField
                  name="jobInfo.feedback"
                  label="Feedback"
                  disabled={!isAdmin}
                  containerStyle={apply('mt-4 mr-4 w-full')}
                  minRows={6}
                />
              </Row>
              {JOB_STATUSES.COMPLETED && isAdmin && showSubmitFeedbackActions ? (
                <Row>
                  <FeedbackButton
                    style={apply('mr-4')}
                    type="button"
                    onClick={async () => {
                      await onUpdateJob(inspectedJob.id, {
                        jobInfo: {
                          ratings,
                          feedback,
                        },
                      })
                      toast.success('Feedback submitted')
                    }}
                  >
                    Submit feedback
                  </FeedbackButton>
                </Row>
              ) : null}
            </>
          )}
        </Column>
      </Details>

      <ReactTooltip />

      {company && isAdmin ? (
        <CompanyModal
          isOpen={isCompanyModalOpen}
          setIsOpen={setIsCompanyModalOpen}
          companyId={company.id}
        />
      ) : null}
      <ConfirmModal
        title="Are You Sure?"
        subtitle="Are you sure to reassign this job"
        isOpen={isReassignModalOpen}
        closeModal={() => setIsReassignModalOpen(false)}
        onConfirm={() => {
          onUpdateJob(inspectedJob.id, {
            status,
            jobInfo: {},
            contactInfo: {},
          }).then((job) => {
            if (job) {
              toast.success(`Job re-assigned successfully!`)
            }
          })
          setIsReassignModalOpen(false)
        }}
      />
    </Container>
  )
}

export { JobInfo }

const PricingSummaryCard = styled.div(
  apply('p-4 rounded-lg flex flex-column', {
    backgroundColor: '#F4F6F8',
    rowGap: 16,
  })
)

const PricingSummaryRow = styled.div(
  apply('flex justify-between', { fonrSize: 13, lineHeight: '20px' })
)

function isValidDate(d) {
  return moment.isMoment(d) || (d instanceof Date && !Number.isNaN(d.getTime()))
}

function TimesheetSection() {
  const { isEditing, inspectedJob } = useJobs()
  const { setFieldValue, values } = useFormContext()

  const pilotEquipments = usePilotEquipments(
    inspectedJob?.engagedBy?.pilotCategory?.organizationId
  )

  useEffect(() => {
    if (Array.isArray(values.jobInfo.selectedEquipments)) {
      setFieldValue(
        'jobInfo.equipmentFeePerHour',
        values.jobInfo.selectedEquipments.reduce((p, c) => p + c.rate, 0)
      )
    } else {
      setFieldValue('jobInfo.equipmentFeePerHour', 0)
    }
  }, [values.jobInfo.selectedEquipments])

  const selectedEquipmentIds = useMemo(
    () => (values.jobInfo.selectedEquipments || []).map((it) => it.id),
    [values.jobInfo.selectedEquipments]
  )

  const abortController = useRef(new AbortController())
  useEffect(() => {
    // should only calculate timesheet via api if on status until delivered
    const statusNeedCalculateTimesheet = ![
      JOB_STATUSES.IN_REVIEW,
      JOB_STATUSES.COMPLETED,
    ].includes(inspectedJob.status)
    const { startedAt, finishedAt } = values.jobInfo
    if (
      isValidDate(startedAt) &&
      isValidDate(finishedAt) &&
      inspectedJob.status &&
      statusNeedCalculateTimesheet
    ) {
      abortController.current.abort('retry too early')
      abortController.current = new AbortController()
      // eslint-disable-next-line no-inner-declarations
      async function calculateTimesheet() {
        const response = await JobService.calculateTimesheet(
          {
            jobId: inspectedJob.id,
            selectedEquipmentIds,
            calloutFee: values.jobInfo.calloutFee || 0,
            startedAt,
            finishedAt,
          },
          { signal: abortController.current.signal }
        )
        setFieldValue(
          'jobInfo.jobHours',
          response?.pricing.summary.jobHours || 0,
          false
        )
        setFieldValue(
          'jobInfo.totalPilotFee',
          response?.pricing.summary.pilotFee || 0,
          false
        )
        setFieldValue(
          'jobInfo.totalEquipmentFee',
          response?.pricing.summary.equipmentFee || 0,
          false
        )
      }
      calculateTimesheet()
    }
    return () => {
      abortController.current.abort('unmount')
    }
  }, [
    values.jobInfo.startedAt,
    values.jobInfo.finishedAt,
    values.jobInfo.calloutFee,
    inspectedJob.id,
    selectedEquipmentIds,
  ])

  return (
    <>
      <Header>
        <Title id="timesheet">Timesheet</Title>
      </Header>
      <Column gap={20} style={{ marginBottom: 16 }}>
        <FormInput
          name="jobInfo.pricing.budget"
          type="number"
          min={0}
          label="Budget Cap"
          disabled={!isEditing}
        />
        <div>
          <Label>Started At</Label>
          <DateField
            disabled={!isEditing}
            name="jobInfo.startedAt"
            type="text"
            aria-label="Started At"
            withTimePicker
            rootStyle={apply('w-full', { flex: 'initial' })}
            placeholder={!isEditing ? 'Not Yet Available' : undefined}
            autoOk
          />
        </div>
        <div>
          <Label>Ended At</Label>
          <DateField
            disabled={!isEditing}
            name="jobInfo.finishedAt"
            type="text"
            aria-label="Ended At"
            withTimePicker
            rootStyle={apply('w-full', { flex: 'initial' })}
            placeholder={!isEditing ? 'Not Yet Available' : undefined}
            autoOk
          />
        </div>
        <Row style={{ margin: 0 }}>
          <FormInput
            name="jobInfo.pilotCategory.description"
            type="text"
            label="Pilot Category"
            disabled
          />
          <FormInput
            name="jobInfo.pilotCategory.rate"
            type="text"
            label="Pilot Fee / Hour"
            disabled
          />
          <FormInput name="jobInfo.jobHours" type="text" label="Hours" disabled />
          <FormInput
            name="jobInfo.totalPilotFee"
            type="text"
            label="Total Pilot Fee"
            disabled
          />
        </Row>
        <SelectField
          name="jobInfo.selectedEquipments"
          label="Equipment Used"
          onChange={(values = []) => {
            setFieldValue('jobInfo.selectedEquipments', values)
          }}
          options={pilotEquipments || []}
          placeholder="Select Equipment Used"
          isDisabled={!isEditing}
          isMulti
        />
        <Row style={{ margin: 0 }}>
          <FormInput
            name="jobInfo.equipmentFeePerHour"
            type="text"
            label="Equipment Fee / Hour"
            disabled
          />
          <FormInput name="jobInfo.jobHours" type="text" label="Hours" disabled />
          <FormInput
            name="jobInfo.totalEquipmentFee"
            type="text"
            label="Total Equipment Fee"
            disabled
          />
        </Row>
        <FormInput
          name="jobInfo.calloutFee"
          type="number"
          label="Callout Fee"
          disabled={!isEditing}
        />
        <PricingSummaryCard style={{ marginTop: 4 }}>
          <PricingSummaryRow>
            <span>Pricing Summary</span>
            <span>Total</span>
          </PricingSummaryRow>
          <div style={{ height: 1, backgroundColor: '#CCCCCC', width: '100%' }} />
          <PricingSummaryRow>
            <span>Pilot Fee</span>
            <span>{formatCurrency(values.jobInfo.totalPilotFee)}</span>
          </PricingSummaryRow>
          <PricingSummaryRow>
            <span>Equipment Fee</span>
            <span>{formatCurrency(values.jobInfo.totalEquipmentFee)}</span>
          </PricingSummaryRow>
          <PricingSummaryRow>
            <span>Callout Fee</span>
            <span>{formatCurrency(values.jobInfo.calloutFee || 0)}</span>
          </PricingSummaryRow>
          <PricingSummaryRow>
            <span>Total</span>
            <span>
              {formatCurrency(
                values.jobInfo.totalPilotFee +
                  values.jobInfo.totalEquipmentFee +
                  (values.jobInfo.calloutFee || 0)
              )}
            </span>
          </PricingSummaryRow>
        </PricingSummaryCard>
      </Column>
    </>
  )
}

function JobAssetsSection() {
  const [isInstructionModalOpen, setIsInstructionModalOpen] = useState(false)
  const fileUploaderRef = useRef(null)

  const { id } = useParams()
  const { isPilot } = useIdentity()

  const {
    reference: jobReference,
    reference,
    inspectedFolder = {},
    jobFolderList,
    onConfirmJobDocument,
    jobStatus,
  } = useJobs()

  const {
    values: {
      jobInfo,
      documents: { uploadedByPilot },
    },
  } = useFormContext()

  const { uploadCode } = jobInfo

  const readOnly = !isPilot || jobStatus === JOB_STATUSES.IN_REVIEW

  return (
    <>
      <Header>
        <Title id="assets">Job Assets</Title>
      </Header>
      <Row>
        <Column flex="0 0 24%">
          <FolderPanel readOnly={readOnly} />
        </Column>
        <Separator />
        <Column>
          <Row style={apply('justify-between')}>
            <h4 style={apply('font-extrabold mb-8')}>Upload Assets</h4>
            {!readOnly ? (
              <ButtonLink type="button" onClick={() => setIsInstructionModalOpen(true)}>
                How to upload files?
              </ButtonLink>
            ) : null}
          </Row>
          {!readOnly ? (
            <UploadPanel
              key={inspectedFolder.fullPath}
              jobId={id}
              path={inspectedFolder.id}
              reference={reference}
              uploadCode={uploadCode}
              files={uploadedByPilot}
              ref={fileUploaderRef}
              onConfirmJobDocument={onConfirmJobDocument}
            />
          ) : null}
          <JobDocuments
            type="pilot"
            jobReference={jobReference}
            folderList={jobFolderList}
            readOnly={readOnly}
          />
        </Column>
      </Row>

      <UploadInstructionModal
        isOpen={isInstructionModalOpen}
        closeModal={() => setIsInstructionModalOpen(false)}
      />
    </>
  )
}

function EngangedBySection() {
  const { companyList } = useJobs()

  const companyOptions = companyList.map(
    ({ id, company: { companyName, userId } }) => ({
      id,
      value: companyName,
      label: companyName,
      userId,
    })
  )

  return (
    <>
      <Header>
        <Title id="engaged-by">Engaged By</Title>
      </Header>
      <Row>
        <FormInput
          disabled
          name="jobInfo.engagedBy.fullName"
          type="text"
          label="Engaged By"
        />
        <FormInput disabled name="jobInfo.engagedBy.email" type="text" label="Email" />
      </Row>
      <Row>
        <FormInput
          disabled
          name="jobInfo.engagedBy.phoneNumber"
          type="text"
          label="Phone Number"
        />
        <DateField
          disabled
          name="jobInfo.engagedAt"
          type="text"
          label="Engaged At"
          autoOk
        />
      </Row>
      <Row>
        <SelectField
          name="jobInfo.pilotCompany.company.companyName"
          label="Company Name"
          options={companyOptions}
          placeholder="Select customer"
          isDisabled
          testId="job-company"
        />
      </Row>
      <Row>
        <FormInput
          name="jobInfo.pilotCompany.company.email"
          label="Company Email"
          testId="job-company-email"
          disabled
        />
        <FormInput
          name="jobInfo.pilotCompany.company.phoneNumber"
          label="Company Phone Number"
          testId="job-company-phone-number"
          disabled
        />
      </Row>
      <Row>
        <FormInput
          name="jobInfo.pilotCompany.company.type"
          label="Company Type"
          testId="job-company-type"
          disabled
        />
      </Row>
    </>
  )
}

function ReadyToPublishSection() {
  const { isNBS, pilotList, isEditing, jobStatus, supplierList } = useJobs()

  const {
    setFieldValue,
    values: {
      jobInfo: { ratings, feedback, publishedAt, ...jobInfo },
    },
  } = useFormContext()

  const { publishType } = jobInfo

  const jobPublished = [
    JOB_STATUSES.PUBLISHED,
    JOB_STATUSES.ENGAGED,
    JOB_STATUSES.DELIVERED,
    JOB_STATUSES.IN_REVIEW,
    JOB_STATUSES.IN_REVIEW_NETWORK,
    JOB_STATUSES.COMPLETED,
    JOB_STATUSES.DISPUTED,
  ].includes(jobStatus)

  const isNetworkJobQueue =
    isNBS && jobStatus === JOB_STATUSES.QUEUE && publishType === PUBLISH_TYPES.NETWORK

  const publishTypeOptions = Object.values(PUBLISH_TYPES)
    .map((publishType) => ({
      id: publishType,
      value: publishType,
      label: PUBLISH_TYPES_DISPLAY_NAMES[publishType],
    }))
    .filter(({ value }) => {
      if (!isNBS || jobPublished) return true
      return value !== PUBLISH_TYPES.NETWORK || isNetworkJobQueue
    })

  const pilotOptions = prepend(
    { id: undefined, value: undefined, label: 'All Pilot' },
    pilotList
      .filter(({ isActive }) => isActive)
      .map(({ id, userDetails }) => {
        const fullName = `${userDetails.firstName} ${userDetails.lastName}`
        return {
          id,
          value: fullName,
          label: fullName,
        }
      })
  )

  const supplierListOptions = prepend(
    { id: undefined, value: undefined, label: 'All Supplier' },
    supplierList.map(({ id, company: { companyName } }) => ({
      id,
      value: companyName,
      label: companyName,
    }))
  )

  const disablePublishType =
    !isEditing || publishedAt !== 'Unspecified' || isNetworkJobQueue

  return (
    <>
      <Header>
        <Title id="ready-to-publish">Ready To Publish</Title>
      </Header>
      <Row>
        <SelectField
          name="jobInfo.publishType"
          label="Publish Type"
          onChange={(selectedOption) => {
            setFieldValue('jobInfo.publishType', selectedOption.value)
          }}
          options={publishTypeOptions}
          placeholder="Select publish type"
          containerProps={{ style: apply('mr-3') }}
          isDisabled={disablePublishType}
          testId="job-company"
        />
        {publishType === PUBLISH_TYPES.INTERNAL && (
          <SelectField
            name="jobInfo.selectedPilot.name"
            label="Recommend Pilot"
            emptyOption={{
              id: undefined,
              value: undefined,
            }}
            onChange={({ id, value: name }) => {
              setFieldValue('jobInfo.selectedPilot', { id, name })
            }}
            options={pilotOptions}
            placeholder="Select Pilot"
            isDisabled={disablePublishType}
          />
        )}
        {publishType === PUBLISH_TYPES.SUPPLIER && (
          <SelectField
            name="jobInfo.selectedSupplier.companyName"
            label="Suppliers"
            emptyOption={{
              id: undefined,
              value: undefined,
            }}
            onChange={({ id, value: companyName }) => {
              setFieldValue('jobInfo.selectedSupplier', { id, companyName })
            }}
            options={supplierListOptions}
            placeholder="Select Supplier"
            isDisabled={disablePublishType}
          />
        )}
        {/* We hide this until it's clear what to do with this on network job flow */}
        {/* {publishType === PUBLISH_TYPES.NETWORK && (
        <SelectField
          name="jobInfo.selectedPilotCategory.name"
          label="Pilot Category"
          emptyOption={{
            id: undefined,
            value: undefined,
          }}
          onChange={({ id, value: name }) => {
            setFieldValue('jobInfo.selectedPilotCategory', { id, name })
          }}
          options={pilotCategoryOptions}
          placeholder="Select Pilot Category"
          isDisabled={disablePublishType}
          testId="job-client-name"
        />
      )} */}
      </Row>
    </>
  )
}
