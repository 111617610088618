const PILOT_STATUS = {
  ACTIVE: 'active',
  PENDING: 'pending',
}

const PILOT_APPLICATION_STATUS = {
  INCOMPLETE: 'appl_status_incomplete',
  SUBMITTED: 'appl_status_submitted',
  ACCEPTED: 'appl_status_accepted',
  REJECTED: 'appl_status_rejected',
}

const PILOT_STATUS_DISPLAY = {
  [PILOT_STATUS.ACTIVE]: 'Active',
  [PILOT_STATUS.PENDING]: 'Pending',
}

const PILOT_APPLICATION_STATUS_DISPLAY = {
  [PILOT_APPLICATION_STATUS.INCOMPLETE]: 'Pending',
  [PILOT_APPLICATION_STATUS.SUBMITTED]: 'Need To Review',
  [PILOT_APPLICATION_STATUS.ACCEPTED]: 'Accepted',
  [PILOT_APPLICATION_STATUS.REJECTED]: 'Rejected',
}

export {
  PILOT_STATUS,
  PILOT_STATUS_DISPLAY,
  PILOT_APPLICATION_STATUS,
  PILOT_APPLICATION_STATUS_DISPLAY,
}
