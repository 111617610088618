import { ServiceArea } from 'components/pilots/common/Fields/ServiceArea'
import { styled, apply } from 'lib/styled'

const Details = styled.div(apply('flex-1 flex flex-row'))
const Column = styled.div(apply('flex-1 flex flex-column'))

export function ServiceFields() {
  return (
    <Details>
      <Column style={apply('items-center w-10 mr-6', { flex: 'none' })} />
      <Column>
        <ServiceArea />
      </Column>
    </Details>
  )
}
