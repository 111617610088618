import { connect } from '@seedcloud/stateless'
import { Link } from 'react-router-dom'

import { Status } from '../Status'

import { Button } from 'components/common/Button'
import { Spinner } from 'components/common/Spinner'
import { APPLICATION_STATUSES } from 'constants/applications'
import { CanUpdatePilotApplication } from 'lib/permissions'
import { styled, apply } from 'lib/styled'
import { inspectedApplication$, getIsManagingApplication$ } from 'modules/application'

const Container = styled.section(
  apply(
    'flex flex-row items-center px-6 py-4 border-0 border-b-1 border-solid border-grey-light'
  )
)
const Row = styled.div(
  apply('flex-1 flex flex-row my-2 items-center', {
    '&:first-of-type': apply('mt-0'),
    '&:last-of-type': apply('mb-0'),
  })
)
const Label = styled.label(
  apply('m-0 mr-3 uppercase tracking-wide text-xs text-grey-dark'),
  {
    lineHeight: 1,
  }
)
const Reference = styled.span(apply('px-3 py-2 bg-grey-lighter rounded-lg text-sm'), {
  lineHeight: 1,
  paddingBottom: '9px',
})
const Actions = styled.div(apply('flex-1 flex flex-row items-center justify-end'))
const ApproveButton = styled(Button)(
  apply('mx-4 border-green-light', {
    backgroundColor: '#51d88a',
  }),
  ({ disabled }) =>
    disabled
      ? apply('bg-grey-lighter border-grey-light', { cursor: 'not-allowed' })
      : {}
)

const RejectButton = styled(Button)(
  apply('border-red-light', {
    backgroundColor: '#ef5753',
  }),
  ({ disabled }) =>
    disabled
      ? apply('bg-grey-lighter border-grey-light', { cursor: 'not-allowed' })
      : {}
)

const ConnectedGeneral = connect(({ id }) => ({
  inspectedApplication: inspectedApplication$,
  isManagingApplication: getIsManagingApplication$(id),
}))(General)

// eslint-disable-next-line complexity
function General({
  inspectedApplication,
  isManagingApplication,
  openApproveModal,
  openRejectModal,
}) {
  const { status, reference, pilotId } = inspectedApplication

  const canPerformActions =
    status !== APPLICATION_STATUSES.ACCEPTED && status !== APPLICATION_STATUSES.REJECTED

  return (
    <Container>
      <Row>
        <Label>Status</Label>
        <Status
          value={status}
          style={apply('pb-2 px-3 text-xs', { height: 24, lineHeight: '24px' })}
        />

        <Label style={apply('ml-6')}>Reference</Label>
        <Reference>{reference}</Reference>
        {pilotId && (
          <Link
            to={`/pilot/${pilotId}`}
            style={apply('m-0 ml-6 p-0 italic underline text-sm')}
          >
            View pilot&apos;s profile
          </Link>
        )}

        <CanUpdatePilotApplication>
          <Actions>
            {isManagingApplication && <Spinner size={18} thickness={3} />}

            {canPerformActions && (
              <>
                <ApproveButton
                  disabled={isManagingApplication}
                  onClick={openApproveModal}
                >
                  Approve
                </ApproveButton>

                <RejectButton
                  disabled={isManagingApplication}
                  onClick={openRejectModal}
                >
                  Reject
                </RejectButton>
              </>
            )}
          </Actions>
        </CanUpdatePilotApplication>
      </Row>
    </Container>
  )
}

export { ConnectedGeneral as General }
