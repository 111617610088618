import { clone, find, isNotNilOrEmpty, noop, propEq } from '@seedcloud/ramda-extra'
import { useField } from 'formik'
import { useMemo } from 'react'
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable'

import { ErrorMessage } from './Fields/ErrorMessage'
import { NewLabel, Label } from './Fields/Label'

import { styled, apply } from 'lib/styled'

const Root = styled.div(
  () => apply('flex-1 flex flex-column'),
  ({ newStyle }) => ({
    gap: newStyle ? '.5em' : undefined,
  })
)

const styles = (selectStyles = {}) => ({
  control: (_, { isDisabled }) =>
    apply('flex flex-row rounded-lg text-lg', {
      ...selectStyles.control,
      cursor: isDisabled ? 'not-allowed' : 'initial',
      pointerEvents: isDisabled ? 'none' : 'initial',
      backgroundColor: isDisabled ? 'rgb(243 244 246)' : 'white',
      border: isDisabled ? '1px solid rgb(243 244 246)' : '1px solid #CCCCCC',
    }),

  option: (provided) => ({
    ...provided,
    ...apply('bg-grey-lightest text-black'),
    '&:hover': apply('bg-grey-light'),
  }),

  dropdownIndicator: () => apply('p-0 px-2 relative', { top: 1 }),

  valueContainer: (provided) => ({
    ...provided,
    ...apply('px-4 py-2'),
  }),

  indicatorSeparator: () => apply('hidden'),

  indicatorsContainer: (provided, { isDisabled }) => {
    const styles = clone(provided)

    if (isDisabled) {
      styles.color = 'rgba(0, 0, 0, 0.38)'
    }

    return styles
  },

  singleValue: (provided, { isDisabled }) => ({
    ...provided,
    ...apply('text-black', isDisabled ? { color: 'rgba(0, 0, 0, 0.38)' } : {}),
  }),

  input: (provided) => ({
    ...provided,
    // We already have padding and margins specified in `valueContainer`
    // we don't want those provided by `react-select`
    margin: 0,
    paddingTop: 0,
    paddingBottom: 0,
  }),

  placeholder: (provided) => ({
    ...provided,
    ...apply('text-grey'),
  }),
})

export const newStyles = (selectStyles = {}) => ({
  control: (_, { isDisabled }) =>
    apply('flex flex-row rounded-lg', {
      ...selectStyles.control,
      cursor: isDisabled ? 'not-allowed' : 'pointer',
      pointerEvents: 'initial',
      border: '1px solid #CCCCCC',
    }),

  menu: (provided) => ({
    ...provided,
    ...apply('rounded-lg'),
  }),

  menuList: (provided) => ({
    ...provided,
    ...apply('py-0'),
  }),

  option: (provided, { isSelected }) => ({
    ...provided,
    ...apply('text-black rounded-lg'),
    backgroundColor: isSelected ? '#EEF1F5' : 'white',
    '&:hover': {
      backgroundColor: '#EEF1F5',
    },
  }),

  dropdownIndicator: () => apply('p-0 px-2 relative'),

  valueContainer: (provided) => ({
    ...provided,
    ...{
      padding: '.5rem 1rem',
      fontSize: '0.9375rem',
    },
    ...selectStyles.valueContainer,
  }),

  indicatorSeparator: () => apply('hidden'),

  indicatorsContainer: (provided, { isDisabled }) => {
    const styles = clone(provided)

    if (isDisabled) {
      styles.color = 'rgba(0, 0, 0, 0.38)'
    }

    return styles
  },

  singleValue: (provided, { isDisabled }) => ({
    ...provided,
    ...apply(
      'text-black',
      isDisabled
        ? { color: 'rgba(0, 0, 0, 0.38)' }
        : { fontSize: '0.9375rem', marginLeft: '0', marginRight: '0' }
    ),
  }),

  input: (provided) => ({
    ...provided,
    margin: 0,
    paddingTop: 0,
    paddingBottom: 0,
  }),

  placeholder: (provided) => ({
    ...provided,
    ...apply('text-grey'),
  }),
})

const LabelContainer = styled.div(apply('flex'))

const findOption = (options, value) => find(propEq('value', value), options)

// eslint-disable-next-line complexity
function SelectField({
  id,
  name,
  label,
  subLabel,
  labelIcon,
  type,
  isMulti,
  onChange = noop,
  options,
  isDisabled,
  containerProps,
  selectStyles,
  creatable = false,
  optional = false,
  testId,
  emptyOption,
  required,
  isSearchable = true,
  newStyle,
  ...props
}) {
  const [{ value }, { touched, error }, { setValue: setFieldValue, setTouched }] =
    useField({
      name,
      id,
      ...props,
    })

  if (optional && emptyOption === undefined) {
    throw new Error(
      'When using optional `SelectField`, the `emptyOption` prop must be specified. ' +
        'If you wish to use the default behaviour from `react-select`, ' +
        'manually specify `emptyOption` with either `null` or `[]`.'
    )
  }

  const handleChange = (reactSelectOption, props) => {
    const option = props?.action === 'clear' ? emptyOption : reactSelectOption

    setFieldValue(option?.value)
    setTouched(true)
    onChange(option, props)
  }

  const initialOption = isMulti ? [] : { label: value, value }

  const currentOption = useMemo(() => {
    if (isMulti) {
      return value || []
    }
    return findOption(options, value)
  }, [value, isMulti])

  const noOptionsMessage = creatable
    ? () => 'Type to create a new option'
    : () => 'No options'

  const SelectComponent = creatable ? CreatableSelect : Select

  const LabelComp = newStyle ? NewLabel : Label

  return (
    <Root {...containerProps} isDisabled={isDisabled} newStyle={newStyle}>
      {label && (
        <LabelContainer>
          <LabelComp htmlFor={testId}>
            {label}
            {required && '*'}
            {subLabel && (
              <>
                <br />
                {subLabel}
              </>
            )}
          </LabelComp>
          {labelIcon}
        </LabelContainer>
      )}
      <SelectComponent
        placeholder="Aaa"
        value={currentOption ?? initialOption}
        type={type}
        isMulti={isMulti}
        onChange={handleChange}
        options={options}
        isClearable={optional && isNotNilOrEmpty(value)}
        menuPlacement="auto"
        styles={newStyle ? newStyles(selectStyles) : styles(selectStyles)}
        isDisabled={isDisabled}
        isSearchable={isSearchable}
        classNamePrefix={testId}
        instanceId={testId}
        noOptionsMessage={noOptionsMessage}
        inputId={testId}
        onBlur={() => setTouched(true)}
        {...props}
      />
      {touched && error && <ErrorMessage>{error}</ErrorMessage>}
    </Root>
  )
}

export { SelectField }
