import { useFormikContext } from 'formik'
import { useContext } from 'react'

import { Button } from 'components/common/Button'
import {
  FormLayoutContext,
  FieldGroup,
  Row,
  TextField,
  SelectField,
} from 'components/company/common'
import { COUNTRY_LIST_APLHA_2 } from 'components/company/common/constants'
import { useIdentity } from 'lib/solta-id-react'

const Details = ({ title, values, showEditButton = true }) => {
  const { setFieldValue } = useFormikContext()
  const { role } = useIdentity()

  const { editing, setEditing } = useContext(FormLayoutContext)
  const countryOptions = Object.entries(COUNTRY_LIST_APLHA_2).map(([key, value]) => ({
    value: key,
    label: value,
  }))

  return (
    <FieldGroup
      title={title}
      subtitle="Please fill in you aviation details in order to ensure that you are approved to fly"
      extra={
        !editing && showEditButton && role !== 'user' ? (
          <Button onClick={() => setEditing(true)}>Edit</Button>
        ) : null
      }
    >
      <Row>
        <SelectField
          label="Country"
          value={values.country}
          name="country"
          options={countryOptions}
          emptyOption={{ value: undefined, label: '' }}
          onChange={({ label }) => {
            setFieldValue('country', label)
          }}
          required
        />
        <TextField
          label="Company ARN"
          value={values.companyArn}
          name="companyArn"
          required
        />
      </Row>
    </FieldGroup>
  )
}

export { Details }
