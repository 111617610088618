import { Form } from 'formik'
import { useContext } from 'react'
import { useLocation, useParams } from 'react-router-dom'

import CloudLayoutImage from 'assets/clouds_container.svg'
import { Spinner } from 'components/common/Spinner'
import { StatusBar } from 'components/common/StatusBar'
import { FormLayoutContext, Tabs } from 'components/company/common'
import { SUPPLIER_LABELS, SUPPLIER_TYPES } from 'constants/supplier'
import { styled, apply } from 'lib/styled'

const Layout = styled.div(
  apply('flex flex-1 flex-column bg-white rounded-xl shadow-sm relative'),
  {
    overflow: 'hidden',
  }
)

const LayoutForm = Layout.withComponent(Form)

const Container = styled.div({
  overflowY: 'auto',
  overflowX: 'hidden',
  height: '100%',
})

const Content = styled.div(apply('py-8 pl-6 relative'), {
  backgroundImage: `url('${CloudLayoutImage}')`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'bottom right',
  marginRight: '-24px',
  marginBottom: '-24px',
  paddingRight: 'calc(1.5em * 2)',
  minHeight: '100%',
})

const SpinnerContainer = styled.div({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
})

// eslint-disable-next-line complexity
const SupplierLayout = ({
  children,
  footer,
  hideTabs,
  loading,
  status,
  company,
  type,
}) => {
  const { editing } = useContext(FormLayoutContext)
  const { id } = useParams()
  const { search } = useLocation()

  const LayoutComp = editing ? LayoutForm : Layout

  const baseTabs = [
    {
      title: 'Company Details',
      path: `/supplier/${id}/details${search}`,
      id: 'details',
    },
    {
      title: 'Company Rate Card',
      path: `/supplier/${id}/ratecard${search}`,
      id: 'ratecard',
    },
  ]

  const tabs = [
    ...baseTabs,
    {
      title: 'Aviation Details',
      path: `/supplier/${id}/aviation${search}`,
      id: 'aviation',
    },
    // {
    //   title: 'Pilot List',
    //   path: `/supplier/${id}/pilots${search}`,
    //   id: 'pilot-list',
    // },
  ]

  return (
    <LayoutComp>
      {status && status !== SUPPLIER_LABELS.ACTIVE ? (
        <StatusBar status={status} company={company} />
      ) : null}
      {!hideTabs ? (
        <Tabs
          tabs={type?.[0] === SUPPLIER_TYPES.DATA_ANALYST[0] ? baseTabs : tabs}
          pathLength={4}
        />
      ) : null}
      <Container>
        <Content>
          {loading ? (
            <SpinnerContainer>
              <Spinner size={64} thickness={7} style={{ margin: 'auto 0' }} />
            </SpinnerContainer>
          ) : (
            children
          )}
        </Content>
      </Container>
      {footer || null}
    </LayoutComp>
  )
}

export { SupplierLayout }
