/* eslint-disable complexity */
import { reduceBy, prop, pipe, mergeRight } from '@seedcloud/ramda-extra'
import { saveAs } from 'file-saver'
import { useFormikContext as useFormContext } from 'formik'
import { useCallback, useMemo, useState } from 'react'

import { ComplianceDocumentsPanel } from './ComplianceDocumentsPanel'

import { ReactComponent as ExclamationCircleIcon } from 'assets/exclamation-circle.svg'
import { useJobs } from 'components/common/context/JobContext'
import { DeleteFileModal } from 'components/common/DeleteFileModal'
import { FormInput as FormInputBase } from 'components/common/FormInput'
import { Title as BaseTitle } from 'components/common/Tabs/ui'
import { TextAreaField } from 'components/common/TextAreaField'
import { DOCUMENT_TYPES } from 'constants/documents'
import { apply, styled } from 'lib/styled'
import { useFileModal } from 'utils/hooks'

const Header = styled.div(apply('flex flex-row pt-10 pb-4 items-center'))
const Title = styled(BaseTitle)(apply('mr-1'))
const Row = styled.div(
  apply('flex-1 flex flex-row flex-wrap my-2', {
    '&:first-of-type': apply('mt-0'),
    '&:last-of-type': apply('mb-0'),
  })
)

const FormInput = styled(FormInputBase)({
  width: '100%',
  margin: '0 0 1rem 0',
})

function ComplianceSection({ disabled }) {
  const {
    isAdmin,
    onUploadJobDocument,
    onDownloadJobDocument,
    onDeleteJobDocument,
    organizationDocumentList,
    isOperatingFile,
  } = useJobs()

  const [deleteFileModal, setDeleteFileModal] = useFileModal()
  const { setFieldError } = useFormContext()
  const [operatingFile, setOperatingFile] = useState(null)
  const [multiOperatingDocumentType, setMultiOperatingDocumentType] = useState(null)

  const complianceDocuments = useMemo(
    () =>
      pipe(
        reduceBy((acc, doc) => acc.concat(doc), [], prop('documentType')),
        mergeRight({
          [DOCUMENT_TYPES.JOB_JSEA]: [],
          [DOCUMENT_TYPES.JOB_TERMS]: [],
          [DOCUMENT_TYPES.JOB_CASA]: [],
        })
      )(organizationDocumentList),
    [organizationDocumentList]
  )

  const [docType, setDocType] = useState('')

  const onUpload = useCallback(
    (documentType) => async (files) => {
      setMultiOperatingDocumentType(documentType)
      setDocType(documentType)
      await Promise.all(files.map((file) => onUploadJobDocument(file, documentType)))
      setMultiOperatingDocumentType(null)
    },
    [setMultiOperatingDocumentType, setDocType, onUploadJobDocument]
  )

  const onDelete = useCallback(
    ({ id: selectedDocId, fileName }) => {
      setDeleteFileModal({ isOpened: true, fileName, fileId: selectedDocId })
    },
    [setDeleteFileModal]
  )

  const onDownload = useCallback(
    async ({ id: selectedDocId, fileName }) => {
      setOperatingFile(selectedDocId)
      saveAs(await onDownloadJobDocument(selectedDocId), fileName)
      setOperatingFile(null)
    },
    [setOperatingFile, onDownloadJobDocument]
  )

  const onMultiDownload = useCallback(
    async (documents, fileType) => {
      setMultiOperatingDocumentType(fileType)
      const files = await Promise.all(
        documents.map(async ({ id, fileName }) => {
          const data = await onDownloadJobDocument(id)
          return {
            fileName,
            data,
          }
        })
      )
      await Promise.all(files.map((file) => saveAs(file.data, file.fileName)))
      setMultiOperatingDocumentType(null)
    },
    [onDownloadJobDocument]
  )

  const onRejected = useCallback(
    (fieldName, errorMsg) => setFieldError(fieldName, errorMsg),
    [setFieldError]
  )

  return (
    <>
      <Header>
        <Title data-tabs="files" id="files">
          Job Details
        </Title>
        <span>
          <ExclamationCircleIcon
            {...apply('h-1 w-1', {
              stroke: '#334155',
            })}
            data-tip="Compliance documents are only editable before a job is published. Please create a new job if you wish to do so."
            data-type="info"
          />
        </span>
      </Header>
      <Row style={apply('justify-between')}>
        <TextAreaField
          disabled={disabled}
          name="jobInfo.description"
          label="description"
          containerStyle={apply(
            `flex-grow-4 pr-10 h-12 my-2 ${isAdmin ? 'w-1/2' : 'w-full'}`
          )}
          minRows={6}
        />
        {isAdmin && (
          <ComplianceDocumentsPanel
            documents={complianceDocuments[DOCUMENT_TYPES.JOB_JSEA]}
            containerStyle={apply('flex-grow-1 w-1/2 pr-10 my-2')}
            fileType="Staff Job Materials"
            name={`jobInfo.complianceDocuments.${DOCUMENT_TYPES.JOB_JSEA}`}
            documentType={DOCUMENT_TYPES.JOB_JSEA}
            onUpload={onUpload(DOCUMENT_TYPES.JOB_JSEA)}
            onDelete={onDelete}
            onDownload={onDownload}
            onMultiDownload={onMultiDownload}
            onRejected={onRejected}
            operatingFile={operatingFile}
            multiOperatingDocumentType={multiOperatingDocumentType}
            isLoading={
              isOperatingFile && docType.localeCompare(DOCUMENT_TYPES.JOB_JSEA) === 0
            }
            testId="job-jsea-panel"
            canUpload={!disabled && isAdmin}
            canDelete={!disabled}
            isAdmin={isAdmin}
          />
        )}
        {isAdmin && (
          <>
            <div style={apply('flex-grow-1 w-1/2 pr-10 my-2')}>
              <FormInput
                disabled={disabled}
                name="jobInfo.internalProjectId"
                type="text"
                label="Internal Project Id"
              />
            </div>

            <ComplianceDocumentsPanel
              documents={complianceDocuments[DOCUMENT_TYPES.JOB_CASA]}
              containerStyle={apply('flex-grow-1 w-1/2 pr-10 my-2')}
              fileType="Regulation Documents"
              name={`jobInfo.complianceDocuments.${DOCUMENT_TYPES.JOB_CASA}`}
              documentType={DOCUMENT_TYPES.JOB_CASA}
              onUpload={onUpload(DOCUMENT_TYPES.JOB_CASA)}
              onDelete={onDelete}
              onDownload={onDownload}
              onMultiDownload={onMultiDownload}
              onRejected={onRejected}
              operatingFile={operatingFile}
              multiOperatingDocumentType={multiOperatingDocumentType}
              isLoading={
                isOperatingFile && docType.localeCompare(DOCUMENT_TYPES.JOB_CASA) === 0
              }
              testId="job-casa-panel"
              canUpload={!disabled}
              canDelete={!disabled}
              isAdmin={isAdmin}
            />
          </>
        )}
        <TextAreaField
          disabled={disabled}
          name="jobInfo.instructions"
          label="Instructions"
          containerStyle={apply('w-1/2 pr-10 h-12 my-2')}
          minRows={6}
        />
        <ComplianceDocumentsPanel
          documents={complianceDocuments[DOCUMENT_TYPES.JOB_TERMS]}
          containerStyle={apply('flex-grow-1 w-1/2 pr-10 my-2')}
          fileType="Pilot Attachments"
          name={`jobInfo.complianceDocuments.${DOCUMENT_TYPES.JOB_TERMS}`}
          documentType={DOCUMENT_TYPES.JOB_TERMS}
          onUpload={onUpload(DOCUMENT_TYPES.JOB_TERMS)}
          onDelete={onDelete}
          onDownload={onDownload}
          onMultiDownload={onMultiDownload}
          onRejected={onRejected}
          operatingFile={operatingFile}
          multiOperatingDocumentType={multiOperatingDocumentType}
          isLoading={
            isOperatingFile && docType.localeCompare(DOCUMENT_TYPES.JOB_TERMS) === 0
          }
          testId="job-terms-panel"
          canUpload={!disabled}
          canDelete={!disabled}
          isAdmin={isAdmin}
        />
      </Row>
      <DeleteFileModal
        isOpen={deleteFileModal.isOpened}
        setIsOpen={(open) => setDeleteFileModal({ isOpened: open })}
        fileName={deleteFileModal.fileName}
        deleteHandler={async () => {
          setOperatingFile(deleteFileModal.fileId)
          await onDeleteJobDocument(deleteFileModal.fileId)
          setOperatingFile(null)
        }}
      />
    </>
  )
}

export { ComplianceSection }
