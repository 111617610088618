import { isNotNilOrEmpty, isNotNil } from '@seedcloud/ramda-extra'
import { Formik as FormProvider, Form } from 'formik'

import { FormInput as FormInputBase } from 'components/common/FormInput'
import { CircledMarkerLayer, Map } from 'components/common/Map'
import { ServiceCategory } from 'components/common/ServiceCategory'
import { FieldGroup } from 'components/company/common'
import { styled, apply } from 'lib/styled'

const Container = styled.section()
const Details = styled(Form)(apply('flex-1 flex flex-row'))
const Column = styled.div(apply('flex-1 flex flex-column'))
const Row = styled.div(
  apply('flex flex-row my-2', {
    flex: 'auto',
    '&:first-of-type': apply('mt-0'),
    '&:last-of-type': apply('mb-0'),
  })
)
const FormInput = styled(FormInputBase)({
  '&:first-of-type': apply('ml-0'),
  '&:last-of-type': apply('mr-0'),
  margin: '0 2rem 0 0',
})

const TextLabel = styled.span({
  fontSize: '0.8125rem',
  color: '#555555',
  fontWeight: 400,
  margin: '0 0 .5rem 0',
})

// eslint-disable-next-line complexity
function ServiceArea({ address, serviceRadius, serviceCategories, coordinates }) {
  return (
    <Container>
      <FormProvider
        enableReinitialize
        initialValues={{
          address,
          serviceRadius: isNotNil(serviceRadius)
            ? `${serviceRadius} km`
            : 'Unspecified',
          serviceCategories,
        }}
      >
        <FieldGroup title="Service">
          <Details>
            <Column>
              <Column>
                <TextLabel>Service Categories</TextLabel>
                <Row>
                  <ServiceCategory
                    name="serviceCategories"
                    style={apply('px-3 py-2 pb-3 bg-grey-lighter rounded-lg')}
                  />
                </Row>
              </Column>
              <Row>
                <FormInput
                  disabled
                  name="address"
                  type="text"
                  label="Service Location"
                  containerProps={{ style: { flexGrow: 4 } }}
                  newStyle
                />

                <FormInput
                  disabled
                  name="serviceRadius"
                  type="text"
                  label="Survey radius"
                  newStyle
                />
              </Row>
              <Row style={apply('flex-column')}>
                <Map
                  centerCoords={coordinates ?? [0, 0]}
                  style="mapbox://styles/mapbox/streets-v9"
                  containerStyle={apply(
                    'rounded-lg border-0 border-b-2 border-solid border-grey',
                    { height: '60vh' }
                  )}
                  zoom={{ 50: [8], 100: [7], 150: [6], 200: [6] }[serviceRadius]}
                  type="circled-marker"
                >
                  <CircledMarkerLayer
                    units="kilometres"
                    markerCoords={coordinates}
                    radius={serviceRadius}
                    visible={isNotNilOrEmpty(coordinates)}
                  />
                </Map>
              </Row>
            </Column>
          </Details>
        </FieldGroup>
      </FormProvider>
    </Container>
  )
}

export { ServiceArea }
