import { useEffect, useRef, useState } from 'react'

import { PilotCategoryAdminService } from 'modules/pilotCategory/admin/service'
import { formatCurrency } from 'utils/formatCurrency'

export function usePilotEquipments(organizationId) {
  const abortControllerRef = useRef(new AbortController())
  const [pilotEquipments, setPilotEquipments] = useState([])

  useEffect(() => {
    if (organizationId) {
      abortControllerRef.current.abort('too many request')
      abortControllerRef.current = new AbortController()
      PilotCategoryAdminService.getAllPilotEquipments(
        { organizationId },
        { signal: abortControllerRef.current.signal }
      ).then((response) => {
        setPilotEquipments(
          (response?.items || []).map((it) => ({
            ...it,
            value: it.id,
            label: `${it.description} ${formatCurrency(it.rate)} / hr`,
          }))
        )
      })
    }
    return () => {
      abortControllerRef.current.abort('unmount')
    }
  }, [organizationId])

  return pilotEquipments
}
