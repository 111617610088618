/* eslint-disable complexity */

import { isNilOrEmpty } from '@seedcloud/ramda-extra'
import { useFormikContext as useFormContext } from 'formik'
import { useState } from 'react'

import { Button as ButtonBase } from 'components/common/Button'
import { ConfirmModal } from 'components/common/ConfirmModal/ConfirmModal'
import { useJobs } from 'components/common/context/JobContext'
import { TextAreaField } from 'components/common/TextAreaField'
import { WithComplianceCheckbox } from 'components/jobs/common/WithComplianceCheckbox'
import { JOB_STATUSES } from 'constants/jobs'
import { PUBLISH_TYPES } from 'constants/publishType'
import { ROLES } from 'constants/roles'
import { useIdentity } from 'lib/solta-id-react'
import { apply, styled } from 'lib/styled'
import toast from 'lib/toaster'

const btnStyle = apply('py-3 px-6 text-lg flex items-center justify-center')

const Button = styled(ButtonBase)(btnStyle)

const ActionContainer = styled.div(apply('my-8 flex'))
const ActionNoMarginContainer = styled.div(apply('flex flex-grow-1'))
const AcceptButton = styled(Button)(
  apply('mr-4 border-green-light', {
    backgroundColor: '#009F4D',
  }),
  ({ disabled }) =>
    disabled
      ? apply('bg-grey-lighter border-grey-light', { cursor: 'not-allowed' })
      : {}
)

const RejectButton = styled(Button)(
  apply('border-red-light', {
    backgroundColor: '#D42C16',
  }),
  ({ disabled }) =>
    disabled
      ? apply('bg-grey-lighter border-grey-light', { cursor: 'not-allowed' })
      : {}
)

const ComplianceMessage = styled.div(apply('flex'), { gap: '.5rem' })

const toastStatusChanges = (status) =>
  toast.success(`Job successfully moved to state ${status}`)

const toastDataChanges = (data) =>
  toast.success(`Job successfully updated for ${data} data`)

function Actions() {
  const [showModal, setShowModal] = useState(false)
  const { role, account, isPilot } = useIdentity()
  const { values } = useFormContext()

  const supplierPilotId = account.roles.pilot
  const selectedPilotId =
    values.jobInfo.selectedPilot?.id || values.jobInfo.engagedBy?.id

  const {
    inspectedJob,
    onAcceptJob,
    onRejectJob,
    onPublishJob,
    onFinalizeJob,
    onUpdateJob,
    onEngagedJob,
    onUpdateEngagedJob,
    onSupplierAcceptJob,
    isAdmin,
    // showSubmitFeedbackActions,
    showPublishToActions,
    isNBS,
    initialValues,
  } = useJobs()
  const isStarted = initialValues.jobInfo.startedAt === 'Unspecified'

  const {
    jobInfo: { rejectionReason, acceptTermsConditions, publishType, disputeReason },
    documents: { uploadedByPilot },
  } = values

  const renderActionButton = (buttonText, modalSubtitle, newStatus, toastMessage) => (
    <>
      <ActionContainer>
        <Button
          type="button"
          onClick={() => {
            if (buttonText === 'Assign Job' && !values.jobInfo.selectedPilot.id) {
              toast.error(
                'Please select a pilot to assign the job to before proceeding.'
              )
            } else {
              setShowModal(true)
            }
          }}
        >
          {buttonText}
        </Button>
      </ActionContainer>
      <ConfirmModal
        title="Confirmation"
        subtitle={modalSubtitle}
        onConfirm={() => {
          if (buttonText === 'Engage Job') {
            onEngagedJob(inspectedJob.id, {
              status: newStatus,
              jobInfo: values.jobInfo,
              contactInfo: {},
            }).then(() => toastStatusChanges(toastMessage))
          } else {
            onUpdateJob(inspectedJob.id, {
              status: newStatus,
              jobInfo: values.jobInfo,
              contactInfo: {},
            }).then(() => toastStatusChanges(toastMessage))
          }
          setShowModal(false)
        }}
        isOpen={showModal}
        closeModal={() => setShowModal(false)}
      />
    </>
  )

  if (role === ROLES.STAFF) return null

  switch (inspectedJob.status) {
    case JOB_STATUSES.NEW:
      return (
        <WithComplianceCheckbox
          title="Approve PaaS Agreements"
          key="compliance-new"
          hideCompliance
          complianceMessage={
            <ComplianceMessage>
              By accepting this job, you acknowledge and agree to abide by the following{' '}
              <a
                className="tnc-link"
                href="https://platform-assets.nearbysky.com/nbs_tc.pdf"
                target="_blank"
                rel="noreferrer"
              >
                PaaS agreements
              </a>
            </ComplianceMessage>
          }
          onSubmit={() =>
            onAcceptJob(inspectedJob.id).then(() => toastStatusChanges('queue'))
          }
        >
          {({ submit }) => (
            <ActionNoMarginContainer>
              {isNilOrEmpty(inspectedJob.acceptedBy) &&
                isNilOrEmpty(inspectedJob.rejectedBy) && (
                  <>
                    <AcceptButton type="button" onClick={submit}>
                      Accept
                    </AcceptButton>
                    <RejectButton
                      type="button"
                      onClick={() =>
                        onRejectJob(inspectedJob.id, { rejectionReason }).then(() =>
                          toastStatusChanges('cancelled')
                        )
                      }
                    >
                      Reject
                    </RejectButton>
                    <TextAreaField
                      name="jobInfo.rejectionReason"
                      containerStyle={apply('mx-4 w-full')}
                      minRows={1}
                      defaultValue="Please specify your reason for rejecting this job..."
                      noLabel
                      resize={'none'}
                    />
                  </>
                )}
            </ActionNoMarginContainer>
          )}
        </WithComplianceCheckbox>
      )
    case JOB_STATUSES.QUEUE: {
      const getButtonText = (publishType, isNBS) => {
        if (publishType === PUBLISH_TYPES.NETWORK) {
          return isNBS ? 'Publish to Network' : 'Proceed to Network'
        }
        return 'Publish'
      }
      return (
        showPublishToActions && (
          <ActionContainer>
            <Button
              type="button"
              style={apply('mr-4')}
              onClick={() => {
                if (
                  isNilOrEmpty(acceptTermsConditions) &&
                  publishType === PUBLISH_TYPES.NETWORK
                ) {
                  toast.error('The terms and conditions must be accepted.')
                } else {
                  onPublishJob(inspectedJob.id, {
                    jobInfo: {
                      ...values.jobInfo,
                      selectedAllPilot:
                        values.jobInfo.publishType === 'internal' &&
                        !isNilOrEmpty(values.jobInfo.selectedPilot),
                      selectedAllSupplier:
                        values.jobInfo.publishType === 'supplier' &&
                        !isNilOrEmpty(values.jobInfo.selectedSupplier),
                    },
                    contactInfo: values.contactInfo,
                  }).then(() => toastStatusChanges('published'))
                }
              }}
            >
              {getButtonText(publishType, isNBS)}
            </Button>
          </ActionContainer>
        )
      )
    }

    case JOB_STATUSES.PUBLISHED:
      return (
        <>
          <ActionContainer>
            <Button type="button" onClick={() => setShowModal(true)}>
              {isAdmin ? 'Unpublish Job' : 'Accept Job'}
            </Button>
          </ActionContainer>
          {isAdmin ? (
            <ConfirmModal
              title="Confirmation"
              subtitle="Are you sure you want to unpublish this job? This job will be moved to the In Queue job state."
              onConfirm={() => {
                onUpdateJob(inspectedJob.id, {
                  status: JOB_STATUSES.QUEUE,
                  jobInfo: {},
                  contactInfo: {},
                }).then(() => toastStatusChanges('queued'))
                setShowModal(false)
              }}
              isOpen={showModal}
              closeModal={() => setShowModal(false)}
            />
          ) : (
            <ConfirmModal
              title="Confirmation"
              subtitle="Are you sure you want to accept this job? This job will be listed in the Accepted job state, and will need to be  assigned to a pilot within 3 days before the job's Scheduled At date."
              onConfirm={() => {
                onSupplierAcceptJob(inspectedJob.id, {
                  status: JOB_STATUSES.ACCEPTED,
                  jobInfo: {},
                  contactInfo: {},
                }).then(() => toastStatusChanges('accepted'))
                setShowModal(false)
              }}
              isOpen={showModal}
              closeModal={() => setShowModal(false)}
            />
          )}
        </>
      )

    case JOB_STATUSES.ACCEPTED:
      switch (role) {
        case ROLES.PLATFORM_ADMIN:
          return renderActionButton(
            'Unpublish Job',
            'Are you sure you want to unpublish this job? This job will be moved to the In Queue job state.',
            JOB_STATUSES.QUEUE,
            'queued'
          )
        case ROLES.SUPPLIER_ADMIN:
          if (supplierPilotId === selectedPilotId) {
            return renderActionButton(
              'Engage Job',
              'Are you sure you want to engage in this job? This job will be move to the Engaged job state.',
              JOB_STATUSES.ENGAGED,
              'engaged'
            )
          }
          return renderActionButton(
            'Assign Job',
            "Are you sure you want to assign this job to a Pilot? This job will be listed in the Pilot's Assigned job state. The Pilot must engage with the job within 1 day before the job's Scheduled At date.",
            JOB_STATUSES.ACCEPTED,
            'assigned'
          )
        default:
          return renderActionButton(
            'Engage Job',
            'Are you sure you want to engage in this job? This job will be move to the Engaged job state.',
            JOB_STATUSES.ENGAGED,
            'engaged'
          )
      }

    case JOB_STATUSES.ENGAGED:
      return isPilot ? (
        <>
          {isStarted &&
            (supplierPilotId === selectedPilotId || role === ROLES.USER) && (
              <>
                <ActionContainer>
                  <Button type="button" onClick={() => setShowModal(true)}>
                    Start Job
                  </Button>
                </ActionContainer>
                <ConfirmModal
                  title="Confirmation"
                  subtitle='Are you sure you want to start this job? Please ensure that the time and date you entered in the "Started At" field is correct.'
                  onConfirm={() => {
                    onUpdateEngagedJob(inspectedJob.id, {
                      jobInfo: values.jobInfo,
                      action: 'start',
                    }).then(() => toastDataChanges('started at'))
                    setShowModal(false)
                  }}
                  isOpen={showModal}
                  closeModal={() => setShowModal(false)}
                />
              </>
            )}
          {!isStarted &&
            (supplierPilotId === selectedPilotId || role === ROLES.USER) && (
              <>
                <ActionContainer>
                  <Button type="button" onClick={() => setShowModal(true)}>
                    End Job
                  </Button>
                </ActionContainer>
                <ConfirmModal
                  title="Confirmation"
                  subtitle='Are you sure you want to finish this job? Please ensure that the time and date you entered in the "Ended At" field is correct.'
                  onConfirm={() => {
                    onUpdateEngagedJob(inspectedJob.id, {
                      jobInfo: values.jobInfo,
                      action: 'finish',
                    }).then(() => toastStatusChanges('delivered'))
                    setShowModal(false)
                  }}
                  isOpen={showModal}
                  closeModal={() => setShowModal(false)}
                />
              </>
            )}
        </>
      ) : null
    case JOB_STATUSES.DELIVERED:
      return !isAdmin ? (
        <WithComplianceCheckbox
          key="compliance-delivered"
          title="Approve PaaS SLA Agreements"
          hideCompliance
          complianceMessage={
            <ComplianceMessage>
              By delivering this job, you acknowledge and agree to abide by the
              following{' '}
              <a
                className="tnc-link"
                href="https://platform-assets.nearbysky.com/nbs_tc.pdf"
                target="_blank"
                rel="noreferrer"
              >
                PaaS SLA agreements
              </a>
            </ComplianceMessage>
          }
          onSubmit={async () => {
            if (uploadedByPilot.length === 0) {
              toast.error(
                'Please upload the deliverables in Job Assets before submit for review'
              )
              return
            }
            await onUpdateJob(inspectedJob.id, {
              status: JOB_STATUSES.IN_REVIEW,
              jobInfo: values.jobInfo,
              fetchEngaged: true,
            }).then(() => toastStatusChanges('in review'))
          }}
        >
          {({ submit }) => (
            <ActionNoMarginContainer>
              <Button type="button" onClick={submit}>
                Submit for review
              </Button>
            </ActionNoMarginContainer>
          )}
        </WithComplianceCheckbox>
      ) : null
    case JOB_STATUSES.IN_REVIEW_NETWORK:
    case JOB_STATUSES.IN_REVIEW:
      if (
        isNBS &&
        publishType === PUBLISH_TYPES.NETWORK &&
        inspectedJob.status === JOB_STATUSES.IN_REVIEW_NETWORK
      ) {
        return null
      }
      return isAdmin ? (
        <ActionContainer>
          <Button
            style={apply('mr-4')}
            type="button"
            onClick={() =>
              onFinalizeJob({ inviteOnly: false }).then(() =>
                toastStatusChanges('completed')
              )
            }
          >
            Complete
          </Button>
          <RejectButton
            type="button"
            onClick={() =>
              onUpdateJob(inspectedJob.id, {
                status: JOB_STATUSES.DISPUTED,
                jobInfo: {},
                contactInfo: {},
                disputeReason,
              }).then(() => toastStatusChanges('disputed'))
            }
          >
            Dispute
          </RejectButton>
          <TextAreaField
            placeholder="Please specify your reason for disputing this job..."
            name="jobInfo.disputeReason"
            containerStyle={apply('mx-4 w-full')}
            minRows={1}
            noLabel
            resize={'none'}
          />
        </ActionContainer>
      ) : null
    // case JOB_STATUSES.COMPLETED:
    //   return isAdmin && showSubmitFeedbackActions ? (
    //     <ActionContainer>
    //       <Button
    //         style={apply('mr-4')}
    //         type="button"
    //         onClick={() => {
    //           onFinalizeJob({ inviteOnly: true })
    //         }}
    //       >
    //         Send email
    //       </Button>
    //     </ActionContainer>
    //   ) : null
    default:
      return null
  }
}

export { Actions }
