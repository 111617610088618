import { useContext } from 'react'

import {
  FormLayoutContext,
  DateField,
  FieldGroup,
  Row,
  SelectField,
  Separator,
  TextField,
} from '../../common'

import { DynamicFields } from 'components/common/Fields/DynamicFields'
import { LICENCE_TYPE_DISPLAY_NAMES } from 'constants/licenceTypes'
import { formatDate } from 'utils/formatDate'
import { getFieldArrayName } from 'utils/getFieldArrayName'

const licenceOptions = Object.entries(LICENCE_TYPE_DISPLAY_NAMES).map(
  ([key, value]) => ({ value: key, label: value })
)

const License = ({ values, name, title }) => {
  const { editing } = useContext(FormLayoutContext)

  return (
    <FieldGroup title={title}>
      <DynamicFields
        name={name}
        values={values}
        emptyValues={{
          licenseType: '',
          licenseNumber: '',
          licenseIssuedAt: '',
          licenseExpiredAt: '',
        }}
        editing={editing}
        addButtonText="Add Licence"
      >
        {({ licenseType, licenseNumber, licenseIssuedAt, licenseExpiredAt }, i) => (
          <>
            <Row>
              <SelectField
                label="Licence Type"
                value={LICENCE_TYPE_DISPLAY_NAMES[licenseType]}
                name={getFieldArrayName(name, i, 'licenseType')}
                options={licenceOptions}
                isSearchable={false}
              />
              <TextField
                label="Licence Number"
                value={licenseNumber}
                name={getFieldArrayName(name, i, 'licenseNumber')}
              />
            </Row>
            <Row>
              <DateField
                label="Issue Date"
                value={licenseIssuedAt ? formatDate(licenseIssuedAt) : ''}
                name={getFieldArrayName(name, i, 'licenseIssuedAt')}
              />
              <DateField
                label="Expiry Date"
                value={licenseExpiredAt ? formatDate(licenseExpiredAt) : ''}
                name={getFieldArrayName(name, i, 'licenseExpiredAt')}
              />
            </Row>
            <Separator />
          </>
        )}
      </DynamicFields>
    </FieldGroup>
  )
}

export { License }
