import { useFormikContext } from 'formik'

import { Row } from 'components/company/common'
import { EquipmentForm } from 'components/pilots/common/Fields/Equipment'
import { styled, apply } from 'lib/styled'

const Details = styled.div(apply('flex-1 flex flex-row'))
const Column = styled.div(apply('flex-1 flex flex-column'))

export function EquipmentFormFields() {
  const { values } = useFormikContext()
  return (
    <Details>
      <Column style={apply('items-center w-10 mr-6', { flex: 'none' })} />
      <Column>
        <Row>
          <EquipmentForm
            name="equipments"
            title="Equipment Form"
            values={values.equipments}
          />
        </Row>
      </Column>
    </Details>
  )
}
