import { pick, path, omitBy, assoc, pathOr } from '@seedcloud/ramda-extra'
import { createModule } from '@seedcloud/stateless'

import { JobService } from './service'

import toast from 'lib/toaster'
import { calcNext } from 'utils/calcNext'
import { calcStartRow } from 'utils/calcStartRow'
import { withTryCatch } from 'utils/withTryCatch'

const INITIAL_STATE = Object.freeze({
  entities: {},
  order: [],
  inspectedEntity: undefined,
  selectedTab: 'new',
  filterQuery: undefined,
  statuses: [],
  status: '',
  sortBy: undefined,
  filterBy: undefined,
  sortOrder: undefined,
  limit: 15,
  from: undefined,
  to: undefined,
  project: undefined,
  publishType: undefined,
  paging: {
    startRow: undefined,
    next: undefined,
  },
})

const omitUnspecifiedDate = (dateObj) =>
  omitBy((date) => date === 'Unspecified', dateObj)

const setFilterParams = (module) => (_, params) => {
  const updateParams = pick(
    [
      'filterQuery',
      'statuses',
      'status',
      'paging',
      'sortBy',
      'filterBy',
      'sortOrder',
      'limit',
      'from',
      'to',
      'project',
      'publishType',
    ],
    params
  )
  module.setState(updateParams)
}

const setSelectedTab = (module) => (_, tab) => {
  module.setState({ selectedTab: tab })
}

const engageJob = (module, { setError }) =>
  withTryCatch(
    async () => {
      const { inspectedEntity: id } = module.getState()
      await JobService.engage(id)

      module.inspectEngagedJob(id)
    },
    { errHandler: setError }
  )

const fetchEngagedJobs = (module, { setError }) =>
  withTryCatch(
    async (_, { status, turnPage = true, turnNext }) => {
      const { paging, limit, filterQuery, from, to, sortBy, filterBy, sortOrder } =
        module.getState()
      const next = calcNext(turnPage, turnNext, paging, limit)

      const {
        entities,
        order,
        next: newNext,
        sortBy: newSortBy,
        filterBy: newFilterBy,
        sortOrder: newSortOrder,
      } = await JobService.listEngaged({
        status,
        limit,
        next,
        query: filterQuery,
        from,
        to,
        sortBy,
        filterBy,
        sortOrder,
      })

      module.setState({
        status,
        entities,
        order,
        paging: {
          startRow: calcStartRow(newNext, limit, paging),
          next: newNext,
        },
        sortBy: newSortBy,
        filterBy: newFilterBy,
        sortOrder: newSortOrder,
      })
    },
    { errHandler: setError }
  )

const fetchJobs = (module, { setError }) =>
  withTryCatch(
    async (_, { fetchAvailable = false, turnPage = true, turnNext }) => {
      const {
        paging,
        filterQuery,
        statuses,
        sortBy,
        filterBy,
        sortOrder,
        limit,
        from,
        to,
        project,
        publishType,
      } = module.getState()
      const next = calcNext(turnPage, turnNext, paging, limit)

      const fetchFn = !fetchAvailable ? JobService.list : JobService.listAvailable

      const {
        entities,
        order,
        next: newNext,
        sortBy: newSortBy,
        filterBy: newFilterBy,
        sortOrder: newSortOrder,
      } = await fetchFn({
        limit,
        query: filterQuery,
        statuses,
        next,
        sortBy,
        filterBy,
        sortOrder,
        from,
        to,
        project,
        publishType,
      })

      module.setState({
        entities,
        order,
        filterQuery,
        statuses,
        paging: {
          startRow: calcStartRow(newNext, limit, paging),
          next: newNext,
        },
        sortBy: newSortBy,
        filterBy: newFilterBy,
        sortOrder: newSortOrder,
      })
    },
    { errHandler: setError }
  )

const setInspectedEntity = (module, { setError }) =>
  withTryCatch(
    async (id) => {
      module.setState({
        inspectedEntity: id,
      })
    },
    { errHandler: setError }
  )

const inspectJob = (module, { setError }) =>
  withTryCatch(
    async (id) => {
      const newEntity = await JobService.read(id)

      // the default merge function is `mergeDeepRight`, which will not delete properties already existing on the left side. This means that if the updated `item` contains fields that have been unset, it won't be updated correctly in the state, hence the need to specify a custom merge function that replaces rather than merging the `old` item with the `new` one
      // Although we don't support unsetting fields atm, its still better to use server-side data as source of truth
      module.setState(newEntity, (state, newEntity) => ({
        ...state,
        inspectedEntity: id,
        entities: assoc(id, newEntity, state.entities),
      }))
    },
    { errHandler: setError }
  )

const inspectAvailableJob = (module, { setError }) =>
  withTryCatch(
    async (id) => {
      const newEntity = await JobService.readAvailable(id)

      // the default merge function is `mergeDeepRight`, which will not delete properties already existing on the left side. This means that if the updated `item` contains fields that have been unset, it won't be updated correctly in the state, hence the need to specify a custom merge function that replaces rather than merging the `old` item with the `new` one
      // Although we don't support unsetting fields atm, its still better to use server-side data as source of truth
      module.setState(newEntity, (state, newEntity) => ({
        ...state,
        inspectedEntity: id,
        entities: assoc(id, newEntity, state.entities),
      }))
    },
    { errHandler: setError }
  )

const inspectEngagedJob = (module, { setError }) =>
  withTryCatch(
    async (id) => {
      const newEntity = await JobService.readEngaged(id)

      // the default merge function is `mergeDeepRight`, which will not delete properties already existing on the left side. This means that if the updated `item` contains fields that have been unset, it won't be updated correctly in the state, hence the need to specify a custom merge function that replaces rather than merging the `old` item with the `new` one
      // Although we don't support unsetting fields atm, its still better to use server-side data as source of truth
      module.setState(newEntity, (state, newEntity) => ({
        ...state,
        inspectedEntity: id,
        entities: assoc(id, newEntity, state.entities),
      }))
    },
    { errHandler: setError }
  )

const filterJobs = (module) => (_, filters) => {
  module.setFilterParams(null, filters)

  module.fetchJobs(null, { turnPage: false })
}

const filterEngagedJobs = (module) => (_, filters) => {
  const { status, sortBy, filterBy } = filters
  module.setFilterParams(null, filters)
  const moduleState = module.getState()

  // because 'status' is not attached on searchbar filter system, we need to get 'status' on module.getState
  const statusDecision = status ?? moduleState.status
  module.fetchEngagedJobs(null, {
    status: statusDecision,
    turnPage: false,
    sortBy,
    filterBy,
  })
}

const filterAvailableJobs = (module) => (_, filters) => {
  const { sortBy, filterBy } = filters
  module.setFilterParams(null, filters)
  module.fetchJobs(null, { fetchAvailable: true, turnPage: false, sortBy, filterBy })
}

const publishJob = (module, { setError }) =>
  withTryCatch(
    async (id, { jobInfo, contactInfo }) => {
      const productId = path(['product', 'id'], jobInfo)
      const projectId = path(['project', 'id'], jobInfo)
      const companyId = path(['company', 'id'], jobInfo)
      const clientId = path(['client', 'id'], contactInfo)
      const selectedPilotId = pathOr(null, ['selectedPilot', 'id'], jobInfo)
      const selectedPilotCategoryId = pathOr(
        null,
        ['selectedPilotCategory', 'id'],
        jobInfo
      )
      const selectedSupplierId = pathOr(null, ['selectedSupplier', 'id'], jobInfo)

      const jobInfoPayload = pick(
        [
          'location',
          'radius',
          'description',
          'instructions',
          'orderedBy',
          'commercialSettings',
          'internalProjectId',
          'pricing',
          'publishType',
          'template',
          'acceptTermsConditions',
          'selectedAllPilot',
          'selectedAllSupplier',
        ],
        jobInfo
      )

      const datePayload = omitUnspecifiedDate(
        pick(['finishedAt', 'scheduledAt', 'startedAt', 'engagedAt'], jobInfo)
      )

      const otherPayload = {
        productId,
        projectId,
        companyId,
        clientId,
        selectedPilotId,
        selectedPilotCategoryId,
        selectedSupplierId,
      }

      const job = await JobService.publish(id, {
        ...jobInfoPayload,
        ...datePayload,
        ...otherPayload,
      })

      module.inspectJob(id)

      module.setState({
        entities: {
          [id]: job,
        },
      })
    },
    { errHandler: setError }
  )

const updateJob = (module, { setError }) =>
  withTryCatch(
    async (
      id,
      {
        status,
        jobInfo,
        contactInfo,
        // fetchEngaged = false,
        disputeReason,
      },
      updateSelectedFields = false
    ) => {
      const productId = path(['product', 'id'], jobInfo)
      const projectId = path(['project', 'id'], jobInfo)
      const clientId = path(['client', 'id'], contactInfo)
      const companyId = path(['company', 'id'], jobInfo)
      const selectedPilotId = pathOr(null, ['selectedPilot', 'id'], jobInfo)
      const selectedPilotCategoryId = pathOr(
        null,
        ['selectedPilotCategory', 'id'],
        jobInfo
      )
      const selectedSupplierId = pathOr(null, ['selectedSupplier', 'id'], jobInfo)
      const pilotId = path(['pilot', 'id'], contactInfo)

      const jobInfoPayload = pick(
        [
          'location',
          'radius',
          'description',
          'instructions',
          'orderedBy',
          'commercialSettings',
          'internalProjectId',
          'pricing',
          'publishType',
          'template',
          'acceptTermsConditions',
          'equipmentUsed',
          'equipmentFee',
          'calloutFee',
          'marketFee',
          'ratings',
          'feedback',
          'selectedAllPilot',
          'selectedAllSupplier',
          'selectedCustomerId',
          'selectedEquipments',
        ],
        jobInfo
      )

      const datePayload = omitUnspecifiedDate(
        pick(['finishedAt', 'scheduledAt', 'startedAt', 'engagedAt'], jobInfo)
      )

      const otherPayload = {
        productId,
        projectId,
        clientId,
        pilotId,
        companyId,
        selectedPilotId,
        selectedPilotCategoryId,
        selectedSupplierId,
        status,
        disputeReason,
      }
      const job = await JobService.update(
        id,
        {
          ...jobInfoPayload,
          ...datePayload,
          ...otherPayload,
        },
        updateSelectedFields
      )

      module.setState({
        entities: {
          [id]: job,
        },
      })

      // if (!fetchEngaged) {
      //   module.inspectJob(id)
      // } else {
      //   module.inspectEngagedJob(id)
      // }

      return job
    },
    { errHandler: setError }
  )

const updateEngagedJob = (module, { setError }) =>
  withTryCatch(
    async (id, { jobInfo, action }) => {
      const jobInfoPayload = pick(['startedAt', 'finishedAt'], jobInfo)

      const payload = omitUnspecifiedDate(jobInfoPayload)

      const job = await JobService.updateEngaged(id, {
        ...payload,
        action,
      })

      module.setState({
        entities: {
          [id]: job,
        },
      })
    },
    {
      errHandler: setError,
    }
  )

const acceptJob = (module, { setError }) =>
  withTryCatch(
    async (id) => {
      const job = await JobService.accept(id)

      module.setState({
        entities: {
          [id]: job,
        },
      })

      module.inspectJob(id)
    },
    { errHandler: setError }
  )

const rejectJob = (module, { setError }) =>
  withTryCatch(
    async (id, { rejectionReason }) => {
      const job = await JobService.reject(id, rejectionReason)

      module.setState({
        entities: {
          [id]: job,
        },
      })

      module.inspectJob(id)
    },
    { errHandler: setError }
  )

const finalizeJob = (module, { setError }) =>
  withTryCatch(
    async (_, { inviteOnly }) => {
      const { inspectedEntity: id } = module.getState()
      await JobService.finalize(id)

      if (inviteOnly) {
        toast.success('Email sent')
      }

      module.inspectJob(id)
    },
    { errHandler: setError }
  )

const sendUploadReminder = (_, { setError }) =>
  withTryCatch(
    async (id) => {
      await JobService.sendUploadReminder(id)
    },
    { errHandler: setError }
  )

const createJob = (module, { setError }) =>
  withTryCatch(
    async (_, data) =>
      JobService.create({
        ...pick([
          'productId',
          'clientId',
          'companyId',
          'commercialSettings',
          'template',
          'address',
          'scheduledAt',
          'projectId',
          'radius',
          'selectedCustomerId',
        ])(data),
      }),
    { errHandler: setError }
  )

const reset = (module) => () => module.setState(INITIAL_STATE)

const resetPagination = (module) => () =>
  module.setState({
    paging: {
      startRow: undefined,
      next: undefined,
    },
  })

const duplicateJob = (module, { setError }) =>
  withTryCatch(
    async (id) => {
      const job = await JobService.duplicate(id)
      if (job) {
        module.setState({
          entities: {
            [job.id]: job,
          },
        })
        module.inspectJob(job.id)
      }
      return job
    },
    { errHandler: setError }
  )

const jobModule = createModule({
  name: 'job',
  initialState: INITIAL_STATE,
  decorators: {
    setFilterParams,
    setSelectedTab,
    fetchJobs,
    inspectJob,
    inspectAvailableJob,
    inspectEngagedJob,
    publishJob,
    acceptJob,
    rejectJob,
    updateJob,
    engageJob,
    updateEngagedJob,
    filterJobs,
    filterEngagedJobs,
    filterAvailableJobs,
    setInspectedEntity,
    fetchEngagedJobs,
    finalizeJob,
    sendUploadReminder,
    createJob,
    reset,
    resetPagination,
    duplicateJob,
  },
})

export { jobModule }
