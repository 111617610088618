import { isNilOrEmpty } from '@seedcloud/ramda-extra'
import { useField, Field } from 'formik'

import { ErrorMessage } from 'components/common/ErrorMessage'
import { SERVICE_CATEGORIES } from 'constants/serviceCategories'
import { styled, apply } from 'lib/styled'

const Root = styled.div(apply('flex flex-wrap'), {
  gap: '.5rem',
})
const Container = styled.label(() =>
  apply('flex items-center jusitify-between text-md px-4 py-2 rounded-full', {
    cursor: 'pointer',
    transition: 'all 250ms ease-in-out',
    background: '#00326F',
    color: '#ffffff',
    fontWeight: 'bold',
    border: '1px solid rgba(0, 0, 0, .2)',
  })
)
const Icon = styled.i(apply('mr-2 text-blue-400'))
const CheckBoxInput = styled(Field)(apply('', { opacity: '0' }))

const TextValue = styled.p({
  fontSize: '1.0625rem',
  color: '#000000',
  fontWeight: 400,
  lineHeight: '20.4px',
  marginBottom: '14px',
})

function ServiceCategory({ name }) {
  const [fieldProps, { touched, error }] = useField({ name })
  const { value: fieldValue } = fieldProps

  if (isNilOrEmpty(fieldValue)) return <TextValue>No Data</TextValue>

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Root>
        {SERVICE_CATEGORIES.map(({ value, readableValue, iconClass }) => (
          <Container hidden={!fieldValue.includes(value)} key={value}>
            <Icon className={`fas fa-${iconClass}`} />
            {readableValue}
            <CheckBoxInput
              disabled
              value={value}
              name={name}
              type="checkbox"
              id={readableValue}
            />
          </Container>
        ))}
      </Root>

      <ErrorMessage error={error} visible={touched} />
    </div>
  )
}

export { ServiceCategory }
