import { filter, isNilOrEmpty } from '@seedcloud/ramda-extra'
import { createModule } from '@seedcloud/stateless'

import { PilotCategoryService } from './service'

import { SUPPLIER_TYPES } from 'constants/supplier'
import toast from 'lib/toaster'
import { calcNext } from 'utils/calcNext'
import { calcStartRow } from 'utils/calcStartRow'
import { withTryCatch } from 'utils/withTryCatch'

const INITIAL_STATE = Object.freeze({
  entities: {},
  order: [],
  isSubmitting: false,
  limit: 20,
  paging: {
    startRow: undefined,
    next: undefined,
  },
})

const filterPilotCategories =
  (module) =>
  async (_, { filterQuery }) => {
    module.setState({
      ...filterQuery,
    })
  }

const resetPagination = (module) => async () =>
  module.setState({
    limit: undefined,
    paging: {
      startRow: undefined,
      next: undefined,
    },
  })

const fetchPilotCategories = (module, { setError }) =>
  withTryCatch(
    async (_, { turnPage = true, turnNext, type = SUPPLIER_TYPES.PILOT }) => {
      const { paging, limit } = module.getState()

      const next = calcNext(turnPage, turnNext, paging, limit)

      const {
        entities,
        order,
        next: newNext,
        limit: newLimit,
      } = await PilotCategoryService.list({ next, type, limit })

      module.setState({
        entities,
        order,
        limit: newLimit,
        paging: {
          startRow: calcStartRow(newNext, limit, paging),
          next: newNext,
        },
      })
    },
    { onError: (err) => setError(null, err) }
  )

const publishPilotCategories = (module, { setError }) =>
  withTryCatch(
    async (_, { type, payload }) => {
      module.setState({
        isSubmitting: true,
      })
      const json = { ...payload }
      const typeValue = type[0].toUpperCase()

      json.type = type !== 'equipment' ? typeValue : 'EQUIPMENT'

      const pilotCategory = await PilotCategoryService.publish(json)

      toast.success('New category has been added!')

      module.setState({
        isSubmitting: false,
      })

      return pilotCategory
    },
    { errHandler: setError }
  )

const updatePilotCategories = (module, { setError }) =>
  withTryCatch(
    async (id, { type, payload }) => {
      const json = { ...payload }
      const typeValue = type?.[0].toUpperCase()

      json.type = type !== 'equipment' ? typeValue : 'EQUIPMENT'

      const updatedPilotCategories = await PilotCategoryService.update(id, json)

      toast.success('Pilot Category has been updated!')

      module.setState({
        entities: {
          [id]: { ...updatedPilotCategories },
        },
      })
    },
    { errHandler: setError }
  )

const deletePilotCategory = (module, { setError }) =>
  withTryCatch(
    async (id) => {
      if (isNilOrEmpty(id)) return

      const { entities, order } = module.getState()

      await PilotCategoryService.delete(id)

      delete entities[id]
      const updatedOrder = filter((order) => order !== id, order)

      module.setState({
        entities,
        order: updatedOrder,
      })
    },
    {
      errHandler: setError,
    }
  )

const pilotCategoryModule = createModule({
  name: 'pilotCategory',
  initialState: INITIAL_STATE,
  decorators: {
    fetchPilotCategories,
    publishPilotCategories,
    updatePilotCategories,
    deletePilotCategory,
    filterPilotCategories,
    resetPagination,
  },
})

export { pilotCategoryModule }
