import { styled, apply } from 'lib/styled'

const StyledTitle = styled.h2(apply('m-0 text-xl font-semibold'), {
  // Follows the navigation height
  scrollMarginTop: '160px',
})

export const Title = ({ children, id }) => (
  <StyledTitle data-tabs={id} id={id}>
    {children}
  </StyledTitle>
)
