import { ReactComponent as OnboardingImage } from 'assets/onboarding.svg'
import { Button as BaseButton } from 'components/common/Button'
import { Input } from 'components/common/Fields/Input'
import { NewLabel as Label } from 'components/common/Fields/Label'
import { styled, apply } from 'lib/styled'

const Card = styled.div(
  apply('flex flex-column bg-white items-center justify-center rounded-lg relative'),
  {
    gap: '2em',
    padding: '64px 40px',
    boxShadow: '0px 0px 10px rgba(0, 50, 111, 0.05)',
  }
)

const Image = styled(OnboardingImage)({
  position: 'relative',
  right: '-20px',
})

const Title = styled.h1({
  fontSize: '2.25rem',
  fontWeight: 700,
  margin: 0,
})

const Description = styled.p({
  fontSize: '0.9375rem',
  textAlign: 'center',
  marginBottom: 0,
  maxWidth: '566px',
})

const Button = styled(BaseButton)(apply('rounded-lg text-white w-full py-3'), {
  background: '#009DE4',
  height: 'auto',
})

const CompanyField = styled.div(apply('flex flex-column w-full'), {
  minWidth: '566px',
  gap: '.5em',
  marginLeft: 0,
  marginRight: 0,
})

const ClaimCompanyCard = Card

ClaimCompanyCard.Image = Image
ClaimCompanyCard.Title = Title
ClaimCompanyCard.Description = Description
ClaimCompanyCard.Button = Button

// eslint-disable-next-line react/display-name
ClaimCompanyCard.Field = ({ label, value, id, name, disabled, type, inputProps }) => (
  <CompanyField>
    <Label htmlFor={id}>{label}</Label>
    <Input
      id={id}
      name={name}
      type={type}
      value={value}
      disabled={disabled}
      {...inputProps}
    />
  </CompanyField>
)

export { ClaimCompanyCard }
