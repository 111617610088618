import { isNotNilOrEmpty, renameKeys } from '@seedcloud/ramda-extra'
import { useFormikContext } from 'formik'
import { useMemo, useEffect, useState } from 'react'

import { PreviewModal } from '../JobDetails/sections/PreviewModal'

import { Button } from 'components/common/Button'
import { useJobs } from 'components/common/context/JobContext'
import { FormInput as FormInputBase } from 'components/common/FormInput'
import { SelectField } from 'components/common/SelectField'
import { Title } from 'components/common/Tabs/ui'
import { JOB_STATUSES } from 'constants/jobs'
import { ORGANIZATION_ROLES } from 'constants/roles'
import { useIdentity } from 'lib/solta-id-react'
import { styled, apply } from 'lib/styled'
import { nullToString } from 'utils/stringUtils'

const Header = styled.div(apply('flex flex-column py-6'))
const Column = styled.div(apply('flex-1 flex flex-column'))
const Row = styled.div(apply('flex-1 flex flex-row'))

const FormInput = styled(FormInputBase)(apply('mt-4 ml-0 mr-0'))

const Description = styled.span(apply('text-base text-grey-dark mt-1'))

const convertUserListToOptions = (list) =>
  list
    .filter(({ isActive, isBanned }) => isActive && !isBanned)
    .filter(
      ({ userDetails: { firstName, lastName } }) =>
        isNotNilOrEmpty(firstName) && isNotNilOrEmpty(lastName)
    )
    .map(
      ({ id, userDetails: { phoneNumber: mobile, email, firstName, lastName } }) => ({
        id,
        value: `${firstName} ${nullToString(lastName)}`,
        label: `${firstName} ${nullToString(lastName)}`,
        mobile,
        email,
      })
    )

function ContactInfo({ isEditing }) {
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false)
  const {
    setFieldValue,
    values: { jobInfo },
  } = useFormikContext()
  const { staffList, editableClient, jobStatus } = useJobs()
  const { role, user, account, isAdmin } = useIdentity()
  const isStaff = role === ORGANIZATION_ROLES.ORGANIZATION_STAFF

  const jobCanceled = [JOB_STATUSES.CANCELLED].includes(jobStatus)

  const clientOptions = useMemo(
    () =>
      convertUserListToOptions(
        // As the backend expects `orderedBy` as a `userId`
        // we use the `userId` as the primary identifier
        staffList
          .filter(({ organizationId }) => organizationId === account.companyId)
          .map(renameKeys({ id: 'staffId', userId: 'id' }))
      ),
    [staffList, account.companyId]
  )

  useEffect(() => {
    if (isStaff && user && clientOptions) {
      const userData = clientOptions?.find(({ label }) => label === user.name)

      setFieldValue('contactInfo.client', {
        id: userData?.id,
        name: userData?.label,
        email: userData?.email,
        mobile: userData?.mobile,
      })
    }
  }, [isStaff, user, clientOptions])

  return (
    <>
      <Header>
        <Title id="contact">Staff Job Contact</Title>

        <Description>The staff for the pilot to ask about job information</Description>
      </Header>

      <Row>
        <Column>
          <SelectField
            name="contactInfo.client.name"
            label="Staff Name"
            emptyOption={{ id: undefined, value: undefined, email: '', mobile: '' }}
            onChange={({ id, value: name, email, mobile }) => {
              setFieldValue('contactInfo.client', { id, name, email, mobile })
            }}
            options={clientOptions}
            placeholder="Search and Select Staff"
            isDisabled={!editableClient || isStaff || !isEditing}
            testId="job-client-name"
          />
          <FormInput
            name="contactInfo.client.mobile"
            type="text"
            label="Staff Mobile"
            readOnly
          />
          <FormInput
            name="contactInfo.client.email"
            type="email"
            label="Staff Email"
            readOnly
          />
        </Column>
      </Row>
      {isAdmin && !jobCanceled ? (
        <Row>
          <Button
            type="button"
            style={apply('my-4')}
            onClick={() => setIsPreviewModalOpen(true)}
          >
            Preview
          </Button>
        </Row>
      ) : null}
      <PreviewModal
        isOpen={isPreviewModalOpen}
        setIsOpen={setIsPreviewModalOpen}
        jobInfo={jobInfo}
      />
    </>
  )
}

export { ContactInfo }
