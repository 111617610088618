import { Switch, Route } from 'react-router-dom'

import { ApplicationDetails, AviationDetails } from './ApplicationDetails'
import { ApplicationList } from './ApplicationList'

import { NotFoundPage } from 'components/common/NotFoundPage'
import { styled, apply } from 'lib/styled'

// eslint-disable-next-line react/display-name
const withApplicationList = (Component) => (props) =>
  (
    <>
      <ApplicationList />
      <Component {...props} />
    </>
  )

const Container = styled.div(apply('flex-1 flex flex-row p-6'))

function ApplicationsManager() {
  return (
    <Container>
      <Switch>
        <Route exact path="/" component={ApplicationList} />
        <Route exact path="/applications" component={ApplicationList} />
        <Route
          exact
          path={['/applications/:id', '/applications/:id/personal-details']}
          render={withApplicationList(ApplicationDetails)}
        />
        <Route
          exact
          path={['/applications/:id/aviation-details']}
          render={withApplicationList(AviationDetails)}
        />
        <Route component={NotFoundPage} />
      </Switch>
    </Container>
  )
}

export { ApplicationsManager }
