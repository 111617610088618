const JOB_STATUSES = {
  NEW: 'job_status_new',
  QUEUE: 'job_status_queue',
  PUBLISHED: 'job_status_published',
  ACCEPTED: 'job_status_accepted',
  ENGAGED: 'job_status_engaged',
  DELIVERED: 'job_status_delivered',
  IN_REVIEW: 'job_status_in_review',
  IN_REVIEW_NETWORK: 'job_status_in_review_network',
  COMPLETED: 'job_status_completed',
  CANCELLED: 'job_status_cancelled',
  DISPUTED: 'job_status_disputed',
}

export { JOB_STATUSES }
