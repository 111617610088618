import CloudLayoutImage from 'assets/clouds_container.svg'
import { Spinner } from 'components/common/Spinner'
import { styled, apply } from 'lib/styled'

const Layout = styled.div(
  apply('flex flex-column w-full bg-white rounded-xl shadow-sm relative'),
  {
    overflow: 'hidden',
  }
)

const CloudBackground = styled.div({
  height: '220px',
  backgroundImage: `url('${CloudLayoutImage}')`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'bottom right',
  marginRight: '-24px',
  marginBottom: '-24px',
})

const SpinnerContainer = styled.div({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
})

const MyPilotDetailsLayout = ({ children, footer, loading }) => (
  <Layout>
    <>
      {loading ? (
        <SpinnerContainer>
          <Spinner size={64} thickness={7} style={{ margin: 'auto 0' }} />
        </SpinnerContainer>
      ) : (
        children
      )}
      {!loading ? <CloudBackground /> : null}
    </>
    {footer || null}
  </Layout>
)

export { MyPilotDetailsLayout }
