/* eslint-disable complexity */

import { path } from '@seedcloud/ramda-extra'
import { connect } from '@seedcloud/stateless'
import { Formik as FormProvider, Form } from 'formik'
import { useMemo, useLayoutEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { NavigationTab } from '../JobDetails/NavigationTab'

import { FillContactInfo } from './FillContactInfo'
import { JobInfo } from './JobInfo'
import { validationSchema } from './validationSchema'

import { JobsProvider } from 'components/common/context/JobContext'
import { FormAction } from 'components/company/common'
import { ORGANIZATION_ROLES } from 'constants/roles'
import { useIdentity } from 'lib/solta-id-react'
import { styled, apply } from 'lib/styled'
import toaster from 'lib/toaster'
import { jobModule } from 'modules/job'
import { createCompanyModule } from 'modules/legacy-company'
import { productList$, productModule } from 'modules/product'
import { projectList$, projectModule } from 'modules/project'
import { staffList$, staffModule } from 'modules/staff'

const {
  module: companyModule,
  selectors: { customerList$ },
} = createCompanyModule()

const Container = styled.div(
  apply('flex flex-column w-full bg-white rounded-lg shadow-sm', {
    overflow: 'hidden',
  })
)

const Scrollable = styled.div(
  apply('flex flex-column px-6', {
    overflow: 'auto',
  })
)

const Details = styled(Form)(apply('flex-1 flex flex-column'))

const minScheduledAtDate = new Date()

const emptyInitialValues = {
  jobInfo: {
    address: undefined,
    radius: undefined,
    scheduledAt: new Date(),
    description: undefined,
    instructions: undefined,
    selectedCustomerId: undefined,
    commercialSettings: {
      billable: false,
      paid: false,
      dataUpload: false,
    },
    product: {
      id: undefined,
      name: undefined,
    },
    template: 'default',
    project: { reference: undefined, id: undefined },
    company: {
      id: undefined,
      companyName: undefined,
      email: undefined,
      phoneNumber: undefined,
    },
    customer: {
      companyName: undefined,
      companyEmail: undefined,
      companyNumber: undefined,
    },
  },

  contactInfo: {
    client: {
      id: undefined,
      name: undefined,
      mobile: '',
      email: '',
    },
  },
}

const ConnectedCreateJob = connect(() => ({
  productList: productList$,
  projectList: projectList$,
  customerList: customerList$,
  staffList: staffList$,
}))(CreateJob)

function CreateJob({ productList, projectList, customerList, staffList }) {
  const { role } = useIdentity()

  const history = useHistory()
  function cancelCreate() {
    history.push('/jobs')
  }

  useLayoutEffect(() => () => jobModule.reset(), [])

  const memoizedValidationSchema = useMemo(
    () => validationSchema({ minScheduledAtDate }),
    []
  )

  async function handleSubmit(values) {
    const productId = path(['jobInfo', 'product', 'id'])(values)
    const clientId = path(['contactInfo', 'client', 'id'])(values)
    const projectId = path(['jobInfo', 'project', 'id'])(values)
    const companyId = path(['jobInfo', 'company', 'id'])(values)

    const {
      jobInfo: {
        address,
        commercialSettings,
        template,
        radius,
        scheduledAt,
        selectedCustomerId,
      } = {},
    } = values

    const { id } = await jobModule.createJob(null, {
      productId,
      clientId,
      companyId,
      address,
      radius,
      commercialSettings,
      template,
      scheduledAt,
      projectId,
      selectedCustomerId,
    })

    toaster.success('Job created')

    history.push(`/job/${id}`)
  }

  useLayoutEffect(() => {
    productModule.fetchProducts(null)
    projectModule.fetchProjects(null)
    companyModule.filterCompanies(null, { filterType: 'customer' })
  }, [])

  const onCreateProject = (reference) => {
    projectModule.createProject(null, reference)
  }

  const onFilterProduct = productModule.filterProducts

  const editableClient =
    role === ORGANIZATION_ROLES.ORGANIZATION_ADMIN ||
    role === ORGANIZATION_ROLES.ORGANIZATION_STAFF

  useLayoutEffect(() => {
    if (editableClient) {
      staffModule.fetchStaff(null)
    }
  }, [])

  return (
    <JobsProvider
      value={{
        productList,
        projectList,
        customerList,
        staffList,
        onCreateProject,
        onFilterProduct,
        isEditing: true,
        editableClient,
      }}
    >
      <Container>
        <Scrollable>
          <FormProvider
            enableReinitialize
            initialValues={emptyInitialValues}
            validationSchema={memoizedValidationSchema}
            onSubmit={handleSubmit}
          >
            <Details>
              <NavigationTab
                onGoBackClicked={() => history.push('/jobs')}
                title="Create New"
                showEditingTab={false}
              />
              <JobInfo minScheduledAtDate={minScheduledAtDate} />
              {/* <ContactInfo /> */}
              <FillContactInfo />

              <FormAction
                // loading={isSubmitting}
                okText="Create"
                loadingText="Updating..."
                onCancel={cancelCreate}
              />
            </Details>
          </FormProvider>
        </Scrollable>
      </Container>
    </JobsProvider>
  )
}

export { ConnectedCreateJob as CreateJob }
