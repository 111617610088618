import { useMemo } from 'react'

import { PublishedToBadge } from '../common/PublishedToBadge'

import { ROLES } from 'constants/roles'

function publishedToColumn(selectedTab) {
  const shouldHidePublishedTo = (value) =>
    selectedTab === 'queue' && value !== 'Network Hub'

  return {
    Header: 'Published To',
    accessor: 'publishedTo',
    disableSortBy: true,
    Cell: ({ value }) =>
      shouldHidePublishedTo(value) ? '-' : <PublishedToBadge>{value}</PublishedToBadge>,
  }
}

export const Columns = (selectedTab, role) =>
  // eslint-disable-next-line complexity
  useMemo(() => {
    let columns = []

    switch (selectedTab) {
      case 'new':
        columns = [
          { Header: 'Product', accessor: 'product', disableSortBy: true },
          { Header: 'Customer', accessor: 'customer', disableSortBy: true },
          { Header: 'Location', accessor: 'location', disableSortBy: true },
          { Header: 'Created At', accessor: 'createdAt' },
          { Header: 'Scheduled At', accessor: 'scheduledAt' },
          { Header: 'Reference', accessor: 'reference', disableSortBy: true },
        ]
        break
      case 'queue':
        columns = [
          { Header: 'Product', accessor: 'product', disableSortBy: true },
          { Header: 'Customer', accessor: 'customer', disableSortBy: true },
          { Header: 'Location', accessor: 'location', disableSortBy: true },
          { Header: 'Created At', accessor: 'createdAt' },
          { Header: 'Scheduled At', accessor: 'scheduledAt' },
          { Header: 'Reference', accessor: 'reference', disableSortBy: true },
        ]
        break
      case 'published':
        columns = [{ Header: 'Product', accessor: 'product', disableSortBy: true }]

        if (role === ROLES.PLATFORM_ADMIN) {
          columns.push(
            { Header: 'Customer', accessor: 'customer', disableSortBy: true },
            { Header: 'Location', accessor: 'location', disableSortBy: true },
            { Header: 'Created At', accessor: 'createdAt' },
            { Header: 'Scheduled At', accessor: 'scheduledAt' },
            publishedToColumn(selectedTab),
            { Header: 'Budget Cap', accessor: 'budget', disableSortBy: true },
            { Header: 'Reference', accessor: 'reference', disableSortBy: true }
          )
        }
        if (role === ROLES.SUPPLIER_ADMIN) {
          columns.push(
            { Header: 'Location', accessor: 'location', disableSortBy: true },
            { Header: 'Created At', accessor: 'createdAt' },
            { Header: 'Scheduled At', accessor: 'scheduledAt' },
            { Header: 'Budget Cap', accessor: 'budget', disableSortBy: true },
            { Header: 'Reference', accessor: 'reference', disableSortBy: true }
          )
        }
        if (role === ROLES.USER) {
          columns.push(
            { Header: 'Location', accessor: 'location', disableSortBy: true },
            { Header: 'Created At', accessor: 'createdAt' },
            { Header: 'Scheduled At', accessor: 'scheduledAt' },
            { Header: 'Reference', accessor: 'reference', disableSortBy: true }
          )
        }

        break
      case 'accepted':
        columns = [{ Header: 'Product', accessor: 'product', disableSortBy: true }]
        if (role === ROLES.PLATFORM_ADMIN) {
          columns.push(
            { Header: 'Customer', accessor: 'customer', disableSortBy: true },
            { Header: 'Assigned To', accessor: 'pilotName' },
            { Header: 'Location', accessor: 'location', disableSortBy: true },
            { Header: 'Created At', accessor: 'createdAt' },
            { Header: 'Scheduled At', accessor: 'scheduledAt' },
            publishedToColumn(selectedTab),
            { Header: 'Budget Cap', accessor: 'budget', disableSortBy: true },
            { Header: 'Reference', accessor: 'reference', disableSortBy: true }
          )
        }
        if (role === ROLES.SUPPLIER_ADMIN) {
          columns.push(
            { Header: 'Assigned To', accessor: 'pilotName' },
            { Header: 'Location', accessor: 'location', disableSortBy: true },
            { Header: 'Created At', accessor: 'createdAt' },
            { Header: 'Scheduled At', accessor: 'scheduledAt' },
            { Header: 'Budget Cap', accessor: 'budget', disableSortBy: true },
            { Header: 'Reference', accessor: 'reference', disableSortBy: true }
          )
        }
        if (role === ROLES.USER) {
          columns.push(
            { Header: 'Assigned To', accessor: 'pilotName' },
            { Header: 'Location', accessor: 'location', disableSortBy: true },
            { Header: 'Created At', accessor: 'createdAt' },
            { Header: 'Scheduled At', accessor: 'scheduledAt' },
            { Header: 'Budget Cap', accessor: 'budget', disableSortBy: true },
            { Header: 'Reference', accessor: 'reference', disableSortBy: true }
          )
        }
        break
      case 'engaged':
        columns = [{ Header: 'Product', accessor: 'product', disableSortBy: true }]
        if (role === ROLES.PLATFORM_ADMIN) {
          columns.push(
            { Header: 'Customer', accessor: 'customer', disableSortBy: true },
            { Header: 'Assigned To', accessor: 'pilotName' },
            { Header: 'Location', accessor: 'location', disableSortBy: true },
            { Header: 'Created At', accessor: 'createdAt' },
            { Header: 'Scheduled At', accessor: 'scheduledAt' },
            { Header: 'Started At', accessor: 'startedAt' },
            { Header: 'Ended At', accessor: 'endedAt' },
            publishedToColumn(selectedTab),
            { Header: 'Budget Cap', accessor: 'budget', disableSortBy: true },
            { Header: 'Reference', accessor: 'reference', disableSortBy: true }
          )
        }
        if (role === ROLES.SUPPLIER_ADMIN) {
          columns.push(
            { Header: 'Assigned To', accessor: 'pilotName' },
            { Header: 'Location', accessor: 'location', disableSortBy: true },
            { Header: 'Created At', accessor: 'createdAt' },
            { Header: 'Scheduled At', accessor: 'scheduledAt' },
            { Header: 'Started At', accessor: 'startedAt' },
            { Header: 'Ended At', accessor: 'endedAt' },
            { Header: 'Budget Cap', accessor: 'budget', disableSortBy: true },
            { Header: 'Reference', accessor: 'reference', disableSortBy: true }
          )
        }
        if (role === ROLES.USER) {
          columns.push(
            { Header: 'Assigned To', accessor: 'pilotName' },
            { Header: 'Location', accessor: 'location', disableSortBy: true },
            { Header: 'Created At', accessor: 'createdAt' },
            { Header: 'Scheduled At', accessor: 'scheduledAt' },
            { Header: 'Started At', accessor: 'startedAt' },
            { Header: 'Ended At', accessor: 'endedAt' },
            { Header: 'Budget Cap', accessor: 'budget', disableSortBy: true },
            { Header: 'Reference', accessor: 'reference', disableSortBy: true }
          )
        }
        break
      case 'delivered':
        columns = [{ Header: 'Product', accessor: 'product', disableSortBy: true }]
        if (role === ROLES.SUPPLIER_ADMIN) {
          columns.push(
            { Header: 'Assigned To', accessor: 'pilotName' },
            { Header: 'Location', accessor: 'location', disableSortBy: true },
            { Header: 'Created At', accessor: 'createdAt' },
            { Header: 'Scheduled At', accessor: 'scheduledAt' },
            { Header: 'Started At', accessor: 'startedAt' },
            { Header: 'Ended At', accessor: 'finishedAt' },
            { Header: 'Budget Cap', accessor: 'budget', disableSortBy: true },
            { Header: 'Reference', accessor: 'reference', disableSortBy: true }
          )
        }
        if (role === ROLES.PLATFORM_ADMIN) {
          columns.push(
            { Header: 'Customer', accessor: 'customer', disableSortBy: true },
            { Header: 'Assigned To', accessor: 'pilotName' },
            { Header: 'Location', accessor: 'location', disableSortBy: true },
            { Header: 'Created At', accessor: 'createdAt' },
            { Header: 'Scheduled At', accessor: 'scheduledAt' },
            { Header: 'Started At', accessor: 'startedAt' },
            { Header: 'Ended At', accessor: 'finishedAt' },
            publishedToColumn(selectedTab),
            { Header: 'Budget Cap', accessor: 'budget', disableSortBy: true },
            { Header: 'Reference', accessor: 'reference', disableSortBy: true }
          )
        }
        if (role === ROLES.USER) {
          columns.push(
            { Header: 'Assigned To', accessor: 'pilotName' },
            { Header: 'Location', accessor: 'location', disableSortBy: true },
            { Header: 'Created At', accessor: 'createdAt' },
            { Header: 'Scheduled At', accessor: 'scheduledAt' },
            { Header: 'Started At', accessor: 'startedAt' },
            { Header: 'Ended At', accessor: 'finishedAt' },
            { Header: 'Budget Cap', accessor: 'budget', disableSortBy: true },
            { Header: 'Reference', accessor: 'reference', disableSortBy: true }
          )
        }
        break
      case 'in_review':
        columns = [{ Header: 'Product', accessor: 'product', disableSortBy: true }]
        if (role === ROLES.PLATFORM_ADMIN) {
          columns.push(
            { Header: 'Customer', accessor: 'customer', disableSortBy: true },
            { Header: 'Assigned To', accessor: 'pilotName' },
            { Header: 'Location', accessor: 'location', disableSortBy: true },
            { Header: 'Created At', accessor: 'createdAt' },
            { Header: 'Scheduled At', accessor: 'scheduledAt' },
            { Header: 'Started At', accessor: 'startedAt' },
            { Header: 'Ended At', accessor: 'finishedAt' },
            publishedToColumn(selectedTab),
            { Header: 'Budget Cap', accessor: 'budget', disableSortBy: true },
            { Header: 'Reference', accessor: 'reference', disableSortBy: true }
          )
        }
        if (role === ROLES.SUPPLIER_ADMIN) {
          columns.push(
            { Header: 'Assigned To', accessor: 'pilotName' },
            { Header: 'Location', accessor: 'location', disableSortBy: true },
            { Header: 'Created At', accessor: 'createdAt' },
            { Header: 'Scheduled At', accessor: 'scheduledAt' },
            { Header: 'Started At', accessor: 'startedAt' },
            { Header: 'Ended At', accessor: 'finishedAt' },
            { Header: 'Budget Cap', accessor: 'budget', disableSortBy: true },
            { Header: 'Reference', accessor: 'reference', disableSortBy: true }
          )
        }
        if (role === ROLES.USER) {
          columns.push(
            { Header: 'Assigned To', accessor: 'pilotName' },
            { Header: 'Location', accessor: 'location', disableSortBy: true },
            { Header: 'Created At', accessor: 'createdAt' },
            { Header: 'Scheduled At', accessor: 'scheduledAt' },
            { Header: 'Started At', accessor: 'startedAt' },
            { Header: 'Ended At', accessor: 'finishedAt' },
            { Header: 'Budget Cap', accessor: 'budget', disableSortBy: true },
            { Header: 'Reference', accessor: 'reference', disableSortBy: true }
          )
        }
        break
      case 'completed':
        columns = [{ Header: 'Product', accessor: 'product', disableSortBy: true }]
        if (role === ROLES.PLATFORM_ADMIN) {
          columns.push(
            { Header: 'Customer', accessor: 'customer', disableSortBy: true },
            { Header: 'Assigned To', accessor: 'pilotName' },
            { Header: 'Location', accessor: 'location', disableSortBy: true },
            { Header: 'Created At', accessor: 'createdAt' },
            { Header: 'Scheduled At', accessor: 'scheduledAt' },
            { Header: 'Started At', accessor: 'startedAt' },
            { Header: 'Ended At', accessor: 'finishedAt' },
            publishedToColumn(selectedTab),
            { Header: 'Budget Cap', accessor: 'budget', disableSortBy: true },
            { Header: 'Reference', accessor: 'reference', disableSortBy: true }
          )
        }
        if (role === ROLES.SUPPLIER_ADMIN) {
          columns.push(
            { Header: 'Assigned To', accessor: 'pilotName' },
            { Header: 'Location', accessor: 'location', disableSortBy: true },
            { Header: 'Created At', accessor: 'createdAt' },
            { Header: 'Scheduled At', accessor: 'scheduledAt' },
            { Header: 'Started At', accessor: 'startedAt' },
            { Header: 'Ended At', accessor: 'finishedAt' },
            { Header: 'Budget Cap', accessor: 'budget', disableSortBy: true },
            { Header: 'Reference', accessor: 'reference', disableSortBy: true }
          )
        }
        if (role === ROLES.USER) {
          columns.push(
            { Header: 'Assigned To', accessor: 'pilotName' },
            { Header: 'Location', accessor: 'location', disableSortBy: true },
            { Header: 'Created At', accessor: 'createdAt' },
            { Header: 'Scheduled At', accessor: 'scheduledAt' },
            { Header: 'Started At', accessor: 'startedAt' },
            { Header: 'Ended At', accessor: 'finishedAt' },
            { Header: 'Budget Cap', accessor: 'budget', disableSortBy: true },
            { Header: 'Reference', accessor: 'reference', disableSortBy: true }
          )
        }
        if (role === ROLES.CUSTOMER) {
          columns.push(
            { Header: 'Assigned To', accessor: 'pilotName' },
            { Header: 'Location', accessor: 'location', disableSortBy: true },
            { Header: 'Created At', accessor: 'createdAt' },
            { Header: 'Scheduled At', accessor: 'scheduledAt' },
            { Header: 'Started At', accessor: 'startedAt' },
            { Header: 'Ended At', accessor: 'finishedAt' },
            publishedToColumn(selectedTab),
            { Header: 'Budget Cap', accessor: 'budget', disableSortBy: true },
            { Header: 'Reference', accessor: 'reference', disableSortBy: true }
          )
        }
        break
      case 'cancelled':
        columns = [
          { Header: 'Product', accessor: 'product', disableSortBy: true },
          { Header: 'Customer', accessor: 'customer', disableSortBy: true },
          { Header: 'Location', accessor: 'location', disableSortBy: true },
          { Header: 'Created At', accessor: 'createdAt' },
          { Header: 'Scheduled At', accessor: 'scheduledAt' },
          { Header: 'Cancelled At', accessor: 'cancelledAt' },
          { Header: 'Reference', accessor: 'reference', disableSortBy: true },
        ]
        break
      case 'disputed':
        columns = [{ Header: 'Product', accessor: 'product', disableSortBy: true }]
        if (role === ROLES.SUPPLIER_ADMIN) {
          columns.push(
            { Header: 'Assigned To', accessor: 'pilotName' },
            { Header: 'Location', accessor: 'location', disableSortBy: true },
            { Header: 'Created At', accessor: 'createdAt' },
            { Header: 'Scheduled At', accessor: 'scheduledAt' },
            { Header: 'Started At', accessor: 'startedAt' },
            { Header: 'Ended At', accessor: 'finishedAt' },
            { Header: 'Budget Cap', accessor: 'budget', disableSortBy: true },
            { Header: 'Reference', accessor: 'reference', disableSortBy: true }
          )
        }
        if (role === ROLES.PLATFORM_ADMIN) {
          columns.push(
            { Header: 'Assigned To', accessor: 'pilotName' },
            { Header: 'Customer', accessor: 'customer', disableSortBy: true },
            { Header: 'Location', accessor: 'location', disableSortBy: true },
            { Header: 'Created At', accessor: 'createdAt' },
            { Header: 'Scheduled At', accessor: 'scheduledAt' },
            { Header: 'Started At', accessor: 'startedAt' },
            { Header: 'Ended At', accessor: 'finishedAt' },
            { Header: 'Budget Cap', accessor: 'budget', disableSortBy: true },
            publishedToColumn(selectedTab),
            { Header: 'Reference', accessor: 'reference', disableSortBy: true }
          )
        }
        if (role === ROLES.USER) {
          columns.push(
            { Header: 'Assigned To', accessor: 'pilotName' },
            { Header: 'Location', accessor: 'location', disableSortBy: true },
            { Header: 'Created At', accessor: 'createdAt' },
            { Header: 'Scheduled At', accessor: 'scheduledAt' },
            { Header: 'Started At', accessor: 'startedAt' },
            { Header: 'Ended At', accessor: 'finishedAt' },
            { Header: 'Budget Cap', accessor: 'budget', disableSortBy: true },
            { Header: 'Reference', accessor: 'reference', disableSortBy: true }
          )
        }
        break
      default:
        return []
    }

    if (role === ROLES.ORGANIZATION_STAFF) {
      return columns.filter(({ Header }) => !Header.startsWith('Client'))
    }

    return columns
  })
