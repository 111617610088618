/* eslint-disable camelcase */

import { isNotNil } from '@seedcloud/ramda-extra'
import { format } from 'date-fns'

import { Button } from 'components/common/Button'
import { COMPANY_TYPE_DISPLAY_NAMES } from 'constants/companyTypes'
import { styled, apply } from 'lib/styled'

const Container = styled.li(
  apply(
    'relative flex flex-row items-center px-4 py-2 border-0 border-b-1 border-solid border-grey-lighter',
    {
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: '#FAFAFA',
      },
    }
  ),
  ({ isInspected, isDeleted }) =>
    apply(
      isInspected ? 'bg-blue-lightest' : 'bg-white',
      isDeleted
        ? {
            opacity: 0.2,
            pointerEvents: 'none',
          }
        : {}
    )
)
const Details = styled.div(apply('flex-1 flex flex-column mb-1'))

const FullName = styled.span(apply('font-semibold text-lg text-near-black'))
const TypeText = styled.span(apply('font-light text-sm text-black'))

const JoinedAt = styled.span(
  apply('font-light text-sm italic text-grey', {
    '& > b': apply('font-semibold'),
  })
)

const PendingActivationSpan = styled.span(apply('text-red-lighter'))

const CompanyListItem = ({
  isInspected,
  company,
  onClick,
  isInvite,
  onInvite,
  isInvited,
}) => (
  <Container isInspected={isInspected} onClick={onClick}>
    <Details>
      <FullName>{company.contactName}</FullName>
      <TypeText>{COMPANY_TYPE_DISPLAY_NAMES[company.type]}</TypeText>
      <JoinedAt>
        {isNotNil(company.createdAt) ? (
          <>
            Since <b>{format(new Date(company.createdAt), 'dd MMM yyyy')}</b>
          </>
        ) : (
          <PendingActivationSpan>Pending activation</PendingActivationSpan>
        )}
      </JoinedAt>
    </Details>
    {isInvite && (
      <Button disabled={isInvited} onClick={onInvite}>
        {isInvited ? 'Invited' : 'Invite'}
      </Button>
    )}
  </Container>
)

export { CompanyListItem }
