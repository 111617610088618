import { useFormikContext, Field } from 'formik'
import { useState } from 'react'

import { Avatar } from 'components/common/Avatar'
import { ErrorMessage } from 'components/common/Fields/ErrorMessage'
import { ImageUpload } from 'components/common/ImageUpload'
import { TermsModal } from 'components/common/TermsConditionModal/TermsModal'
import { Row, TextField, SelectField } from 'components/company/common'
import { styled, apply } from 'lib/styled'
import { formatCurrency } from 'utils/formatCurrency'

const Details = styled.div(apply('flex-1 flex flex-row'))
const Column = styled.div(apply('flex-1 flex flex-column'))
const Checkbox = styled.input(apply('mr-2'))

const TermsButton = styled.button({
  backgroundColor: 'white',
  color: 'black',
  border: 'none',
  textDecoration: 'underline',
  padding: '0',
})

const TermsCheckbox = ({
  field: { name, value, onChange, onBlur },
  form: { errors, touched },
  id,
}) => {
  const [showModal, setShowModal] = useState(false)
  return (
    <div>
      <Checkbox
        name={name}
        id={id}
        type="checkbox"
        value={value}
        checked={value}
        onChange={onChange}
        onBlur={onBlur}
        className="TermsCheckbox"
      />
      <label>
        I agree that this pilot agrees to the{' '}
        <TermsButton onClick={() => setShowModal(true)}>
          {' '}
          Terms & Conditions{' '}
        </TermsButton>{' '}
        of being a pilot under the Supplier
      </label>
      {touched[name] && <ErrorMessage>{errors[name]}</ErrorMessage>}
      <TermsModal isOpen={showModal} closeModal={() => setShowModal(false)} />
    </div>
  )
}

export function PersonalDetailFields({
  pilotCategoriesList = [],
  setAvatarFile,
  isInvitingMySelf = false,
}) {
  const { setFieldValue, values } = useFormikContext()

  const pilotCategoryOptions = pilotCategoriesList.map(({ id, description, rate }) => ({
    id,
    value: id,
    label: `${description} ${formatCurrency(rate)} / hr`,
  }))
  return (
    <Details>
      <Column style={apply('items-center w-10 mr-6', { flex: 'none' })}>
        <ImageUpload
          name="avatar"
          ImageComponent={Avatar}
          imageProps={{
            size: 96,
            placeholderSize: 80,
            style: apply('m-0'),
          }}
          onUpload={setAvatarFile}
        />
      </Column>
      <Column>
        {isInvitingMySelf ? (
          <h2 style={{ fontSize: 21, fontWeight: 800, marginBottom: 16 }}>
            Personal Detail
          </h2>
        ) : null}
        <Row>
          <TextField
            value={values.firstName}
            name="firstName"
            type="text"
            label="First Name"
            required
            disabled={isInvitingMySelf}
          />
          <TextField
            value={values.lastName}
            name="lastName"
            type="text"
            label="Last Name"
            disabled={isInvitingMySelf}
          />
        </Row>
        <Row>
          <TextField
            value={values.phoneNumber}
            name="phoneNumber"
            type="text"
            label="Phone Number"
            disabled={isInvitingMySelf}
          />
          <TextField
            value={values.email}
            name="email"
            type="text"
            label="Email"
            required
            disabled={isInvitingMySelf}
          />
        </Row>
        <Row>
          <SelectField
            name="pilotCategoryId"
            label="Pilot Category"
            subLabel="If no category is shown, add one by going to My Company menu > Rate tab > Categories sub-tab"
            emptyOption={{
              id: undefined,
              value: undefined,
            }}
            onChange={({ id }) => {
              setFieldValue('pilotCategoryId', id)
            }}
            options={pilotCategoryOptions}
            placeholder="Select Pilot Category"
            required
          />
        </Row>
        <Row>
          <TextField
            disabled={true}
            value={values.companyName}
            name="companyName"
            type="text"
            label="Company Name"
          />
        </Row>
        {!isInvitingMySelf ? (
          <Row>
            <Field component={TermsCheckbox} name="terms" id="terms" />
          </Row>
        ) : null}
      </Column>
    </Details>
  )
}
