import { connect } from '@seedcloud/stateless'
import { useEffect, useMemo, useState } from 'react'
import { useParams, useHistory, useLocation } from 'react-router-dom'

import { InvitePilot } from './InvitePilot'

import { API_TYPES } from 'lib/http-client'
import { useIdentity } from 'lib/solta-id-react'
import toaster from 'lib/toaster'
import { createCompanyModule } from 'modules/legacy-company'
import { createPilotModule } from 'modules/pilot'
import { createPilotCategoriesModule } from 'modules/pilotCategory'
import { getCompanyAndOrganizationId } from 'utils/getOrganizationId'

const { module: pilotModule } = createPilotModule(API_TYPES.WEB)

const {
  module: pilotCategoryModule,
  selectors: { pilotCategoriesList$ },
} = createPilotCategoriesModule(API_TYPES.WEB)

const { module: companyModule } = createCompanyModule(API_TYPES.WEB)

const { inviteCompanyPilot, inviteMyselfAsPilot } = companyModule

const ConnectedInvitePilot = connect(() => ({
  pilotCategoriesList: pilotCategoriesList$,
}))(InvitePilotContainer)

function useQuery() {
  const { search } = useLocation()

  return useMemo(() => new URLSearchParams(search), [search])
}

function InvitePilotContainer({ pilotCategoriesList }) {
  const { organization, account, refetchAccount, isPilot } = useIdentity()
  const { companyId } = useParams()
  const [companyName, setCompanyName] = useState('')
  const [isInvitingPilot, setIsInvitingPilot] = useState(false)
  const history = useHistory()

  const query = useQuery()
  const isSupplier = useMemo(() => account?.roles.contactType === 'supplier_admin')
  const isInvitingMyself = useMemo(
    () => query.get('invite-myself') === 'true' && !isPilot && isSupplier,
    [query, isPilot, isSupplier]
  )

  async function getCompanyName() {
    const { companyName } = await getCompanyAndOrganizationId()
    setCompanyName(companyName)
  }

  useEffect(() => {
    pilotCategoryModule.filterPilotCategories(null, { filterQuery: { limit: 1000 } })
    pilotCategoryModule.fetchPilotCategories(null, {
      turnPage: false,
      type: organization.supplierType,
      limit: 1000,
    })
    getCompanyName()
    pilotModule.resetUploadAvatar()
    return () => {
      pilotCategoryModule.resetPagination()
    }
  }, [])

  const initialValues = {
    avatar: '',
    firstName: !isInvitingMyself ? '' : account.firstName,
    lastName: !isInvitingMyself ? '' : account.lastName,
    phoneNumber: !isInvitingMyself ? '' : account.phoneNumber,
    email: !isInvitingMyself ? '' : account.email,
    pilotCategoryId: '',
    companyName,
    terms: false,
    serviceRadius: 50,
    address: {},
    serviceCategories: [],
    equipments: [],
  }

  const invitePilotProps = {
    pilotCategoriesList,
    initialValues,
    isInvitingPilot,
    setAvatarFile: ([file]) => {
      pilotModule.setAvatarFile(null, file)
    },
    handleSubmit: async (values) => {
      setIsInvitingPilot(true)
      let company
      if (isInvitingMyself) {
        company = await inviteMyselfAsPilot(null, {
          userDetails: values,
        })
      } else {
        company = await inviteCompanyPilot(null, {
          companyId,
          userDetails: values,
        })
      }
      if (company) {
        toaster.success('Invite pilot success!')
        if (isInvitingMyself) {
          await refetchAccount()
        }
        history.push(`/pilot/${company._id}`)
      }
      setIsInvitingPilot(false)
    },
  }

  return <InvitePilot {...invitePilotProps} isInvitingMyself={isInvitingMyself} />
}

export { ConnectedInvitePilot }
