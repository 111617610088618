import { Form } from 'formik'

import { Spinner } from '../../common/Spinner'

import { Button as ButtonBase } from 'components/common/Button'
import { FieldGroup, Row, TextField, SelectField } from 'components/company/common'
import { SUPPLIER_TYPES_DISPLAY_NAMES } from 'constants/supplier'
import { styled, apply } from 'lib/styled'

const RegisterButton = styled(ButtonBase)(
  apply('p-2 flex justify-center items-center', {
    boxSizing: 'content-box',
    borderRadius: '8px',
    width: '97%',
  })
)

function RegisterForm({ values, setFieldValue, submiting }) {
  const pilotServiceOptions = Object.entries(SUPPLIER_TYPES_DISPLAY_NAMES).map(
    ([value, label]) => ({
      label,
      value: value.split(','),
    })
  )

  return (
    <Form>
      <FieldGroup title="Company">
        <Row>
          <TextField
            name="organizationDetails.companyName"
            type="text"
            label="Company Name"
            required
          />
        </Row>
        <Row>
          <TextField
            name="organizationDetails.companyWebsite"
            type="text"
            label="Website"
            required
          />
        </Row>
        <Row>
          <SelectField
            name="organizationDetails.companyServiceType"
            label="Service Type"
            value={SUPPLIER_TYPES_DISPLAY_NAMES[values.companyServiceType]}
            options={pilotServiceOptions}
            emptyOption={{ value: undefined, label: '' }}
            onChange={({ value }) => {
              setFieldValue('organizationDetails.companyServiceType', value)
            }}
            isSearchable={false}
            required
          />
        </Row>
      </FieldGroup>
      <FieldGroup title="Key Contact">
        <Row>
          <TextField
            name="userDetails.firstName"
            type="text"
            label="First Name"
            required
          />
          <TextField name="userDetails.lastName" type="text" label="Last Name" />
        </Row>
        <Row>
          <TextField name="userDetails.email" type="text" label="Email" required />
          <TextField
            name="userDetails.phoneNumber"
            type="text"
            label="Phone"
            required
          />
        </Row>
      </FieldGroup>
      <RegisterButton type="submit" disabled={submiting}>
        {submiting ? <Spinner size={32} thickness={6} color="#D9D9D9" /> : 'Register'}
      </RegisterButton>
    </Form>
  )
}

export { RegisterForm }
