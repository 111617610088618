import { useContext } from 'react'

import { FormLayoutContext } from 'components/common/context/FormLayoutContext'
import { DynamicFields } from 'components/common/Fields/DynamicFields'
import { FieldGroup, TextField, Row } from 'components/company/common'
import { styled, apply } from 'lib/styled'
import { getFieldArrayName } from 'utils/getFieldArrayName'

function EquipmentForm({ values, name, title }) {
  const { editing } = useContext(FormLayoutContext)
  const Container = styled.div(apply('my-8'))
  return (
    <Container>
      <FieldGroup title={title}>
        <DynamicFields
          name={name}
          values={values}
          emptyValues={{
            name: '',
            serialNumber: '',
          }}
          labelFirstFieldOnly
          editing={editing}
        >
          {({ name: equipmentName, serialNumber }, i) => {
            const isFirstElement = i === 0
            return (
              <Row>
                <TextField
                  label={isFirstElement ? 'Equipment Name' : undefined}
                  value={equipmentName}
                  name={getFieldArrayName(name, i, 'name')}
                />
                <TextField
                  label={isFirstElement ? 'Serial Number' : undefined}
                  value={serialNumber}
                  name={getFieldArrayName(name, i, 'serialNumber')}
                />
              </Row>
            )
          }}
        </DynamicFields>
      </FieldGroup>
    </Container>
  )
}

export { EquipmentForm }
