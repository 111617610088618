import { useFormikContext } from 'formik'
import { useContext, useLayoutEffect } from 'react'

import { Avatar } from 'components/common/Avatar'
import { Button } from 'components/common/Button'
import { FormLayoutContext } from 'components/common/context/FormLayoutContext'
import { ImageUpload } from 'components/common/ImageUpload'
import { FieldGroup, Row, TextField, SelectField } from 'components/company/common'
import { styled, apply } from 'lib/styled'
import { formatCurrency } from 'utils/formatCurrency'
import { formatPilotCategories } from 'utils/formatPilotCategories'

const Column = styled.div(apply('flex-1 flex flex-column'))
const Details = styled.div(apply('flex-1 flex flex-row'))

function PersonalDetails({
  id,
  userId,
  pilotCategoriesList,
  values,
  setEditing,
  showButtonEdit,
  pilotModule,
}) {
  const { editing } = useContext(FormLayoutContext)
  const { setFieldValue } = useFormikContext()
  const pilotCategoryOptions = pilotCategoriesList.map(({ id, description, rate }) => ({
    id,
    value: id,
    label: `${formatPilotCategories(description)} ${formatCurrency(rate)} / hr`,
  }))

  useLayoutEffect(() => {
    pilotModule.resetUploadAvatar()
  }, [id])

  function onUploadAvatar([file]) {
    pilotModule.uploadAvatar(null, file, userId)
  }

  return (
    <FieldGroup
      title={'Personal Details'}
      extra={
        !editing && showButtonEdit ? (
          <Button onClick={() => setEditing(true)}>Edit</Button>
        ) : null
      }
    >
      <Details>
        <Column style={apply('items-center w-10 mr-6', { flex: 'none' })}>
          <ImageUpload
            disabled={!editing}
            imageSrc={values.userDetails.avatar}
            name="userDetails.avatar"
            ImageComponent={Avatar}
            imageProps={{
              size: 96,
              placeholderSize: 80,
              style: apply('m-0'),
            }}
            onUpload={onUploadAvatar}
          />
        </Column>

        <Column>
          <Row>
            <TextField
              label="First Name"
              value={values.userDetails.firstName}
              disabled={false}
              required
              name="userDetails.firstName"
            />
            <TextField
              label="Last Name"
              value={values.userDetails.lastName}
              name="userDetails.lastName"
            />
          </Row>
          <Row>
            <TextField
              label="Phone Number"
              value={values.userDetails.phoneNumber}
              required
              name="userDetails.phoneNumber"
            />
            <TextField
              label="Email"
              value={values.userDetails.email}
              disabled={true}
              name="userDetails.email"
            />
          </Row>
          <Row>
            <TextField
              label="Company Name"
              value={values.userDetails.companyName}
              disabled
              name="userDetails.companyName"
            />

            <SelectField
              label="Pilot Category"
              value={
                editing
                  ? values.userDetails.pilotCategory
                  : formatPilotCategories(values.userDetails.pilotCategoryName)
              }
              name="userDetails.pilotCategory"
              options={pilotCategoryOptions}
              emptyOption={{ value: undefined, label: '' }}
              isSearchable={false}
              onChange={({ value }) => {
                setFieldValue('userDetails.pilotCategory', value)
              }}
              required
            />
          </Row>
        </Column>
      </Details>
    </FieldGroup>
  )
}

export { PersonalDetails }
