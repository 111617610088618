import CONSTANTS from '@seedcloud/constants'
import { omit, pick } from '@seedcloud/ramda-extra'
import { connect } from '@seedcloud/stateless'
import { useLayoutEffect, useState } from 'react'
import * as Validator from 'yup'

import { CompanyDetail } from 'components/company/CompanyDetail/CompanyDetail'
import { SUPPLIER_TYPES } from 'constants/supplier'
import { API_TYPES } from 'lib/http-client'
import { useIdentity } from 'lib/solta-id-react'
import { createCompanyModule } from 'modules/company'
import { createOrganizationModule } from 'modules/organization'
import { getFilename } from 'utils/getFilename'
import { useEntityType } from 'utils/hooks/useEntityType'
import { optionalString, requiredString } from 'utils/validation'

const {
  module: companyModule,
  selectors: { companyDetail$, invitationStatus$, isInspectingCompany$, isSubmitting$ },
} = createCompanyModule(API_TYPES.WEB)

const { module: organizationModule } = createOrganizationModule(API_TYPES.WEB)

const { object } = Validator

const companyDetailValidationSchema = object({
  companyName: requiredString('Company Name'),
  abn: requiredString('ABN').matches(/^\d+$/, 'ABN should only contain number'),
  phoneNumber: requiredString('Phone number').matches(
    CONSTANTS.REGEX.E164_PHONE_FORMAT,
    'Please include a country code (e.g +64213112)'
  ),
  website: requiredString('Company Website').url(
    'Please input full url (e.g https://example.com)'
  ),
  address: optionalString('Company Address'),
  commercial: object().shape({
    firstName: requiredString('Key Contact First Name'),
    lastName: optionalString('Key Contact Last Name'),
    phoneNumber: requiredString('Commercial Phone number').matches(
      CONSTANTS.REGEX.E164_PHONE_FORMAT,
      'Please include a country code (e.g +64213112)'
    ),
    email: requiredString('Commercial Email').email('Not a valid email address'),
  }),
  billing: object().shape({
    firstName: optionalString('Billing Contact First Name'),
    lastName: optionalString('Billing Contact Last Name'),
    phoneNumber: optionalString('Billing Phone number').matches(
      CONSTANTS.REGEX.E164_PHONE_FORMAT,
      'Please include a country code (e.g +64213112)'
    ),
    email: optionalString('Billing Email').email('Not a valid email address'),
  }),
  heroPilot: object().shape({
    firstName: optionalString('Hero Pilot First Name'),
    lastName: optionalString('Hero Pilot Last Name'),
    phoneNumber: optionalString('Hero Pilot Phone number').matches(
      CONSTANTS.REGEX.E164_PHONE_FORMAT,
      'Please include a country code (e.g +64213112)'
    ),
    email: optionalString('Hero Pilot Email').email('Not a valid email address'),
  }),
})

const CompanyDetailContainer = ({
  isInspectingCompany,
  isInspectingOrganization,
  isSubmitting,
  companyDetail = {},
  status,
}) => {
  const { isInternalEntity } = useEntityType()
  const { organization, role } = useIdentity()
  const { logo } = organization

  useLayoutEffect(() => {
    companyModule.inspectCompany()
  }, [])

  const [editing, setEditing] = useState(false)

  const {
    companyName,
    abn,
    website,
    address,
    phoneNumber,
    commercial,
    billing,
    heroPilot,
    supplierType,
  } = companyDetail

  const initialValues = {
    companyName,
    abn,
    phoneNumber,
    website,
    address,
    supplierType,
    commercial,
    billing,
    heroPilot,
    logo: getFilename(logo),
  }

  const loading = isInspectingCompany && isInspectingOrganization

  const companyDetailProps = {
    isAdmin: false,
    isInternalEntity,
    isInspectingCompany,
    isSubmitting,
    companyDetail,
    initialValues,
    validationSchema: companyDetailValidationSchema,
    editing,
    setEditing,
    loading,
    status,
    organization,
    role,
    handleSubmit: async (values) => {
      const payload = omit(['logo'], values)

      if (values?.supplierType === SUPPLIER_TYPES.DATA_ANALYST) {
        payload.heroPilot = undefined
      }

      const requests = [companyModule.updateCompany(null, payload)]

      if (isInternalEntity) {
        requests.push(
          organizationModule.updateOrganization(null, pick(['logo'], values))
        )
      }

      await Promise.all(requests)
      setEditing(false)
    },
  }

  return <CompanyDetail {...companyDetailProps} />
}

export const ConnectedCompanyDetail = connect(() => ({
  companyDetail: companyDetail$,
  isInspectingCompany: isInspectingCompany$,
  isSubmitting: isSubmitting$,
  status: invitationStatus$,
}))(CompanyDetailContainer)
