import { BaseBadge } from 'components/common/Badge'
import {
  PILOT_STATUS,
  PILOT_STATUS_DISPLAY,
  PILOT_APPLICATION_STATUS,
  PILOT_APPLICATION_STATUS_DISPLAY,
} from 'constants/pilots'

export const variants = {
  [PILOT_STATUS.ACTIVE]: {
    backgroundColor: '#E0F0E8',
    color: '#005027',
  },
  [PILOT_STATUS.PENDING]: {
    backgroundColor: '#FAF7E7',
    color: '#AC9100',
  },
}
export const pilotAppVariants = {
  [PILOT_APPLICATION_STATUS.ACCEPTED]: {
    backgroundColor: '#E0F0E8',
    color: '#005027',
  },
  [PILOT_APPLICATION_STATUS.INCOMPLETE]: {
    backgroundColor: '#FAF7E7',
    color: '#AC9100',
  },
  [PILOT_APPLICATION_STATUS.SUBMITTED]: {
    backgroundColor: '#FAF7E7',
    color: '#AC9100',
  },
  [PILOT_APPLICATION_STATUS.REJECTED]: {
    backgroundColor: '#E7BABB',
    color: 'red',
  },
}

const PilotBadge = ({ isActive }) => {
  const status = isActive ? PILOT_STATUS.ACTIVE : PILOT_STATUS.PENDING
  const labelText = PILOT_STATUS_DISPLAY[status]
  return (
    <BaseBadge status={status} variants={variants}>
      {labelText}
    </BaseBadge>
  )
}

const PilotApplicationBadge = ({ pilotAppStatus }) => {
  const labelText = PILOT_APPLICATION_STATUS_DISPLAY[pilotAppStatus]
  return (
    <BaseBadge status={pilotAppStatus} variants={pilotAppVariants}>
      {labelText}
    </BaseBadge>
  )
}

export { PilotBadge, PilotApplicationBadge }
