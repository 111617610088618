import { isNotNilOrEmpty, path } from '@seedcloud/ramda-extra'
import moment from 'moment'
import { useMemo } from 'react'

import { PUBLISH_TYPES, PUBLISH_TYPES_DISPLAY_NAMES } from 'constants/publishType'
import { formatCurrency } from 'utils/formatCurrency'

const toHumanReadableDateWithTime = (date) =>
  date ? moment(date).format('DD/MM/YY hh:mm A') : ''
const toHumanReadableDate = (date) => (date ? moment(date).format('DD/MM/YY') : '')

const getPublishToRow = (job) => {
  const publishType = path(['publishType'], job)
  const selectedPilot = path(['selectedPilot', 'userId', 'firstName'], job)
  const selectedSupplier = path(['selectedSupplier', 'name'], job)

  switch (publishType) {
    case PUBLISH_TYPES.INTERNAL:
      return isNotNilOrEmpty(selectedPilot)
        ? `Internal: ${selectedPilot}`
        : 'All Internal Pilots'
    case PUBLISH_TYPES.SUPPLIER:
      return isNotNilOrEmpty(selectedSupplier)
        ? `Supplier: ${selectedSupplier}`
        : 'All Supplier'
    default:
      return `${PUBLISH_TYPES_DISPLAY_NAMES[PUBLISH_TYPES.NETWORK]} Hub`
  }
}

export const Rows = (jobList) =>
  useMemo(() =>
    jobList.map((job) => ({
      id: job?.id,
      reference: job?.reference,
      product: job?.product?.name,
      orderedBy: job?.orderedBy?.fullName,
      clientMobile: job?.orderedBy?.phoneNumber,
      customer: job?.selectedCustomer?.contactName,
      description: job?.description,
      pilotName: job?.engagedBy?.fullName,
      pilotMobile: job?.engagedBy?.phoneNumber,
      location: job?.address,
      uploadCode: job?.uploadCode,
      budget: formatCurrency(job?.pricing?.budget),
      publishedTo: getPublishToRow(job),
      createdAt: toHumanReadableDate(job?.createdAt),
      scheduledAt: toHumanReadableDate(job?.scheduledAt),
      engagedAt: toHumanReadableDateWithTime(job?.engagedAt),
      startedAt: toHumanReadableDateWithTime(job?.startedAt),
      finishedAt: toHumanReadableDateWithTime(job?.finishedAt),
      cancelledAt: toHumanReadableDateWithTime(job?.rejectedAt),
    }))
  )
