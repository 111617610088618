import CONSTANTS from '@seedcloud/constants'
import { prop, values } from '@seedcloud/ramda-extra'
import { Formik as FormProvider } from 'formik'
import { useHistory } from 'react-router-dom'
import * as Validator from 'yup'

import { InvitePilotForm } from './InvitePilotForm'

import { FormLayoutProvider } from 'components/common/context/FormLayoutContext'
import { FormAction } from 'components/company/common'
import { LICENCE_TYPES } from 'constants/licenceTypes'
import { SERVICE_CATEGORIES } from 'constants/serviceCategories'
import { styled, apply } from 'lib/styled'
import { mapboxResultSchema } from 'utils/hooks/preProcessResults'
import { requiredString, optionalString, dateNowMinimum } from 'utils/validation'

const { array, string, object, boolean, number } = Validator

const baseValidationSchema = {
  avatar: string().optional(),
  firstName: requiredString('First Name'),
  lastName: optionalString('Last Name'),
  phoneNumber: optionalString('Phone number').matches(
    CONSTANTS.REGEX.E164_PHONE_FORMAT,
    'Please include a country code (e.g +64)'
  ),
  email: requiredString('Email').email('Not a valid email address'),
  pilotCategoryId: requiredString('Pilot Category'),
}

const validationSchemaWithTerms = object({
  ...baseValidationSchema,
  terms: boolean().oneOf([true], 'You must accept Terms and Condition to continue'),
})

const validationSchemaMySelf = object({
  ...baseValidationSchema,
  licenceType: optionalString('Licence type').oneOf(values(LICENCE_TYPES)),
  licenceNumber: optionalString('Licence number'),
  licenceExpiredAt: dateNowMinimum().label('Licence expiry'),
  serviceCategories: array()
    .of(string().oneOf(SERVICE_CATEGORIES.map(prop('value'))))
    .min(1)
    .label('Service categories'),
  serviceRadius: number().required().label('Radius'),
  address: mapboxResultSchema.required(),
})

const Container = styled.div(
  apply('flex-1 grid bg-white rounded-lg shadow-sm', {
    gridTemplateRows: '1fr auto',
  })
)

const Scrollable = styled.div(
  apply('flex flex-column p-6', {
    overflow: 'auto',
    maxHeight: '100%',
  })
)

function InvitePilot({
  pilotCategoriesList,
  initialValues,
  handleSubmit,
  isInvitingPilot,
  setAvatarFile,
  isInvitingMyself = false,
}) {
  const history = useHistory()
  return (
    <Container>
      <FormLayoutProvider value={{ editing: true }}>
        <FormProvider
          id="createPilot"
          enableReinitialize
          initialValues={initialValues}
          validationSchema={
            isInvitingMyself ? validationSchemaMySelf : validationSchemaWithTerms
          }
          onSubmit={(values) => {
            handleSubmit(values)
          }}
        >
          {({ resetForm, values, submitForm }) => (
            <>
              <Scrollable>
                <InvitePilotForm
                  setAvatarFile={setAvatarFile}
                  pilotCategoriesList={pilotCategoriesList}
                  values={values}
                  isInvitingMyself={isInvitingMyself}
                />
              </Scrollable>
              <FormAction
                loading={isInvitingPilot}
                loadingText="Inviting"
                okText="Create"
                onCancel={() => {
                  resetForm()
                  history.push('/pilots')
                }}
                onOK={() => {
                  submitForm()
                }}
              />
            </>
          )}
        </FormProvider>
      </FormLayoutProvider>
    </Container>
  )
}

export { InvitePilot }
