import { DateField } from 'components/common/DateField'
import { Row, TextField, SelectField } from 'components/company/common'
import { LICENCE_TYPE_DISPLAY_NAMES } from 'constants/licenceTypes'
import { styled, apply } from 'lib/styled'

const Details = styled.div(apply('flex-1 flex flex-row'))
const Column = styled.div(apply('flex-1 flex flex-column'))
const TextLabel = styled.span({
  fontSize: '0.8125rem',
  color: '#555555',
  fontWeight: 400,
  marginBottom: 8,
})

export function AviationDetailFields() {
  const licenceTypes = Object.entries(LICENCE_TYPE_DISPLAY_NAMES).map(
    ([value, label]) => ({
      label,
      value,
    })
  )

  return (
    <Details>
      <Column style={apply('items-center w-10 mr-6', { flex: 'none' })} />
      <Column>
        <h2 style={{ fontSize: 21, fontWeight: 800, marginBottom: 16 }}>
          Aviation Detail
        </h2>
        <Row>
          <SelectField label="Licence Type" name="licenceType" options={licenceTypes} />
        </Row>
        <Row>
          <Column>
            <TextField name="licenceNumber" type="text" label="License Number" />
          </Column>
          <Column>
            <TextLabel>Lisence Expiry</TextLabel>
            <DateField
              name="licenceExpiry"
              type="text"
              aria-label="Lisence Expiry"
              autoOk
              rootStyle={apply('w-full', { flex: 'initial' })}
            />
          </Column>
        </Row>
      </Column>
    </Details>
  )
}
