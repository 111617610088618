/* eslint-disable complexity */
import { Formik as FormProvider } from 'formik'

import { EquipmentForm } from '../common/Fields/Equipment'
import { ServiceArea } from '../common/Fields/ServiceArea'

import { MyPersonalDetails } from './MyPersonalDetails'
import { MyPilotAndAviationDetail } from './MyPilotAndAviationDetails'
import { MyPilotDetailsLayout } from './MyPilotDetailsLayout'

import CloudLayoutImage from 'assets/clouds_container.svg'
import { Button, CancelButton } from 'components/common/Button'
import { FormLayoutProvider } from 'components/common/context/FormLayoutContext'
import { Tab } from 'components/common/Tabs/Tab'
import { FormAction, NormalTabs } from 'components/company/common'
import { PILOT_APPLICATION_STATUS } from 'constants/pilots'
import { styled, apply } from 'lib/styled'

const Layout = styled.form(
  apply('flex flex-column w-full bg-white rounded-xl shadow-sm relative'),
  {
    overflow: 'hidden',
  }
)

const DetailsContainer = styled.div(apply('px-8 py-8 relative'), {
  overflowY: 'auto',
  height: '100%',
})

const CloudBackground = styled.div({
  height: '220px',
  backgroundImage: `url('${CloudLayoutImage}')`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'bottom right',
  marginRight: '-24px',
  marginBottom: '-24px',
})

const NextButtonWrapper = styled.div(apply('p-6 flex flex-end'), {
  borderTop: '1px solid #CCCCCC',
  justifyContent: 'flex-end',
  '& > button': {
    width: '49.2%',
  },
})

const SubmitButtonWrapper = styled.div(apply('p-6 flex items-stretch'), {
  borderTop: '1px solid #CCCCCC',
  '& > button': {
    width: '50%',
  },
  gap: '1.5rem',
})

const ValidationWarningWrapper = styled.div(apply('p-2'), {
  backgroundColor: 'rgba(245, 0, 0, 0.24)',
  textAlign: 'center',
  color: 'red',
})

function MyPilotDetails({
  initialValues,
  validationSchema,
  editing,
  setEditing,
  step,
  setStep,
  id,
  userId,
  // paramsId,
  avatar,
  pilotCategoriesList,
  isUpdatingIndividualPilot,
  pilotModule,
  isUploadingAvatar,
  loading,
  pilotApplicationStatus,
  onSubmit,
}) {
  if (pilotApplicationStatus === PILOT_APPLICATION_STATUS.INCOMPLETE) {
    setEditing(true)
  }
  const nextStep = () => {
    setStep((prev) => {
      if (prev < 1) {
        return prev + 1
      }
      return prev
    })
  }
  const prevStep = () => {
    setStep((prev) => {
      if (prev > 0) {
        return prev - 1
      }
      return prev
    })
  }
  const validationWarning = 'PLEASE FILL ALL REQUIRED FIELDS!'

  if (loading) return <MyPilotDetailsLayout disableTabs loading />

  return (
    <FormLayoutProvider value={{ editing }}>
      <FormProvider
        id="myPilotDetails"
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {({ values, resetForm, handleSubmit, isValid }) => (
          <Layout>
            <NormalTabs
              numberOfTabs={2}
              isDisabled={
                pilotApplicationStatus === PILOT_APPLICATION_STATUS.INCOMPLETE
              }
              selectedTab={step}
            >
              <Tab title="Personal Details">
                <DetailsContainer>
                  <MyPersonalDetails
                    id={id}
                    userId={userId}
                    avatarSrc={avatar}
                    pilotCategoriesList={pilotCategoriesList}
                    values={values}
                    setEditing={setEditing}
                    showButtonEdit={
                      pilotApplicationStatus === PILOT_APPLICATION_STATUS.ACCEPTED
                    }
                    pilotModule={pilotModule}
                    pilotApplicationStatus={pilotApplicationStatus}
                  />
                  {!loading ? <CloudBackground /> : null}
                </DetailsContainer>
              </Tab>
              <Tab title="Aviation Details">
                <DetailsContainer>
                  <MyPilotAndAviationDetail
                    showButtonEdit={
                      pilotApplicationStatus === PILOT_APPLICATION_STATUS.ACCEPTED
                    }
                    setEditing={setEditing}
                    values={values}
                  />
                  <EquipmentForm
                    values={values.equipments}
                    name="equipments"
                    title="Equipment Form"
                    required
                  />
                  <ServiceArea />
                  {/* {documents && !isAdmin ? (
                    <DocumentsPanel
                      documents={documents}
                      ownerId={id}
                      onDownloadClick={pilotModule.downloadDocument}
                    />
                  ) : null} */}
                  {!loading ? <CloudBackground /> : null}
                </DetailsContainer>
              </Tab>
            </NormalTabs>
            {editing &&
              pilotApplicationStatus === PILOT_APPLICATION_STATUS.ACCEPTED && (
                <>
                  {!isValid && (
                    <ValidationWarningWrapper>
                      {validationWarning}
                    </ValidationWarningWrapper>
                  )}
                  <FormAction
                    loading={isUpdatingIndividualPilot || isUploadingAvatar}
                    loadingText={
                      isUpdatingIndividualPilot ? 'Updating...' : 'Uploading...'
                    }
                    okText="Save"
                    onCancel={() => {
                      resetForm()
                      setEditing(false)
                    }}
                    onOK={handleSubmit}
                  />
                </>
              )}
            {step === 0 &&
              pilotApplicationStatus === PILOT_APPLICATION_STATUS.INCOMPLETE && (
                <>
                  {!isValid && (
                    <ValidationWarningWrapper>
                      {validationWarning}
                    </ValidationWarningWrapper>
                  )}
                  <NextButtonWrapper>
                    <Button type="button" disabled={loading} onClick={nextStep}>
                      {loading ? 'Loading' : 'Next'}
                    </Button>
                  </NextButtonWrapper>
                </>
              )}
            {step === 1 &&
              pilotApplicationStatus === PILOT_APPLICATION_STATUS.INCOMPLETE && (
                <>
                  {!isValid && (
                    <ValidationWarningWrapper>
                      {validationWarning}
                    </ValidationWarningWrapper>
                  )}
                  <SubmitButtonWrapper>
                    <CancelButton type="button" disabled={loading} onClick={prevStep}>
                      {loading ? 'Loading' : 'Back'}
                    </CancelButton>
                    <Button type="submit" disabled={loading} onClick={handleSubmit}>
                      {loading ? 'Loading' : 'Submit Details'}
                    </Button>
                  </SubmitButtonWrapper>
                </>
              )}
          </Layout>
        )}
      </FormProvider>
    </FormLayoutProvider>
  )
}

export { MyPilotDetails }
