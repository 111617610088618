import CONSTANTS from '@seedcloud/constants'
import { isNilOrEmpty } from '@seedcloud/ramda-extra'
import { connect } from '@seedcloud/stateless'
import { Formik as FormProvider, Form } from 'formik'
import { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import * as Validator from 'yup'

import { CompanyDetailsForm } from './CompanyDetailsForm'

import { Loader } from 'components/common/Loader'
import { Spinner } from 'components/common/Spinner'
import { TabEditing } from 'components/common/TabEditing'
import { API_TYPES } from 'lib/http-client'
import { styled, apply } from 'lib/styled'
import toast from 'lib/toaster'
import { createCompanyModule } from 'modules/legacy-company'

const {
  module: companyModule,
  selectors: { inspectedCompany$, isInspectingCompany$, isUploadingImage$ },
} = createCompanyModule(API_TYPES.ADMIN)

const { object, string } = Validator
const requiredString = string().required('This field is required')
const optionalString = string().nullable().optional()

const validationSchema = object({
  name: optionalString,
  abn: optionalString,
  address: optionalString.label('Address'),
  user: object({
    firstName: requiredString,
    lastName: optionalString,
    phoneNumber: requiredString.matches(
      CONSTANTS.REGEX.E164_PHONE_FORMAT,
      'Please input phone number with country code ex: +6123123'
    ),
    email: requiredString.email('Not a valid email address'),
  }),
})

const Container = styled.div(
  apply('flex-1 flex flex-column bg-white rounded-lg shadow-sm', {
    overflow: 'auto',
  })
)
const Header = styled.div(apply('flex flex-row pt-4'))
const Scrollable = styled.div(
  apply('flex flex-column px-6 pb-6', {
    minHeight: 'min-content',
  })
)
const SpinnerWrapper = styled.div(
  apply('flex justify-center items-center', {
    minWidth: '92px',
    minHeight: '92px',
  })
)

const { inspectCustomer, resetCompanyFilter, fetchCustomers } = companyModule

const ConnectedCompanyDetails = connect(() => ({
  isInspectingCompany: isInspectingCompany$,
  inspectedCompany: inspectedCompany$,
  isUploadingImage: isUploadingImage$,
}))(CompanyDetails)

function CompanyDetails({
  companyId,
  isInspectingCompany,
  companyEntities,
  isUploadingImage,
  inspectedCompany,
  isModal = false,
  ...props
}) {
  const [isEditing, setIsEditing] = useState(false)
  const [reRenderKey, setReRenderKey] = useState(false)

  const location = useLocation()
  const params = useParams()
  const id = params.companyId || companyId
  const isNew = location?.state?.isNew

  useEffect(() => {
    setIsEditing(false)
  }, [id])

  useEffect(() => {
    inspectCustomer(id)
  }, [id])

  useEffect(() => {
    if (isNew) {
      resetCompanyFilter()
      fetchCustomers(null, { turnPage: false })
    }
  }, [isNew])

  if (isNilOrEmpty(inspectedCompany)) return null

  const { abn, address, company, isActive, user } = inspectedCompany

  return (
    <Container {...props}>
      <FormProvider
        id="companyDetails"
        enableReinitialize
        initialValues={{
          name: company.companyName,
          abn,
          // eslint-disable-next-line camelcase
          address,
          user: {
            firstName: user.firstName,
            lastName: user.lastName,
            phoneNumber: user.phoneNumber,
            email: user.email,
          },
        }}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          await companyModule.updateCustomer(id, values).then((customer) => {
            if (customer) {
              toast.success('Customer Updated Successfully!')
            }
          })
          setIsEditing(false)
        }}
      >
        <Loader
          loading={isInspectingCompany}
          renderPlaceholder={
            <SpinnerWrapper>
              <Spinner size={64} thickness={7} style={{ padding: '8px' }} />
            </SpinnerWrapper>
          }
        >
          <Scrollable>
            <Form key={reRenderKey}>
              <Header>
                {!isModal && (
                  <TabEditing
                    style={{ marginLeft: 'auto' }}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    disabled={isUploadingImage}
                    customHandleCancel={() => setReRenderKey(!reRenderKey)}
                  />
                )}
              </Header>
              <CompanyDetailsForm
                id={id}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                isActive={isActive}
                isModal={isModal}
              />
            </Form>
          </Scrollable>
        </Loader>
      </FormProvider>
    </Container>
  )
}

export { ConnectedCompanyDetails as CompanyDetails }
