import { useLocation, useParams } from 'react-router-dom'

import { Tabs } from 'components/common/Tabs'
import { SupplierLayout } from 'components/supplier/common/SupplierLayout'
import { SUPPLIER_TYPES } from 'constants/supplier'
import { styled } from 'lib/styled'

const StyledTab = styled(Tabs)({
  width: '400px',
  '> a': {
    borderBottom: 'none',
  },
})

const TabsContainer = styled.div({
  marginBottom: '41px',
  borderBottom: '1px solid #CCCCCC',
})

const SupplierCategoriesLayout = ({ children, company, loading, status, type }) => {
  const { search } = useLocation()
  const { id } = useParams()

  const tabs = [
    {
      title: 'Categories',
      path: `/supplier/${id}/ratecard/categories${search}`,
      id: 'categories',
    },
    {
      title: 'Equipment Rates',
      path: `/supplier/${id}/ratecard/equipment${search}`,
      id: 'equipment',
    },
  ]

  return (
    <SupplierLayout company={company} loading={loading} status={status} type={type}>
      {type !== SUPPLIER_TYPES.DATA_ANALYST ? (
        <>
          <TabsContainer>
            <StyledTab tabs={tabs} defaultSelectedId="categories" pathLength={5} />
          </TabsContainer>
          {children}
        </>
      ) : (
        children
      )}
    </SupplierLayout>
  )
}

export { SupplierCategoriesLayout }
