/* eslint-disable camelcase */
import CONSTANTS from '@seedcloud/constants'
import { Formik as FormProvider, Form, useField } from 'formik'
import { memo, useEffect, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import * as Validator from 'yup'

import { NavigationTab } from './NavigationTab'
import { Actions, JobInfo } from './sections'

import { useJobs } from 'components/common/context/JobContext'
import { Spinner } from 'components/common/Spinner'
import radiusValidator from 'components/jobs/common/radiusValidator'
import { JOB_STATUSES } from 'constants/jobs'
import { ROLES } from 'constants/roles'
import { useIdentity } from 'lib/solta-id-react'
import { apply, styled } from 'lib/styled'

const Container = styled.div(
  apply('flex flex-column w-full bg-white rounded-lg shadow-sm pb-8')
)

const Scrollable = styled.div(
  apply('flex flex-column h-full px-8', {
    overflowY: 'scroll',
  })
)

const { boolean, number, object, string } = Validator

const optionalString = string().nullable().optional()

const validationSchema = object({
  status: optionalString,
  jobInfo: object({
    reference: optionalString,
    createdAt: optionalString,
    scheduledAt: optionalString.label('scheduledAt'),
    startedAt: optionalString.label('startedAt'),
    engagedAt: optionalString.label('engagedAt'),
    description: optionalString,
    instructions: optionalString,
    uploadCode: optionalString,
    product: object({
      name: optionalString,
      id: optionalString,
    }),
    project: object({
      reference: string().optional(),
      id: string().optional(),
    }).optional(),
    location: object().optional().label('location'),
    radius: radiusValidator.optional(),
    finishedAt: optionalString
      .when('startedAt', {
        is: (startedAt) => startedAt !== 'Unspecified',
        then: string().test(
          'is-earlier-than-startedAt',
          'End time should be later than start time',
          (finishedAt, context) => {
            if (finishedAt !== 'Unspecified') {
              return new Date(finishedAt) > new Date(context.parent.startedAt)
            }
            return true
          }
        ),
      })
      .label('finishedAt'),
    customer: object({
      companyName: optionalString,
      companyEmail: optionalString.email('Not a valid email address'),
      companyNumber: optionalString.matches(
        CONSTANTS.REGEX.E164_PHONE_FORMAT,
        'Please include a country code (e.g +64213112)'
      ),
    }),
    pricing: object({
      timeMaterial: boolean(),
      quote: boolean(),
      budget: number().nullable().min(1, 'The minimum budget is 1'),
    }),
  }),
  contactInfo: object({
    client: object({
      id: optionalString,
      name: optionalString,
      mobile: optionalString,
      email: optionalString,
    }),

    pilot: object({
      id: optionalString,
      name: optionalString,
      mobile: optionalString,
      email: optionalString,
    }),
  }),
})

function BaseJobDetails() {
  const { role } = useIdentity()
  const history = useHistory()
  const {
    isEditing,
    setIsEditing,
    jobStatus,
    showEditingTab,
    showDuplicateButton,
    initialValues,
    onUpdateJob,
    loading,
    inspectedJob,
    onDuplicateJob,
  } = useJobs()

  const showEditOnEngaged = role === ROLES.PLATFORM_ADMIN

  const {
    jobInfo: { publishType, publishedAt },
  } = initialValues

  if (loading) {
    return (
      <Container>
        <Spinner size={64} thickness={7} style={{ margin: 'auto 0' }} />
      </Container>
    )
  }

  return (
    <Container>
      <Scrollable id="scroll">
        <FormProvider
          id="jobDetails"
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={({ status, contactInfo, jobInfo }) => {
            const updateSelectedFields =
              status === JOB_STATUSES.QUEUE && publishedAt !== 'Unspecified'
            onUpdateJob(
              inspectedJob.id,
              { status, contactInfo, jobInfo },
              updateSelectedFields
            )
          }}
        >
          <Form>
            <NavigationTab
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              onGoBackClicked={() => history.push('/jobs')}
              title={jobStatus}
              showEditingTab={
                jobStatus === JOB_STATUSES.ENGAGED ? showEditOnEngaged : showEditingTab
              }
              publishType={publishType}
              showDuplicateButton={showDuplicateButton}
              jobId={inspectedJob.id}
              onDuplicateJob={onDuplicateJob}
            />
            <JobInfo role={role} />
            <Actions />
            <DocumentsFormUpdater />
          </Form>
        </FormProvider>
      </Scrollable>
    </Container>
  )
}

const JobDetails = memo(BaseJobDetails, () => true)

// this component is used for updating form values - values.document
// because we cannot use initialValues since when uploading documents
// documents will updated and update initialvalues and then reset the form
function BaseDocumentsFormUpdater() {
  const { documents } = useJobs()
  const documentsMemoized = useMemo(() => documents, [documents])

  const [, , action] = useField('documents')

  useEffect(() => {
    requestAnimationFrame(() => {
      action.setValue(documentsMemoized, false)
    })
  }, [documentsMemoized])

  return <></>
}

const DocumentsFormUpdater = memo(BaseDocumentsFormUpdater, () => true)

export { JobDetails }
