import { isNotNil } from '@seedcloud/ramda-extra'
import { Formik as FormProvider, Form } from 'formik'

import { Row, TextField } from 'components/company/common'
import { LICENCE_TYPE_DISPLAY_NAMES } from 'constants/licenceTypes'
import { styled, apply } from 'lib/styled'
import { formatDate } from 'utils/formatDate'

const Container = styled.section()
const Details = styled(Form)(apply('flex-1 flex flex-row'))
const Column = styled.div(apply('flex-1 flex flex-column'))

function SkillsAndAbilities({
  licenceType,
  licenceNumber,
  licenceExpiredAt,
  insuranceNumber,
  insuranceExpiredAt,
}) {
  return (
    <Container>
      <FormProvider
        enableReinitialize
        initialValues={{
          licenceType: LICENCE_TYPE_DISPLAY_NAMES[licenceType],
          licenceNumber,
          licenceExpiredAt: formatDate(licenceExpiredAt),
          insuranceNumber,
          insuranceExpiredAt: isNotNil(insuranceExpiredAt)
            ? formatDate(insuranceExpiredAt)
            : undefined,
        }}
      >
        <Details>
          <Column>
            <Row style={apply('w-1/2')}>
              <TextField
                disabled
                name="licenceType"
                type="text"
                label="Licence Type"
                containerProps={{ style: apply('mr-3') }}
                value={LICENCE_TYPE_DISPLAY_NAMES[licenceType]}
              />
            </Row>

            <Row>
              <TextField
                disabled
                name="licenceNumber"
                type="text"
                label="Licence Number"
                value={licenceNumber}
              />

              <TextField
                disabled
                name="licenceExpiredAt"
                type="text"
                label="Licence Expiry"
                value={formatDate(licenceExpiredAt)}
              />
            </Row>

            <Row>
              <TextField
                disabled
                name="insuranceNumber"
                type="text"
                label="Insurance Number (optional)"
                value={insuranceNumber}
              />

              <TextField
                disabled
                name="insuranceExpiredAt"
                type="text"
                label="Insurance Expiry (optional)"
                value={
                  isNotNil(insuranceExpiredAt)
                    ? formatDate(insuranceExpiredAt)
                    : undefined
                }
              />
            </Row>
          </Column>
        </Details>
      </FormProvider>
    </Container>
  )
}

export { SkillsAndAbilities }
