import { useLocation, useParams } from 'react-router-dom'

import { ClaimCompanyLayout, ClaimCompanyCard } from 'components/claim/common'
import { CONFIG } from 'config'
import { apply } from 'lib/styled'
import { getTokenInformation } from 'utils/getTokenInformation'

const { Image, Title, Description, Button, Field } = ClaimCompanyCard

const ClaimCompany = () => {
  const { search } = useLocation()
  const { token } = useParams()
  const { sig } = Object.fromEntries(new URLSearchParams(search))
  const { organizationName, userEmail, companyName } = getTokenInformation(token)

  return (
    <ClaimCompanyLayout>
      <ClaimCompanyCard>
        <Image />
        <Title>Welcome to {organizationName} Portal</Title>
        <Description>
          Hi {userEmail}, please claim your company to get started as a Supplier on the
          NearbySky Network Plan (Free).
        </Description>
        <Field
          name="company"
          value={companyName}
          label="Company"
          id="company"
          disabled
        />
        <a
          style={apply('w-full')}
          href={`${CONFIG.API.URL}/company-claims/${token}?sig=${sig}`}
        >
          <Button>Claim Company</Button>
        </a>
      </ClaimCompanyCard>
    </ClaimCompanyLayout>
  )
}

export { ClaimCompany }
