import { useContext } from 'react'

import { Button } from 'components/common/Button'
import { FormLayoutContext } from 'components/common/context/FormLayoutContext'
import { FieldGroup, Row, TextField } from 'components/company/common'
import { styled, apply } from 'lib/styled'
import { authModule } from 'modules/auth'

const Column = styled.div(apply('flex-1 flex flex-column'))
const Details = styled.div(apply('flex-1 flex flex-row'))

// eslint-disable-next-line complexity
function CustomerDetails({ values, setEditing, showButtonEdit }) {
  const { editing } = useContext(FormLayoutContext)
  return (
    <>
      <FieldGroup
        title={'Profile Details'}
        extra={
          !editing && showButtonEdit ? (
            <Button onClick={() => setEditing(true)}>Edit</Button>
          ) : null
        }
      >
        <Details>
          <Column>
            <Row>
              <TextField
                label="First Name"
                value={values.firstName}
                disabled={false}
                required
                name="firstName"
              />
              <TextField label="Last Name" value={values.lastName} name="lastName" />
            </Row>
            <Row>
              <TextField
                label="Phone Number"
                value={values.phoneNumber}
                required
                name="phoneNumber"
              />
              <TextField label="Email" value={values.email} disabled name="email" />
            </Row>
          </Column>
        </Details>
      </FieldGroup>
      <FieldGroup title={'Company Details'}>
        <Details>
          <Column>
            <Row>
              <TextField
                label="Company Name"
                value={values.organization.name}
                required
                name="organization.name"
              />
              <TextField
                label="ABN"
                value={values.organization.abn}
                required
                name="organization.abn"
              />
            </Row>
            <Row>
              <TextField
                label="Address"
                value={values.organization.address}
                name="organization.address"
              />
            </Row>
          </Column>
        </Details>
      </FieldGroup>
      <FieldGroup title={'Password'}>
        <Button
          type="button"
          onClick={() =>
            authModule.changePassword().then((response) => {
              if (response) {
                window.open(response.url, '_blank')
              }
            })
          }
          disabled={!editing}
        >
          Change Password
        </Button>
      </FieldGroup>
    </>
  )
}

export { CustomerDetails }
