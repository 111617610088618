/* eslint-disable complexity */
import CONSTANTS from '@seedcloud/constants'
import { values, prop } from '@seedcloud/ramda-extra'
import { connect } from '@seedcloud/stateless'
import { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import * as Validator from 'yup'

import { MyPilotDetails } from './MyPilotDetails'

import { LICENCE_TYPES } from 'constants/licenceTypes'
import { PILOT_APPLICATION_STATUS } from 'constants/pilots'
import { roleKey } from 'constants/roles'
import { SERVICE_CATEGORIES } from 'constants/serviceCategories'
import { API_TYPES } from 'lib/http-client'
import { useIdentity } from 'lib/solta-id-react'
import { styled, apply } from 'lib/styled'
import toaster from 'lib/toaster'
import { createPilotModule } from 'modules/pilot'
import { createPilotCategoriesModule } from 'modules/pilotCategory'
import { mapboxResultSchema } from 'utils/hooks/preProcessResults'
import { optionalString, dateNowMinimum } from 'utils/validation'

const {
  module: pilotModule,
  selectors: {
    inspectedPilot$,
    isInspectingPilot$,
    isUpdatingIndividualPilot$,
    isUploadingAvatar$,
  },
} = createPilotModule(API_TYPES.WEB)

const {
  module: pilotCategoryModule,
  selectors: { pilotCategoriesList$ },
} = createPilotCategoriesModule(API_TYPES.WEB)

const { object, string, array, number, boolean } = Validator
const requiredString = (label) => string().required().label(label)

function useParamsOrRegisteredPilotID(user, id) {
  const pilotId = JSON.parse(user?.[roleKey]).pilot
  if (!id && pilotId) {
    return pilotId
  }
  return id
}

const ConnectedPilotDetails = connect(() => ({
  isInspectingPilot: isInspectingPilot$,
  inspectedPilot: inspectedPilot$,
  pilotCategoriesList: pilotCategoriesList$,
  isUpdatingIndividualPilot: isUpdatingIndividualPilot$,
  isUploadingAvatar: isUploadingAvatar$,
}))(MyPilotDetailsContainer)

function MyPilotDetailsContainer({
  inspectedPilot = {},
  pilotCategoriesList,
  isUpdatingIndividualPilot,
  isUploadingAvatar,
}) {
  const history = useHistory()
  const { user, setPilotApplicationStatus, pilotApplicationStatus, organization } =
    useIdentity()

  // NOTE: THESE 4 VARIABLES IS FOR DEBUGGING, REMOVE THEM IF YOU SURE
  // const pilotApplicationStatus = PILOT_APPLICATION_STATUS.INCOMPLETE
  // const pilotApplicationStatus = PILOT_APPLICATION_STATUS.SUBMITTED
  // const pilotApplicationStatus = PILOT_APPLICATION_STATUS.REJECTED
  // const pilotApplicationStatus = PILOT_APPLICATION_STATUS.ACCEPTED
  const { id: paramsId } = useParams()
  const [loading, setLoading] = useState(false)
  const [editing, setEditing] = useState(false)
  const [step, setStep] = useState(0)

  const id = useParamsOrRegisteredPilotID(user, paramsId)

  useEffect(() => {
    const fetchPilotAndSetDefaultCompany = async () => {
      setLoading(true)
      pilotCategoryModule.filterPilotCategories(null, { filterQuery: { limit: 1000 } })
      await Promise.all([
        pilotCategoryModule.fetchPilotCategories(null, {
          turnPage: false,
          type: organization.supplierType,
        }),
        pilotModule.inspectPilot(id),
      ])
      setLoading(false)
    }
    fetchPilotAndSetDefaultCompany()
    return () => {
      pilotCategoryModule.resetPagination()
    }
  }, [id])

  const {
    userDetails = {},
    companyName,
    licenceType,
    licenceNumber,
    licenceExpiredAt,
    insuranceNumber,
    insuranceExpiredAt,
    serviceCategories,
    pilotCategory,
    serviceRadius,
    equipments,
    userId,
  } = inspectedPilot
  const { firstName, lastName, phoneNumber, avatar } = userDetails

  const address = {
    geometry: inspectedPilot.addressLocation,
    // eslint-disable-next-line camelcase
    place_name: inspectedPilot.address,
  }

  const initialValues = {
    userDetails: {
      firstName,
      lastName,
      phoneNumber,
      email: paramsId ? userDetails?.email : user.email,
      avatar,
      pilotCategoryId: pilotCategory?.id,
      pilotCategoryName: pilotCategory
        ? `${pilotCategory?.description} A$${pilotCategory?.rate} / hr`
        : '',
      companyName,
      userId,
    },
    licenceType,
    licenceNumber,
    licenceExpiredAt,
    insuranceNumber,
    insuranceExpiredAt,
    serviceCategories,
    addressLocation: '', // Transform this when placed on server
    address,
    serviceRadius,
    termsAndConditionsAccepted: true, // TODO: Make page confirmation
    equipments: equipments?.length ? equipments : [{ name: '', serialNumber: '' }],
  }

  const validationSchema = object({
    userDetails: object({
      firstName: requiredString('First name'),
      lastName: optionalString('Last name'),
      phoneNumber: requiredString('Phone number').matches(
        CONSTANTS.REGEX.E164_PHONE_FORMAT,
        'Not a valid phone number'
      ),
      pilotCategoryId: requiredString('Pilot category').matches(
        CONSTANTS.REGEX.MONGO_OBJECT_ID
      ),
    }),
    licenceType: requiredString('Licence type').oneOf(values(LICENCE_TYPES)),
    licenceNumber: requiredString('Licence number'),
    licenceExpiredAt: dateNowMinimum().label('Licence expiry'),
    insuranceNumber: optionalString('Insurance number'),
    insuranceExpiredAt: dateNowMinimum().label('Insurance expiry').optional(),
    serviceCategories: array()
      .of(string().oneOf(SERVICE_CATEGORIES.map(prop('value'))))
      .min(1)
      .label('Service categories'),
    addressLocation: optionalString('Address Location'),
    address: mapboxResultSchema.required(),
    serviceRadius: number().required().label('Radius'),
    termsAndConditionsAccepted: boolean().isTrue(),
    equipments: array()
      .of(
        object({
          name: requiredString('Equipment Name'),
          serialNumber: requiredString('Serial Number'),
        })
      )
      .required(),
  })

  const pilotDetailsProps = {
    initialValues,
    validationSchema,
    editing,
    setEditing,
    step,
    setStep,
    toaster,
    id,
    userId,
    paramsId,
    avatar,
    pilotCategoriesList,
    isUpdatingIndividualPilot,
    pilotModule,
    isUploadingAvatar,
    loading,
    pilotApplicationStatus,
    onSubmit: async (values) => {
      setLoading(true)
      if (pilotApplicationStatus === PILOT_APPLICATION_STATUS.INCOMPLETE) {
        await pilotModule.submitApplication(id, values).then((pilot) => {
          if (pilot) {
            toaster.success('Submit Details Success!')
            setPilotApplicationStatus(PILOT_APPLICATION_STATUS.SUBMITTED)
            history.push('/my-pilot-info')
          }
        })
        setEditing(false)
        setLoading(false)
      }
      if (pilotApplicationStatus === PILOT_APPLICATION_STATUS.ACCEPTED) {
        const formValues = values
        formValues.userDetails.pilotCategory = formValues.userDetails.pilotCategoryId
        await pilotModule.updateIndividualPilot(id, formValues).then((pilot) => {
          if (pilot) {
            toaster.success('Update Success!')
          }
        })
        setEditing(false)
        setLoading(false)
      }
    },
  }

  return <MyPilotDetails {...pilotDetailsProps} />
}

const PaddedContainer = styled.div(apply('flex-1 flex flex-row p-6'))

function PilotDetailsWithContainer() {
  return (
    <PaddedContainer>
      <ConnectedPilotDetails />
    </PaddedContainer>
  )
}

export { ConnectedPilotDetails as MyPilotDetails, PilotDetailsWithContainer }
