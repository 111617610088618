import CONSTANTS from '@seedcloud/constants'
import { connect } from '@seedcloud/stateless'
import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import * as Validator from 'yup'

import { PilotDetails } from '../common/PilotDetails'

import { roleKey } from 'constants/roles'
import { API_TYPES } from 'lib/http-client'
import { useIdentity } from 'lib/solta-id-react'
import { styled, apply } from 'lib/styled'
import { createPilotModule } from 'modules/pilot'
import { createPilotCategoriesModule } from 'modules/pilotCategory'
import { useParamsOrRegisteredPilotID } from 'utils/useParamsOrRegisterPilotId'
import { optionalString, requiredString } from 'utils/validation'

const {
  module: pilotModule,
  selectors: { inspectedPilot$, isInspectingPilot$ },
} = createPilotModule(API_TYPES.WEB)

const {
  module: pilotCategoryModule,
  selectors: { pilotCategoriesList$ },
} = createPilotCategoriesModule(API_TYPES.WEB)

const { object } = Validator

const validationSchema = object({
  userDetails: object({
    firstName: requiredString('First name'),
    lastName: optionalString('Last name'),
    phoneNumber: requiredString('Phone number').matches(
      CONSTANTS.REGEX.E164_PHONE_FORMAT,
      'Not a valid phone number'
    ),
    avatar: optionalString('Avatar'),
    pilotCategory: requiredString('Pilot category').matches(
      CONSTANTS.REGEX.MONGO_OBJECT_ID
    ),
  }),
})

const ConnectedPilotDetails = connect(() => ({
  isInspectingPilot: isInspectingPilot$,
  inspectedPilot: inspectedPilot$,
  pilotCategoriesList: pilotCategoriesList$,
}))(PilotDetailsContainer)

function PilotDetailsContainer({
  inspectedPilot = {},
  pilotCategoriesList,
  isUpdatingIndividualPilot,
}) {
  const { user } = useIdentity()
  const { id: paramsId } = useParams()
  const history = useHistory()
  const [loading, setLoading] = useState(true)
  const id = useParamsOrRegisteredPilotID(user, paramsId, roleKey)

  useEffect(() => {
    const fetchPilots = async () => {
      setLoading(true)
      pilotCategoryModule.filterPilotCategories(null, { filterQuery: { limit: 1000 } })
      await Promise.all([
        pilotCategoryModule.fetchPilotCategories(null, { turnPage: false }),
        pilotModule.inspectPilot(id),
      ])
      setLoading(false)
    }
    fetchPilots()
    return () => {
      pilotCategoryModule.resetPagination()
    }
  }, [id])

  const { userDetails = {}, pilotCategory, companyName } = inspectedPilot
  const { firstName, lastName, phoneNumber, avatar } = userDetails

  const initialValues = {
    userDetails: {
      firstName,
      lastName,
      phoneNumber,
      email: paramsId ? userDetails?.email : user.email,
      avatar,
      pilotCategory: pilotCategory?.id,
      pilotCategoryName: pilotCategory
        ? `${pilotCategory?.description} A$${pilotCategory?.rate} / hr`
        : '',
      companyName,
    },
  }

  const pilotDetailsProps = {
    avatar,
    editing: !paramsId,
    history,
    id,
    initialValues,
    isUpdatingIndividualPilot,
    paramsId,
    pilotCategoriesList,
    pilotModule,
    validationSchema,
    loading,
  }

  return <PilotDetails {...pilotDetailsProps} />
}

const PaddedContainer = styled.div(apply('flex-1 flex flex-row p-6'))

function PilotDetailsWithContainer() {
  return (
    <PaddedContainer>
      <ConnectedPilotDetails />
    </PaddedContainer>
  )
}

export { ConnectedPilotDetails, PilotDetailsWithContainer }
