import { connect } from '@seedcloud/stateless'
import { useState, useLayoutEffect } from 'react'

import { PilotEquipmentTable } from '../../common/tables/PilotEquipmentTable'
import { CURRENCY_VALUE } from '../common/constants'

import { CurrencySelectField } from './CurrencySelectField'
import { EquipmentCategoryModal } from './EquipmentCategoryModal'
import { PilotCategoriesLayout } from './PilotCategoriesLayout'

import { Button } from 'components/common/Button'
import { ConfirmModal } from 'components/common/ConfirmModal/ConfirmModal'
import { FieldGroup } from 'components/company/common'
import { API_TYPES } from 'lib/http-client'
import { useIdentity } from 'lib/solta-id-react'
import { styled, apply } from 'lib/styled'
import { createCompanyModule } from 'modules/company'
import { createOrganizationModule } from 'modules/organization'
import { createPilotCategoriesModule } from 'modules/pilotCategory'
import { useEntityType } from 'utils/hooks/useEntityType'

const {
  module: companyModule,
  selectors: {
    companyDetail$,
    currency$,
    invitationStatus$,
    isInspectingGetCompanyAndOrganizationId$,
    isUpdatingCurrency$,
    supplierType$,
  },
} = createCompanyModule(API_TYPES.WEB)

const {
  module: organizationModule,
  selectors: { isInspectingOrganization$ },
} = createOrganizationModule(API_TYPES.WEB)

const {
  module: pilotCategoryModule,
  selectors: {
    pilotCategoriesList$,
    isFetchingPilotCategories$,
    pilotCategoriesEntities$,
    pilotCategoriesFilters$,
  },
} = createPilotCategoriesModule(API_TYPES.WEB)

const Space = styled.div(apply('flex'), {
  gap: '1rem',
  flex: '0 0 300px',
})

const EquipmentCategory = ({
  pilotCategoriesList,
  pilotCategoriesEntities,
  isFetchingPilotCategories,
  isInspectingCompany,
  type,
  status,
  pilotCategoriesFilters,
  isInspectingOrganization,
}) => {
  const { organization, role } = useIdentity()

  const fetchOptions = {
    turnPage: false,
    type: ['equipment'],
  }

  useLayoutEffect(() => {
    if (!type) {
      companyModule.inspectCompany()
    }
  }, [])

  useLayoutEffect(() => {
    pilotCategoryModule.fetchPilotCategories(null, fetchOptions)
    return () => {
      pilotCategoryModule.resetPagination()
    }
  }, [])

  const {
    paging: { startRow, next },
    limit,
  } = pilotCategoriesFilters

  // eslint-disable-next-line no-unused-vars
  const currencyOptions = Object.entries(CURRENCY_VALUE).map(([_, value]) => ({
    value,
    label: value,
  }))

  const { currency = 'AUD $' } = organization

  const [selected, setSelected] = useState(CURRENCY_VALUE.AUD)
  const [showAddModal, setShowAddModal] = useState(false)
  const [showEditModal, setShowEditModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showCurrencyModal, setCurrencyModal] = useState(false)
  const [selectedCategory, setSelectedCategory] = useState(null)
  // eslint-disable-next-line no-unused-vars
  const [deletedCategoryId, setDeletedCategoryId] = useState(null)
  const [submitting, setSubmitting] = useState(false)

  const handleShowEdit = (id) => {
    setShowEditModal(true)
    setSelectedCategory(pilotCategoriesEntities[id])
  }

  const handleOpenDeleteModal = (id) => {
    setShowDeleteModal(true)
    setDeletedCategoryId(id)
  }

  const handleCloseModal = () => {
    setShowAddModal(false)
  }

  const handleCloseEditModal = () => {
    setShowEditModal(false)
    setSelectedCategory(null)
  }

  // eslint-disable-next-line no-unused-vars
  const handleCreateCategory = async (values) => {
    await pilotCategoryModule.publishPilotCategories(null, {
      type: 'equipment',
      payload: values,
    })
    setSubmitting(false)
    setShowAddModal(false)
    await pilotCategoryModule.fetchPilotCategories(null, fetchOptions)
  }

  // eslint-disable-next-line no-unused-vars
  const handleEditPilotCategory = async (values) => {
    setSubmitting(true)
    await pilotCategoryModule.updatePilotCategories(selectedCategory.id, {
      type: 'equipment',
      payload: values,
    })
    setSubmitting(false)
    setShowEditModal(false)
    await pilotCategoryModule.fetchPilotCategories(null, fetchOptions)
  }

  const handleDeletePilotCategory = async () => {
    setSubmitting(true)
    await pilotCategoryModule.deletePilotCategory(deletedCategoryId)
    setSubmitting(false)
    setShowDeleteModal(false)
    await pilotCategoryModule.fetchPilotCategories(null, fetchOptions)
  }

  const { isInternalEntity } = useEntityType()

  return (
    <PilotCategoriesLayout
      isInternalEntity={isInternalEntity}
      status={status}
      company={organization.name ?? ''}
      type={type}
      loading={
        isFetchingPilotCategories || isInspectingCompany || isInspectingOrganization
      }
    >
      <FieldGroup
        title="Customize your categories and rates"
        subtitle="Equipment Rate indicates the type of Equipment (e.g. DJI Mavic 3 Pro Drone Combo) that will be used and its respective Rate (e.g. $100)."
        extra={
          role !== 'user' && (
            <Space>
              <CurrencySelectField
                label="Currency"
                options={currencyOptions}
                selected={currency}
                name="currency"
                onChange={(option) => {
                  setCurrencyModal(true)
                  setSelected(option.value)
                }}
                style={{ height: '40px' }}
              />
              <Button onClick={() => setShowAddModal(true)} style={{ height: '40px' }}>
                Add New Category
              </Button>
            </Space>
          )
        }
      >
        <PilotEquipmentTable
          currency={currency}
          values={pilotCategoriesList}
          handleEdit={handleShowEdit}
          handleDelete={handleOpenDeleteModal}
          limit={limit}
          next={next}
          startRow={startRow}
          role={role}
          onTurnPage={(turnNext) =>
            pilotCategoryModule.fetchPilotCategories(null, {
              turnPage: true,
              turnNext,
              type: ['equipment'],
            })
          }
        />
      </FieldGroup>

      <EquipmentCategoryModal
        loading={submitting}
        title="Create New Equipment Category"
        currency={currency}
        isOpen={showAddModal}
        setIsOpen={setShowAddModal}
        closeModal={handleCloseModal}
        onSubmit={handleCreateCategory}
      />

      <EquipmentCategoryModal
        loading={submitting}
        title="Edit Equipment Category"
        initialValues={selectedCategory}
        currency={currency}
        isOpen={showEditModal}
        setIsOpen={setShowEditModal}
        closeModal={handleCloseEditModal}
        onSubmit={handleEditPilotCategory}
      />

      <ConfirmModal
        title="Delete Category"
        subtitle="Are you sure you want to delete this equipment category?"
        isOpen={showDeleteModal}
        closeModal={() => setShowDeleteModal(false)}
        confirmLoadingText="Deleting..."
        loading={submitting}
        onConfirm={handleDeletePilotCategory}
      />

      <ConfirmModal
        title="Change Currency"
        subtitle={
          <span>
            Are you sure you want to apply the new currency <b>({selected})</b> to
            equipment categories?
          </span>
        }
        isOpen={showCurrencyModal}
        closeModal={() => setCurrencyModal(false)}
        onConfirm={async () => {
          // eslint-disable-next-line no-undef
          await organizationModule.updateOrganization(null, { currency: selected })
          setCurrencyModal(false)
        }}
      />
    </PilotCategoriesLayout>
  )
}

const ConnectedEquipmentCategory = connect(() => ({
  pilotCategoriesList: pilotCategoriesList$,
  pilotCategoriesEntities: pilotCategoriesEntities$,
  isFetchingPilotCategories: isFetchingPilotCategories$,
  isInspectingCompany: isInspectingGetCompanyAndOrganizationId$,
  isUpdatingCurrency: isUpdatingCurrency$,
  companyCurrency: currency$,
  pilotCategoriesFilters: pilotCategoriesFilters$,
  companyDetails: companyDetail$,
  type: supplierType$,
  status: invitationStatus$,
  isInspectingOrganization: isInspectingOrganization$,
}))(EquipmentCategory)

export { ConnectedEquipmentCategory as EquipmentCategory }
